import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update } from "../utils/tableSlice";

export function getOrderRegelTxt(id) {
  axios.get(API.OrderRegelTxtUrl, {
    params: {
      regel_id: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createOrderRegelTxt() {
  axios.post(API.OrderRegelTxtUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateOrderRegelTxt(id, data) {
  axios.put(API.OrderRegelTxtUrl, data, {
    params: {
      regel_id: id
    }
  })
    .then(response => {
      store.dispatch(update({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}
