import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';

const titleList = [
  {key: 0, text: "Particuliere klant"},
  {key: 1, text: "Zakelijke klant"},
  {key: 2, text: "Overheids klant"},
  {key: 3, text: "Contractor"},
  {key: 4, text: "Leverancier"},
  {key: 5, text: "Interne organisatie"},
];

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterRelatie = (query, relatie) => {
  return relatie.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderRelatie = (relatie, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={relatie.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={relatie.text}
      />
  );
};

export class RelatieSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: titleList[0].text,
    }
  };

  render() {
    return (
        <Select2
          itemPredicate={filterRelatie}
          itemRenderer={renderRelatie}
          ItemListRenderer={renderMenu}
          items={titleList}
          onItemSelect={this.props.onSelect}
        >
          <Button
            text={this.props.value}
            icon='person'
            rightIcon='double-caret-vertical'
            small={true}
            style={{marginBottom: '0.1em', width: this.props.width}} 
          />
        </Select2>
    );
  };
};

