import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Icon, Intent, Toaster, Classes, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { orderGridBoxesFinance, orderUiItemsFinance } from './orderUI';
import * as API from '../../mainapi';
import MszTable from '../../components/MszTable';
import UiRenderer from '../../utils/DialogRender';
import PaymentDialog from './PaymentDialog';
import { load, select, del } from '../../utils/paytableSlice';
import { saveAs } from 'file-saver';
import DeleteDialog from '../../components/DeleteDialog';
import PlanInvoiceDialog from './PlanInvoiceDialog';
import { checkModule } from '../../utils/checkModule';

class FinanceTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDialog: false,
      showPlanInvoiceDialog: false,
      selectedPayId: null,
      showDialog: false,
      newRecord: false,
      toasterKey: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.getRow = this.getRow.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);
  }

  componentDidMount() {
    if (this.props.maintable.selectedRowData !== null) {
      this.loadTableData(this.props.maintable.selectedRowData.order_id);
    } else {
      this.parseGrid('orderpay', []);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dialog.recordData !== this.props.dialog.recordData) {
      if (this.props.dialog.recordData === null | nextProps.dialog.createRecord !== this.props.dialog.createRecord) {
        this.loadTableData(nextProps.dialog.recordData.order_id);
      }
      return true;
    } else if (nextProps.paytable.columnData !== this.props.paytable.columnData | nextProps.paytable.rowData !== this.props.paytable.rowData) {
      return true;
    } else {
      if (nextState !== this.state) {
        return true;
      } else {
        return false;
      }
    }
  }

  loadTableData(orderId) {
    axios.get(API.OrderPayUrl, {
      params: {
        order_id: orderId
      }
    })
      .then(response => {
        const data = response.data;
        this.parseGrid('orderpay', data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  booleanRenderer = (params) => {
    if (params.value) {
      return (
        <div>
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        </div>
      )
    } 
  }

  parseGrid(tableName, tableData) {
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;
        var newData = {};

        data.forEach((record) => {
          if (record.yesno) {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              cellRenderer: this.booleanRenderer,
              hide: (record.visible === 0)
            };
          } else {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              hide: (record.visible === 0)
            };
          }
          columnData.push(newData);
        })

        this.resetSelectedRow();
        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ newRecord: false });
      })
      .catch(error => {
          console.error(error);
      })
  }

  rowClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
  }

  rowDoubleClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ showDialog: true, newRecord: false });
  }

  resetSelectedRow() {
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  closeDialog = () => {
    this.setState({ showDialog: false, newRecord: false, showDeleteDialog: false });
  }

  deleteClick = () => {
    if (this.props.paytable.selectedRowIdx !== null) {
      this.setState({
        showDeleteDialog: true,
        selectedPayId: this.props.paytable.selectedRowData.order_payments_id,
      });
    }
  }

  getRow(action) {
    if (action === 'createRecord') {
      this.setState({ showDialog: true, newRecord: true });
    }

    if (this.props.paytable.selectedRowIdx !== null) {
      switch (action) {
        case 'editRecord' : this.setState({ showDialog: true, newRecord: false });
          break
        case 'deleteRecord' : this.deleteClick();
          break
      }
    }
  }

  deleteRow = () => {
    this.dispatch(del({ rowIdx: this.props.paytable.selectedRowIdx }));
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar intent={amount < 100 ? Intent.WARNING : Intent.SUCCESS}
          className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 1000,
    };
  }

  downloadFile = (factuurNr, fileType) => {
    // Request the download
    axios.get(API.FactuurFileUrl, {
      params: { factuurnr: factuurNr, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerInvoice = () => {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });

    const bodyData = { payment_ids: [this.props.paytable.selectedRowData.order_payments_id] };

    axios.post(API.InvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          this.downloadFile(data.factuurnr, 'docx');

          if (data.bestand_pdf !== null) {
            this.downloadFile(data.factuurnr, 'pdf');
          }

          if (data.bestand_ubl !== null) {
            this.downloadFile(data.factuurnr, 'ubl');
          }

          // Reload ayments table as the gefactureerd flag is updated
          this.loadTableData(this.props.dialog.recordData.order_id);

          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: data.factuurnr + ' is gefactureerd. Bestanden staan klaar in Downloads.'
          });
        } else {
          console.log(response.data);
          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze termijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
        console.error(error);
      })
  }

  renderDialog = () => {
    if (this.props.paytable.selectedRowIdx !== null | this.state.newRecord) {
      return (
        <PaymentDialog
          showDialog={this.state.showDialog}
          rowData={this.props.paytable.selectedRowData}
          newRecord={this.state.newRecord}
          orderId={this.props.dialog.recordData.order_id}
          onClose={this.closeDialog}
        />
      )
    }
  }

  render() {
    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <UiRenderer gridBoxes={orderGridBoxesFinance} uiItems={orderUiItemsFinance} />
        <div style={{display: 'inline-flex', marginTop: '1em'}}>
          <Button icon={IconNames.ADD} text="Toevoegen" onClick={() => this.getRow('createRecord')} />
          <Button icon={IconNames.EDIT} text="Wijzigen" onClick={() => this.getRow('editRecord')} />
          <Button icon={IconNames.DELETE} text="Verwijderen" onClick={() => this.getRow('deleteRecord')} />
          { !checkModule("PLANINVOICE") &&
            <Button
              icon={IconNames.EURO}
              intent={Intent.PRIMARY}
              text="Factureren"
              disabled={this.props.paytable.selectedRowIdx === null}
              onClick={this.triggerInvoice}
            />
          }
          { checkModule("PLANINVOICE") &&
            <Button
              icon={IconNames.EURO}
              intent={Intent.PRIMARY}
              text="Agenderen"
              disabled={this.props.paytable.selectedRowIdx === null}
              onClick={() => this.setState({ showPlanInvoiceDialog: true })}
            />
          }
        </div>
        <MszTable 
          tableColumns={this.props.paytable.columnData}
          tableRows={this.props.paytable.rowData}
          rowClicked={this.rowClicked}
          rowDoubleClicked={this.rowDoubleClicked}
          height='20em'
        />

        {this.renderDialog()}

        <DeleteDialog
          showDialog={this.state.showDeleteDialog}
          title='Betalingstermijn verwijderen'
          message={'Betalingstermijn ' + this.state.selectedPayId + ' verwijderen?'}
          url={API.OrderPayUrl}
          apiParams={{'order_payments_id': this.state.selectedPayId}}
          onDelete={this.deleteRow}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
        <PlanInvoiceDialog
          showDialog={this.state.showPlanInvoiceDialog}
          title='Factuur agenderen'
          onClose={() => this.setState({ showPlanInvoiceDialog: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  paytable: state.paytable,
  maintable: state.maintable
});

export default connect(mapStateToProps)(FinanceTab);
