import React, {Component} from 'react';
import axios from 'axios';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Toaster, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { PasswordInput } from './PasswordInput';
import { TextInput } from './TextInput';
import * as API from '../mainapi';

class RegisterUserDialog extends Component {
  constructor(props) {
    super(props);

    this.toaster = Toaster;

    this.state = {
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      password2: "",
    };

    this.currentUserId = sessionStorage.getItem("currentUserId");
  }

  cancelClick = (closeDialog) => {
    closeDialog();
  }

  submitClick = (closeDialog, reloadTable) => {
    const requestBody = {
      "username": this.state.userName,
      "email": this.state.email,
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "password": this.state.password,
      "password2": this.state.password2,
    };

    axios.post(API.RegisterUrl, requestBody)
      .then(response => {
        if (response.status === 201) {
          this.toaster.show({ icon: IconNames.CONFIRM, intent: Intent.SUCCESS, message: 'Nieuwe gebruiker is geregistreerd.' });
          closeDialog();
          reloadTable();
        } else {
          this.toaster.show({ icon: IconNames.WARNING_SIGN, intent: Intent.WARNING, message: 'Niet gelukt.' });
        }
      })
      .catch(error => {
          console.error(error);
          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: 'Het wachtwoord voldoet niet aan de eisen.'
          });
      })
  }

  render() {
    const { showDialog, title, onClose, onTableReload } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <FormGroup helperText="Email" style={{margin: '0.0em'}}>
            </FormGroup>
            <TextInput
              id="email"
              autoComplete="off"
              text={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <FormGroup helperText="Voornaam" style={{margin: '0.0em'}}>
            </FormGroup>
            <TextInput
              id="firstname"
              autoComplete="off"
              text={this.state.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
            <FormGroup helperText="Achternaam" style={{margin: '0.0em'}}>
            </FormGroup>
            <TextInput
              id="lastname"
              autoComplete="off"
              text={this.state.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
            <FormGroup helperText="Gebruikersnaam" style={{margin: '0.0em'}}>
            </FormGroup>
            <TextInput
              id="user"
              autoComplete="off"
              text={this.state.userName}
              onChange={(e) => this.setState({ userName: e.target.value })}
            />
            <FormGroup helperText="Wachtwoord" style={{margin: '0.0em'}}>
            </FormGroup>
            <PasswordInput
              id="password"
              autoComplete="off"
              text={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <FormGroup helperText="Controle wachtwoord" style={{margin: '0.0em'}}>
            </FormGroup>
            <PasswordInput
              id="password2"
              autoComplete="off"
              text={this.state.password2}
              onChange={(e) => this.setState({ password2: e.target.value })}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              <Button
                icon={IconNames.SEND_MESSAGE}
                intent={Intent.WARNING}
                disabled={this.state.password !== this.state.password2 || this.state.password === "" || this.state.userName === ""}
                onClick={() => this.submitClick(onClose, onTableReload)}>
                  Registreren
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default RegisterUserDialog;
