import React, {Component} from 'react'
import { Card, Elevation, Colors, Icon } from '@blueprintjs/core'

export class MetricCard extends Component {
  render() {
    const metricGradient = 'linear-gradient(' + Colors.TURQUOISE2 + ',' + Colors.TURQUOISE1 + ')';
    const { label, icon, value } = this.props

    return (
      <Card elevation={Elevation.THREE}
        style={{background: [`${metricGradient}`], borderRadius: '0.7em', color: Colors.LIGHT_GRAY4, margin: '1em', width: '13%'}}>
        <Icon icon={icon} color={Colors.TURQUOISE5} size='14pt' />
        <h3 style={{margin: '0.1em'}}>
          {value}
        </h3>
        <h4 style={{color: Colors.TURQUOISE5, marginBottom: '0.1em', marginTop: '1em'}}>{label}</h4>
      </Card>
    );
  };
};

