import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './locale.nl';
import './MszTable.css';

import React, {Component} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from "react-redux";
import { AG_GRID_LOCALE_NL } from './locale.nl';
import { set } from '../utils/totalizerSlice';

class MszTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTable: true,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
  };

  onModelUpdated = (e) => {
    var resultSum = 0;

    if (this.props.totalColumn !== null) {
      e.api.forEachNodeAfterFilter(node => {
        resultSum += node.data[this.props.totalColumn];
      })
    }

    this.dispatch(set({ value: resultSum.toFixed(2) }));
  }

  render() {
    const { tableColumns, tableRows, rowClicked, rowDoubleClicked } = this.props;
    var { height } = this.props;

    if (height === null | height === undefined) {
      height = '100%';
    }

    const gridOptions = {
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellStyle: {
          display: 'flex'
        }
      },
      animateRows: true,
      rowSelection: 'single',
      onRowClicked: rowClicked,
      onRowDoubleClicked: rowDoubleClicked,
      onModelUpdated: this.onModelUpdated
    };

    return (
      <div className='ag-theme-alpine' style={{width: '100%', height: height}}>
        <AgGridReact
          rowData={tableRows}
          columnDefs={tableColumns}
          gridOptions={gridOptions}
          localeText={AG_GRID_LOCALE_NL}
        />
      </div>
    );
  };
};

const mapStateToProps = state => ({
  totalizer: state.totalizer
});

export default connect(mapStateToProps)(MszTable)
