import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Tabs, Tab } from '@blueprintjs/core';
import { mwGridBoxesMain, mwUiItemsMain, mwGridBoxesAantekeningen, mwUiItemsAantekeningen } from './mwUI';
import { clear } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateMw } from '../../services/mw.service';
import UiRenderer from '../../utils/DialogRender';

class MedewerkerDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog) {
    const mwRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, mwRecord);

    const mwId = mwRecord.medewerker_id
    delete recordData.medewerker_id
    updateMw(mwId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '70%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Tabs animate={true} id='TabsCrm' large={true}>
            <Tab id='main' title='Gegevens'
              panel={<UiRenderer gridBoxes={mwGridBoxesMain} uiItems={mwUiItemsMain} />}
            /> 
            <Tab id='afspraken' title='Aantekeningen'
              panel={<UiRenderer gridBoxes={mwGridBoxesAantekeningen} uiItems={mwUiItemsAantekeningen} />}
            /> 
          </Tabs>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(MedewerkerDialog);
