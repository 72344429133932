/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/

export const liteFactuurGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A0', boxTitle: "Factuur ID en omschrijving", boxWidth: '50%'},
            {boxId: 'B0', boxTitle: "Ordernr / Onze referentie", boxWidth: '50%'}
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Opdrachtgever", boxWidth: '55%'},
            {boxId: 'B2', boxTitle: "Contactgegevens", boxWidth: '45%'}
        ]
    },
    {
        line: 3,
        boxes: [
            {boxId: 'A1', boxTitle: "Factuuradres", boxWidth: '55%'},
            {boxId: 'B1', boxTitle: "Contactgegevens administratie", boxWidth: '45%'}
        ]
    },
]

export const liteFactuurUiItemsMain = [
    {
        boxId: 'A0',
        key: 'A0',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'lite_id',
                placeholder: 'Factuur ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'omschrijving',
                placeholder: 'Omschrijving',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B0',
        key: 'B01',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fk_order_id',
                placeholder: 'Ordernr / Onze referentie',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A20',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 0,
                elementType: 'Search',
                table: 'crm',
                action: 'search',
                fields: {
                    'client_id': 'contact_id',
                    'soort_relatie': 'soort_relatie',
                    'naam': 'naam',
                    'adres': 'adres',
                    'huisnr': 'huisnr',
                    'hnrtoev': 'hnrtoev',
                    'postcode': 'postcode',
                    'plaats': 'plaats',
                    'land': 'land',
                    'contactp': 'contactp',
                    'aanhef': 'aanhef',
                    'telefoon': 'telefoon',
                    'telefoon_2': 'telefoon_2',
                    'email': 'email',
                    "fact_adres": "fact_adres",
                    "fact_huisnr": "fact_huisnr",
                    "fact_hnrtoev": "fact_hnrtoev",
                    "fact_postcode": "fact_postcode",
                    "fact_plaats": "fact_plaats",
                    "fact_land": "fact_land",
                    "fact_contactp": "fact_contactp",
                    "fact_aanhef": "fact_aanhef",
                    "fact_telefoon": "fact_telefoon",
                    "fact_email": "fact_email"
                }
            },
            {
                key: 1,
                elementType: 'TextInput',
                field: 'client_id',
                placeholder: 'Relatienr',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'naam',
                placeholder: 'Naam',
                icon: null,
                width: '35em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: null,
        label: 'Soort relatie',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'RelatieSelect',
                field: 'soort_relatie',
                placeholder: 'Soort relatie',
                icon: null,
                width: null,
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A22',
        groupType: 'Inline',
        label: 'Afleveradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A23',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A24',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B21',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TelInput',
                field: 'telefoon_2',
                placeholder: 'Telefoon 2',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B22',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A1',
        groupType: 'Inline',
        label: 'Factuuradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fact_adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'fact_huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'fact_hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fact_postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'fact_plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'fact_land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A13',
        groupType: 'Label',
        label: 'Betalingsvoorwaarden (dagen)',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'betalingsvoorw',
                icon: null,
                width: '5em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'Switch',
                field: 'stdbetaling',
                label: 'Standaard betalingsvoorwaarden gebruiken',
                icon: null,
                width: null,
                action: 'switch',
                restrict: null,
                readOnly: false,
                spacing: '2em'
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'fact_aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'fact_contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'fact_telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'EmailInput',
                field: 'fact_email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '25em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B12',
        groupType: 'Label',
        label: 'Referentie opdrachtgever',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B13',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'uwref',
                placeholder: 'Uw referentie',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B15',
        groupType: 'Label',
        label: 'BTW verlegd toepassen en BTW id van client',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B16',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 2,
                elementType: 'Switch',
                field: 'btwverlegd',
                label: '',
                icon: null,
                width: null,
                action: 'switch',
                restrict: null,
                readOnly: false
            },
            {
                key: 1,
                elementType: 'TextInput',
                field: 'btw_id',
                placeholder: 'BTW-id',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    }
]
