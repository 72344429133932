import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update, del } from "../utils/tableSlice";

export function getOfferte(id) {
  axios.get(API.OfferteUrl, {
    params: {
      offerte_id: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createOfferte() {
  axios.post(API.OfferteUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateOfferte(id, data) {
  const state = store.getState();

  axios.put(API.OfferteUrl, data, {
    params: {
      offerte_id: id
    }
  })
    .then(response => {
      const bucketChange = state.dialog.recordData.vervallen !== state.maintable.selectedRowData.vervallen;
      if (bucketChange) {
        store.dispatch(del({ rowIdx: state.maintable.selectedRowIdx }));
      } else {
        store.dispatch(update({ rowData: response.data }));
      }
    })
    .catch(error => {
        console.error(error);
    })
}

export function promoteOfferte() {
  const state = store.getState();
  store.dispatch(del({ rowIdx: state.maintable.selectedRowIdx }));
}
