import React, {Component} from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Toaster, FormGroup, Icon, Card, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TextInput } from './TextInput';
import { load, select } from '../utils/usertableSlice';
import { update, clear } from '../utils/dialogSlice';
import { updateUser, updateGroups } from '../services/user.service';
import { Dispatcher } from '../views/Main/ServiceDispatch'

import CheckboxList from './UserGroupSelect';
import RegisterUserDialog from './RegisterUserDialog';
import MszTable from './MszTable';
import * as API from '../mainapi';

class UserAdminDialog extends Component {
  constructor(props) {
    super(props);

    this.toaster = Toaster;

    this.state = {
      newRecord: false,
      showRegisterDialog: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.closeClick.bind(this);
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData() {
    axios.get(API.UserUrl, {
    })
      .then(response => {
        const data = response.data;
        this.parseGrid(data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  booleanRenderer = (params) => {
    if (params.value) {
      return (
        <div>
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        </div>
      )
    } 
  }

  parseGrid(tableData) {
    const gridData = [
      {"fieldname": "id", "description": "Id", "yesno": false, "visible": true},
      {"fieldname": "username", "description": "Gebruikersnaam", "yesno": false, "visible": true},
      {"fieldname": "first_name", "description": "Voornaam", "yesno": false, "visible": true},
      {"fieldname": "last_name", "description": "Achternaam", "yesno": false, "visible": true},
      {"fieldname": "email", "description": "Email", "yesno": false, "visible": true},
      {"fieldname": "is_staff", "description": "Beheerder", "yesno": true, "visible": true},
      {"fieldname": "is_active", "description": "Actief", "yesno": true, "visible": true},
      {"fieldname": "groups", "description": "Groepen", "yesno": false, "visible": false}
    ];
    var columnData = [];
    var newData = {};

    gridData.forEach((record) => {
      if (record.yesno) {
        newData = {
          field: record.fieldname,
          headerName: record.description.toString(),
          cellRenderer: this.booleanRenderer,
          hide: (!record.visible)
        };
      } else {
        newData = {
          field: record.fieldname,
          headerName: record.description.toString(),
          hide: (!record.visible)
        };
      }
      columnData.push(newData);
    })

    this.resetSelectedRow();
    this.dispatch(load({ rowData: tableData, columnData: columnData }));
    this.setState({ newRecord: false });
  }

  rowClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
    Dispatcher('User', 'editRecord');
  }

  resetSelectedRow() {
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  saveClick(closeDialog) {
    const userRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, userRecord);

    const userId = userRecord.id
    delete recordData.id
    updateUser(userId, recordData);
    updateGroups(userId, recordData.groups);
  }

  closeClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
  }

  renderUserDetails = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Gebruikergegevens
        </p>

        <div>
          <div style={{display: 'inline-flex'}}>
            <FormGroup helperText='Id' inline={true} style={{margin: '0.0em'}}/>
            <TextInput
              type='text'
              width='5em'
              value={this.props.dialog.recordData.id}
              readOnly={true}
              small={true}
            />
            <FormGroup helperText='Gebruikersnaam' inline={true} style={{margin: '0.0em'}}/>
            <TextInput
              type='text'
              width='10em'
              value={this.props.dialog.recordData.username}
              readOnly={true}
              small={true}
            />
          </div>

          <FormGroup helperText='Voornaam' inline={true} style={{margin: '0.0em'}}/>
          <TextInput
            type='text'
            width='15em'
            value={this.props.dialog.recordData.first_name}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'first_name': e.target.value }))}
            small={true}
          />
          <FormGroup helperText='Achternaam' inline={true} style={{margin: '0.0em'}}/>
          <TextInput
            type='text'
            width='20em'
            value={this.props.dialog.recordData.last_name}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'last_name': e.target.value }))}
            small={true}
          />
          <p className={Classes.HEADING}>
            Status
          </p>

          <Switch
            label='Actief'
            checked={this.props.dialog.recordData.is_active}
            onChange={(e) => this.dispatch(update({ 'is_active': e.target.checked }))}
          />
          <Switch
            label='Beheerder'
            checked={this.props.dialog.recordData.is_staff}
            onChange={(e) => this.dispatch(update({ 'is_staff': e.target.checked }))}
          />
        </div>
      </Card>
    )
  }

  handleToggleItem = (newSelectedItems) => {
    this.dispatch(update({ 'groups': newSelectedItems }));
  };

  renderUserGroups = () => {
    const userGroups = JSON.parse(sessionStorage.getItem("userGroup")).map((item) => item.text)

    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '40%'}}
      >
        <p className={Classes.HEADING}>
          Groepen
        </p>
        <div>
          <CheckboxList
            fixedList={userGroups}
            selectedItems={this.props.selectedItems}
            onToggleItem={this.handleToggleItem} />
        </div>
      </Card>
    )
  }

  render() {
    const { showDialog, title, onClose } = this.props;
    let dataIsLoaded = this.props.dialog.recordData !== null

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1, width: '60%'}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <MszTable 
              tableColumns={this.props.usertable.columnData}
              tableRows={this.props.usertable.rowData}
              rowClicked={this.rowClicked}
              height='20em'
            />
            <div style={{display: 'inline-flex', width: '100%'}}>
              {dataIsLoaded && this.renderUserDetails()}
              {dataIsLoaded && this.renderUserGroups()}
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.NEW_PERSON}
                intent={Intent.SUCCESS}
                onClick={() => this.setState({ showRegisterDialog: true })}>
                  Nieuwe gebruiker
              </Button>
              <Button
                icon={IconNames.STOP}
                intent={Intent.NONE}
                onClick={() => this.closeClick(onClose)}>
                  Afsluiten
              </Button>
              <Button
                icon={IconNames.CONFIRM}
                intent={Intent.WARNING}
                onClick={() => this.saveClick(onClose)}>
                  Opslaan
              </Button>
            </div>
          </div>
        </Dialog>

        <RegisterUserDialog
          showDialog={this.state.showRegisterDialog}
          title='Registreren nieuwe gebruiker'
          onClose={() => this.setState({ showRegisterDialog: false })}
          onTableReload={this.loadTableData}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  usertable: state.usertable,
  selectedItems: state.dialog.recordData !== null ? state.dialog.recordData.groups : []
});

export default connect(mapStateToProps)(UserAdminDialog);
