import React, {Component} from 'react'
import { InputGroup } from '@blueprintjs/core'

export class UserInput extends Component {
  render() {
    return (
      <div>
        <InputGroup
          leftIcon="user"
          value={this.props.text}
          onChange={this.props.onChange}
          maxLength={this.props.length}
          disabled={this.props.disabled}
          style={{marginBottom: '1em'}}
        />
      </div>
    );
  };
};

