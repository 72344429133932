/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/
import { IconNames } from "@blueprintjs/icons";

export const offerteGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Project", boxWidth: '51%'},
            {boxId: 'B1', boxTitle: "Bedragen", boxWidth: '31%'},
            {boxId: 'C1', boxTitle: "Status", boxWidth: '18%'}
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Opdrachtgever", boxWidth: '55%'},
            {boxId: 'B2', boxTitle: "Contactgegevens", boxWidth: '45%'}
        ]
    },
]

export const offerteUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'offerte_id',
                placeholder: 'Offerte ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: 'Label',
        label: 'Omschrijving project',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'omschrijving',
                placeholder: 'Omschrijving project',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A13',
        groupType: 'Label',
        label: 'Uw referentie',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'uwref',
                placeholder: 'Uw referentie',
                icon: null,
                width: '25em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A15',
        groupType: 'Label',
        label: 'Aanvraag- en indiendatum',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A16',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'aanvraagdatum',
                placeholder: 'Aanvraagdatum',
                icon: null,
                width: '10em',
                action: 'dateinput',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'DateInput',
                field: 'indiendatum',
                placeholder: 'Indiendatum',
                icon: null,
                width: '10em',
                action: 'dateinput',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'Checkbox',
                field: 'ingediend',
                label: 'Ingediend',
                icon: null,
                action: 'check',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A17',
        groupType: 'Label',
        label: 'Actiedatum en houder',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A18',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'actiedatum',
                placeholder: 'Actiedatum',
                icon: null,
                width: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'actiehouder',
                placeholder: 'Actiehouder',
                icon: 'follower',
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A19',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'nextactie',
                placeholder: 'Eerstvolgende actie',
                icon: 'build',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A191',
        groupType: 'Label',
        label: 'Beslisdatum',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A192',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'beslisdatum',
                placeholder: 'Beslisdatum',
                icon: null,
                width: '10em',
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: 'Label',
        label: 'Aanneemsom (Excl. BTW)',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'aanneemsom',
                placeholder: 'Aanneemsom',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B13',
        groupType: 'Label',
        label: 'Totaal orderbedrag',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'orderbedrag',
                placeholder: 'Orderbedrag',
                icon: null,
                width: '10em',
                action: 'calculate',
                operator: '-x',
                calcField: 'inkoopbedrag',
                target: 'marge',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B15',
        groupType: 'Label',
        label: 'Inkoopbedrag',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B16',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'inkoopbedrag',
                placeholder: 'Inkoopbedrag',
                icon: null,
                width: '10em',
                action: 'calculate',
                operator: 'x-',
                calcField: 'orderbedrag',
                target: 'marge',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B17',
        groupType: 'Label',
        label: 'Marge',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B18',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'marge',
                placeholder: 'Marge',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B19',
        groupType: 'Label',
        label: 'Scoringskans',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B20',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'scoringskans',
                placeholder: 'Scoringskans',
                icon: IconNames.PERCENTAGE,
                width: '7em',
                min: 0,
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Switch',
                field: 'vervallen',
                label: 'Vervallen',
                icon: null,
                width: null,
                action: 'switch',
                restrict: null,
                readOnly: false,
                disabledCondition: ['promoted', true]
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Switch',
                field: 'promoted',
                label: 'In opdracht',
                icon: null,
                width: null,
                action: 'switch',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 0,
                elementType: 'Search',
                table: 'crm',
                action: 'search',
                fields: {
                    'client_id': 'contact_id',
                    'soort_relatie': 'soort_relatie',
                    'naam': 'naam',
                    'adres': 'adres',
                    'huisnr': 'huisnr',
                    'hnrtoev': 'hnrtoev',
                    'postcode': 'postcode',
                    'plaats': 'plaats',
                    'land': 'land',
                    'contactp': 'contactp',
                    'aanhef': 'aanhef',
                    'telefoon': 'telefoon',
                    'telefoon_2': 'telefoon_2',
                    'email': 'email',
                    "fact_adres": "fact_adres",
                    "fact_huisnr": "fact_huisnr",
                    "fact_hnrtoev": "fact_hnrtoev",
                    "fact_postcode": "fact_postcode",
                    "fact_plaats": "fact_plaats",
                    "fact_land": "fact_land",
                    "fact_contactp": "fact_contactp",
                    "fact_aanhef": "fact_aanhef",
                    "fact_telefoon": "fact_telefoon",
                    "fact_email": "fact_email"
                }
            },
            {
                key: 1,
                elementType: 'TextInput',
                field: 'client_id',
                placeholder: 'Relatienr',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'naam',
                placeholder: 'Naam',
                icon: null,
                width: '35em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: null,
        label: 'Soort relatie',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'RelatieSelect',
                field: 'soort_relatie',
                placeholder: 'Soort relatie',
                icon: null,
                width: null,
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A22',
        groupType: 'Inline',
        label: 'Afleveradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A23',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A24',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B21',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TelInput',
                field: 'telefoon_2',
                placeholder: 'Telefoon 2',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B22',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    }
];

export const offerteGridBoxesAantekeningen = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Aantekeningen", boxWidth: '100%'},
        ]
    }
]

export const offerteUiItemsAantekeningen = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextEdit',
                field: 'aantekeningen',
                placeholder: 'Vul hier de aantekeningen in...',
                minLines: 22,
                maxLines: 30,
                action: 'textedit',
                restrict: null,
                readOnly: false
            }
        ]
    }
]
