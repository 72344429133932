// Multitenant config
export const IS_MULTITENANT = (process.env.REACT_APP_INSTANCE_TYPE === 'multitenant')

// Main API Url
const baseProtocol = process.env.REACT_APP_BASE_PROTOCOL
const baseUrl = process.env.REACT_APP_BASE_URL
export const APIURL = baseProtocol + baseUrl

// Login
export const TokenUrl = APIURL + '/token/'
export const TokenRefreshUrl = APIURL + '/token/refresh/'

// Admin API calls
export const RegisterUrl = APIURL + '/admin/register/'
export const ChangePasswordUrl = APIURL + '/admin/change_password/'
export const UserGroupUrl = APIURL + '/admin/usergroup/'
export const UserUrl = APIURL + '/admin/user/'
export const LoadUserUrl = APIURL + '/admin/loaduser/'
export const UpdateProfileUrl = APIURL + '/admin/update_profile/'
export const UpdateGroupUrl = APIURL + '/admin/update_group/'

// Modules and Settings
export const ModulesUrl = APIURL + '/main/appmodules/'
export const SettingsUrl = APIURL + '/main/appsettings/'
export const PrjLabelUrl = APIURL + '/main/prjlabel/'

// Main API calls
export const CrmUrl = APIURL + '/main/crm/'
export const MwUrl = APIURL + '/main/mw/'
export const BkUrl = APIURL + '/main/bk/'
export const UrenUrl = APIURL + '/main/uren/'
export const FactuurUrl = APIURL + '/main/factuur/'
export const FactPosUrl = APIURL + '/main/factpos/'
export const FactuurFileUrl = APIURL + '/main/factuur/file/'
export const OfferteUrl = APIURL + '/main/offerte/'
export const OrderUrl = APIURL + '/main/order/'
export const OrderPosUrl = APIURL + '/main/pos/'
export const OrderPayUrl = APIURL + '/main/pay/'
export const OrderContractUrl = APIURL + '/main/contract/'
export const InkorderRegelUrl = APIURL + '/main/inkorderregel/'
export const OrderRegelTxtUrl = APIURL + '/main/orderregeltxt/'
export const OrderMetricsUrl = APIURL + '/main/order/metrics/'
export const ProdSoortUrl = APIURL + '/main/prdsoort/'
export const PrjFaseUrl = APIURL + '/main/prjfase/'
export const InvoiceUrl = APIURL + '/main/invoice/'
export const InvoiceCopyUrl = APIURL + '/main/invoicecopy/'
export const InvoiceCopyFileUrl = APIURL + '/main/invoicecopy/file/'
export const PlanInvoiceUrl = APIURL + '/main/planinvoice/'
export const InkooporderUrl = APIURL + '/main/inkooporder/'
export const InkooporderFileUrl = APIURL + '/main/inkooporder/file/'
export const InkooporderTermijnPaidUrl = APIURL + '/main/inkooporder/termijnpaid/'
export const OfferteDocUrl = APIURL + '/main/offertedoc/'
export const OfferteFileUrl = APIURL + '/main/offertedoc/file/'
export const OfferteToOrderUrl = APIURL + '/main/offerte/makeorder/'
export const OpleverDocUrl = APIURL + '/main/opleverdoc/'
export const OpleverFileUrl = APIURL + '/main/opleverdoc/file/'
export const MetricsCashUrl = APIURL + '/main/metrics/cash/'
export const ReportFacturenUrl = APIURL + '/main/report/facturen/'
export const ReportOffertesUrl = APIURL + '/main/report/offertes/'
export const ReportOrdersUrl = APIURL + '/main/report/orders/'
export const ReportFileUrl = APIURL + '/main/report/file/'
export const LiteFactuurUrl = APIURL + '/main/lite/factuur/'
export const LitePosUrl = APIURL + '/main/lite/pos/'
export const LiteInvoiceUrl = APIURL + '/main/lite/invoice/'

// UI API calls
export const TableviewUrl = APIURL + '/ui/tableviews/'
export const MenuUrl = APIURL + '/ui/menu/'
