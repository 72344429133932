import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card, FormGroup, RadioGroup, Radio, Switch, Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { load, update, reset } from '../../utils/positionSlice';
import { add, update as tableUpdate } from '../../utils/postableSlice';
import { TextInput } from '../../components/TextInput';
import { ProductSoortSelect } from '../../components/ProductSoortSelect';
import { BtwSelect } from '../../components/BtwSelect';

class PositionDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.createRowData = this.createRowData.bind(this);
    this.loadRowData = this.loadRowData.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidMount() {
    if (this.props.newRecord) {
      this.createRowData();
    } else {
      // if (this.props.postable.selectedRowData !== null) {
      const posId = this.props.postable.selectedRowData.order_positions_id;
      this.loadRowData(posId);
      // }
    }
  }

  componentDidUpdate() {
    if (this.props.newRecord && !this.props.position.createRecord) {
      this.createRowData();
    } else {
      if (!this.props.newRecord && this.props.position.recordData !== null) {
        // Selected row from table prop; compare to loaded record data in store
        const selectedId = this.props.postable.selectedRowData.order_positions_id;
        const loadedId = this.props.position.recordData.order_positions_id;

        if (selectedId !== loadedId && this.props.showDialog) {
          this.loadRowData(selectedId);
        }
      }
    }
  }

  createRowData() {
    const bodyData = {"order_id": this.props.orderId};

    axios.post(API.OrderPosUrl, bodyData)
      .then(response => {
        const payload = { recordData: response.data, createRecord: true };
        this.dispatch(load(payload));
        this.dispatch(add({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadRowData(posId) {
    axios.get(API.OrderPosUrl, {
      params: {
        order_positions_id: posId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
      })
      .catch(error => {
          console.error(error);
      })
  }

  updateRowData(posId, data) {
    axios.put(API.OrderPosUrl, data, {
      params: {
        order_positions_id: posId
      }
    })
      .then(response => {
        this.dispatch(tableUpdate({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  handleTypeChange = (e) => {
    this.dispatch(update({ 'opdracht': e.target.value === '0' }));
    this.dispatch(update({ 'meerwerk': e.target.value === '1' }));
    this.dispatch(update({ 'regie': e.target.value === '2' }));
    this.dispatch(update({ 'intern': e.target.value === '3' }));
    this.dispatch(update({ 'credit': e.target.value === '4' }));
  }

  parseType = () => {
    var typeValue = '';

    if (this.props.position.recordData.opdracht) {
      typeValue = '0';
    } else if (this.props.position.recordData.meerwerk) {
      typeValue = '1';
    } else if (this.props.position.recordData.regie) {
      typeValue = '2';
    } else if (this.props.position.recordData.intern) {
      typeValue = '3';
    } else if (this.props.position.recordData.credit) {
      typeValue = '4';
    }

    return typeValue;
  }

  handleUrenChange = (e) => {
    this.dispatch(update({ 'uren': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.position.recordData.tarief }));
  }

  handleTariefChange = (e) => {
    this.dispatch(update({ 'tarief': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.position.recordData.uren }));
  }

  handleBedragChange = (e) => {
    this.dispatch(update({ 'bedrag': e.target.value }));
  }

  saveClick(closeDialog) {
    const posRecord = this.props.position.recordData;
    const recordData = Object.assign({}, posRecord);

    const posId = posRecord.order_positions_id
    delete recordData.order_positions_id
    this.updateRowData(posId, recordData)

    this.dispatch(reset());
    closeDialog();
  }

  cancelClick(closeDialog) {
    this.dispatch(reset());
    closeDialog();
  }

  renderGeneral = () => {
    return (
      <Card key={1} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Omschrijving en tarief
        </p>

        <div>
          <TextInput
            key={1}
            type='text'
            width='10em'
            value={this.props.position.recordData.order_positions_id}
            readOnly={true}
            small={true}
          />
          <TextInput
            key={2}
            type='text'
            placeholder='Omschrijving'
            width='40em'
            value={this.props.position.recordData.productomschrijving}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'productomschrijving': e.target.value }))}
            small={true}
          />
          <ProductSoortSelect
            key={3}
            value={this.props.position.recordData.productsoort}
            onSelect={(e) => this.dispatch(update({ 'productsoort': e.text }))}
          />

          <FormGroup key={4} helperText='Uren, tarief en bedrag' inline={true} style={{margin: '0.0em'}}/>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              key={5}
              type='number'
              icon={IconNames.TIME}
              placeholder='Uren'
              width='10em'
              value={this.props.position.recordData.uren}
              readOnly={false}
              onChange={this.handleUrenChange}
              small={true}
              min={0}
            />
            <TextInput
              key={6}
              type='number'
              icon={IconNames.EURO}
              placeholder='Tarief'
              width='10em'
              value={this.props.position.recordData.tarief}
              readOnly={false}
              onChange={this.handleTariefChange}
              small={true}
              min={0}
            />
            <TextInput
              key={7}
              type='number'
              icon={IconNames.EURO}
              placeholder='Bedrag'
              width='10em'
              value={this.props.position.recordData.bedrag}
              readOnly={this.props.position.recordData.uren > 0}
              onChange={this.handleBedragChange}
              small={true}
              min={0}
            />
          </div>

          <FormGroup key={9} helperText='BTW' inline={true} style={{margin: '0.0em'}}/>
          <BtwSelect
            key={10}
            value={this.props.position.recordData.btw}
            onSelect={(e) => this.dispatch(update({ 'btw': e.key }))}
          />

        </div>
      </Card>
    )
  }

  renderProjectType = () => {
    return (
      <Card key={2} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '65%'}}
      >
        <p className={Classes.HEADING}>
          Soort project
        </p>

        <div>
          <RadioGroup inline={true} onChange={this.handleTypeChange} selectedValue={this.parseType()}
            disabled={this.props.position.recordData.gefactureerd}>
            <Radio label='Opdracht' value='0' />
            <Radio label='Meerwerk' value='1' />
            <Radio label='Regie' value='2' />
            <Radio label='Intern' value='3' />
            <Radio label='Credit' value='4' />
          </RadioGroup>
        </div>
      </Card>
    )
  }

  renderFactuurStatus = () => {
    return (
      <Card key={3} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '35%'}}
      >
        <p className={Classes.HEADING}>
          Facturatie status
        </p>

        <div>
          <Checkbox
            label='Gefactureerd'
            checked={this.props.position.recordData.gefactureerd}
            readOnly={true}
          />
        </div>
      </Card>
    )
  }

  renderGRekening = () => {
    return (
      <Card key={3} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '40%'}}
      >
        <p className={Classes.HEADING}>
          G-Rekening
        </p>

        <div>
          <Switch
            label='Toepassen'
            checked={this.props.position.recordData.grekening}
            onChange={(e) => this.dispatch(update({ 'grekening': e.target.checked }))}
          />
          <TextInput
            type='number'
            icon={IconNames.PERCENTAGE}
            width='10em'
            value={this.props.position.recordData.gperc}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'gperc': e.target.value }))}
            small={true}
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.position.recordData !== null) {
      return (
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderProjectType()}
            {this.renderFactuurStatus()}
          </div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderGeneral()}
            {this.renderGRekening()}
          </div>
        </div>      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Orderpositie'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '60%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          {this.renderDialog()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  position: state.position,
  postable: state.postable
});

export default connect(mapStateToProps)(PositionDialog);
