import { createSlice } from '@reduxjs/toolkit';

const tableSlice = createSlice({
    name: 'postable',
    initialState: {
        columnData: [],
        rowData: [],
        selectedRowIdx: null,
        selectedRowData: null
    },
    reducers: {
        load: {
            reducer(state, { payload }) {
                const { columnData, rowData } = payload
                state.columnData = columnData
                state.rowData = rowData
            },
        },
        select: {
            reducer(state, { payload }) {
                const { selectedRowIdx, selectedRowData } = payload
                state.selectedRowIdx = selectedRowIdx
                state.selectedRowData = selectedRowData
            },
        },
        update: {
            reducer(state, { payload }) {
                const { rowData } = payload
                state.selectedRowData = rowData
                state.rowData[state.selectedRowIdx] = rowData
            },
        },
        add: {
            reducer(state, { payload }) {
                const { rowData } = payload
                state.rowData.push(rowData)
                state.selectedRowIdx = state.rowData.length - 1
                state.selectedRowData = rowData
            },
        },
        del: {
            reducer(state, { payload }) {
                const { rowIdx } = payload
                state.rowData.splice(rowIdx, 1)
                state.selectedRowIdx = null
                state.selectedRowData = null
            },
        }
    }
})

const { actions, reducer } = tableSlice;
export const { load, select, update, add, del } = actions;

export default reducer
