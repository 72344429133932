import React, {Component} from 'react';
import moment from 'moment';
import { DateInput } from '@blueprintjs/datetime'; 
import { dateFormats } from '../utils/formats';

export class DateEntry extends Component {
  render() {
    return (
      <DateInput
        formatDate={date => moment(date).format(dateFormats.DISPLAYFORMAT)}
        parseDate={str => moment(str, dateFormats.DISPLAYFORMAT).toDate()}
        placeholder={this.props.placeholder}
        value={moment(this.props.value, dateFormats.STORAGEFORMAT).toDate()}
        disabled={this.props.readOnly}
        onChange={this.props.onChange}
        fill={this.props.fill}
        maxDate={moment().add(20, 'years').toDate()}
      />
    );
  };
};

