import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { load, update, reset } from '../../utils/inkorderregelSlice';
import { add, update as tableUpdate } from '../../utils/inkorderregeltableSlice';
import { TextInput } from '../../components/TextInput';
import { TextEdit } from '../../components/TextEdit';
import { LevSoortSelect } from '../../components/LevSoortSelect';
import SearchDialog from '../../components/SearchDialog';

class InkorderregelDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchDialog: false,
      tableName: null,
      updateFields: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.createRowData = this.createRowData.bind(this);
    this.loadRowData = this.loadRowData.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidMount() {
    if (this.props.newRecord) {
      this.createRowData();
    } else {
      const inkorderregelId = this.props.inkorderregeltable.selectedRowData.inkorderregel_id;
      this.loadRowData(inkorderregelId);
    }
  }

  componentDidUpdate() {
    if (this.props.newRecord && !this.props.inkorderregel.createRecord) {
      this.createRowData();
    } else {
      if (!this.props.newRecord && this.props.inkorderregel.recordData !== null) {
        // Selected row from table prop; compare to loaded record data in store
        const selectedId = this.props.inkorderregeltable.selectedRowData.inkorderregel_id;
        const loadedId = this.props.inkorderregel.recordData.inkorderregel_id;

        if (selectedId !== loadedId && this.props.showDialog) {
          this.loadRowData(selectedId);
        }
      }
    }
  }

  createRowData() {
    const bodyData = {"order_contracts_id": this.props.orderContractsId};

    axios.post(API.InkorderRegelUrl, bodyData)
      .then(response => {
        const payload = { recordData: response.data, createRecord: true };
        this.dispatch(load(payload));
        this.dispatch(add({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadRowData(inkorderregelId) {
    axios.get(API.InkorderRegelUrl, {
      params: {
        inkorderregel_id: inkorderregelId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
      })
      .catch(error => {
          console.error(error);
      })
  }

  updateRowData(inkorderregelId, data) {
    axios.put(API.InkorderRegelUrl, data, {
      params: {
        inkorderregel_id: inkorderregelId
      }
    })
      .then(response => {
        this.dispatch(tableUpdate({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  saveClick(closeDialog) {
    const inkorderregelRecord = this.props.inkorderregel.recordData;
    const recordData = Object.assign({}, inkorderregelRecord);

    const inkorderregelId = inkorderregelRecord.inkorderregel_id
    delete recordData.inkorderregel_id
    this.updateRowData(inkorderregelId, recordData)

    this.dispatch(reset());
    closeDialog();
  }

  cancelClick(closeDialog) {
    this.dispatch(reset());
    closeDialog();
  }

  searchClick = () => {
    const fields = {
      'omschrijving': 'omschrijving',
      'regeltekst': 'regeltekst'
    }
    this.setState({ showSearchDialog: true, tableName: 'orderregeltxt', updateFields: fields })
  }

  renderOrderRegel = () => {
    return (
      <Card key={1} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '100%'}}
      >
        <p className={Classes.HEADING}>
          Inkooporderregel
        </p>

        <div>
          <TextInput
            type='text'
            width='12em'
            value={this.props.inkorderregel.recordData.inkorderregel_id}
            readOnly={true}
            small={true}
          />
          <div style={{display: 'inline-flex'}}>
            <Button
              icon={IconNames.SEARCH}
              intent={Intent.PRIMARY}
              onClick={() => this.searchClick()}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Omschrijving'
              width='40em'
              value={this.props.inkorderregel.recordData.omschrijving}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'omschrijving': e.target.value }))}
              small={true}
            />
          </div>
          <TextEdit
            placeholder='Voer regeltekst in...'
            minLines={5}
            maxLines={20}
            value={this.props.inkorderregel.recordData.regeltekst}
            readOnly={false}
            onChange={(text) => this.dispatch(update({ 'regeltekst': text }))}
          />
        </div>
      </Card>
    )
  }

  renderBedragSoort = () => {
    return (
      <Card key={3} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '100%'}}
      >
        <p className={Classes.HEADING}>
          Bedrag en soort levering
        </p>

        <div>
          <TextInput
            type='number'
            icon={IconNames.EURO}
            width='10em'
            value={this.props.inkorderregel.recordData.bedragexcl}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'bedragexcl': e.target.value }))}
            small={true}
          />
          <LevSoortSelect
            value={this.props.inkorderregel.recordData.levsoort}
            onSelect={(e) => this.dispatch(update({ 'levsoort': e.key }))}
            width='15em'
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.inkorderregel.recordData !== null) {
      return (
        <div>
          {this.renderOrderRegel()}
          {this.renderBedragSoort()}
          <SearchDialog
            showDialog={this.state.showSearchDialog}
            title='Zoeken'
            table={this.state.tableName}
            fields={this.state.updateFields}
            reducer={'inkorderregel'}
            onClose={() => this.setState({ showSearchDialog: false })}
          />
        </div>
      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Orderpositie'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '40%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '94%'}} >
          {this.renderDialog()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  inkorderregel: state.inkorderregel,
  inkorderregeltable: state.inkorderregeltable
});

export default connect(mapStateToProps)(InkorderregelDialog);
