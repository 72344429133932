import React, {Component} from 'react'
import { InputGroup, Button } from '@blueprintjs/core'
import { Intent } from '@blueprintjs/core'

export class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    }
  };

  render() {
    const { showPassword } = this.state;
    var { autoComplete } = this.props;

    if (autoComplete === undefined) {
      autoComplete = "on";
    }

    const lockButton = (
      <Button
        icon={showPassword ? "unlock" : "lock"}
        intent={Intent.WARNING}
        minimal={true}
        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
      />
    );

    return (
      <div>
        <InputGroup
          autoComplete={autoComplete}
          rightElement={lockButton}
          value={this.props.text}
          onChange={this.props.onChange}
          maxLength={this.props.length}
          type={showPassword ? "text" : "password"}
          disabled={this.props.disabled}
          style={{marginBottom: '1em'}}
        />
      </div>
    );
  };
};

