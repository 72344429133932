import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { load, update, reset } from '../../utils/litepositionSlice';
import { add, update as tableUpdate } from '../../utils/litepostableSlice';
import { TextInput } from '../../components/TextInput';
import { ProductSoortSelect } from '../../components/ProductSoortSelect';
import { BtwSelect } from '../../components/BtwSelect';

class LitePositionDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.createRowData = this.createRowData.bind(this);
    this.loadRowData = this.loadRowData.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidMount() {
    if (this.props.newRecord) {
      this.createRowData();
    } else {
      const posId = this.props.litepostable.selectedRowData.lite_positions_id;
      this.loadRowData(posId);
    }
  }

  componentDidUpdate() {
    if (this.props.newRecord && !this.props.liteposition.createRecord) {
      this.createRowData();
    } else {
      if (!this.props.newRecord && this.props.liteposition.recordData !== null) {
        // Selected row from table prop; compare to loaded record data in store
        const selectedId = this.props.litepostable.selectedRowData.lite_positions_id;
        const loadedId = this.props.liteposition.recordData.lite_positions_id;

        if (selectedId !== loadedId && this.props.showDialog) {
          this.loadRowData(selectedId);
        }
      }
    }
  }

  createRowData() {
    const bodyData = {"lite_id": this.props.liteId};

    axios.post(API.LitePosUrl, bodyData)
      .then(response => {
        const payload = { recordData: response.data, createRecord: true };
        this.dispatch(load(payload));
        this.dispatch(add({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadRowData(liteId) {
    axios.get(API.LitePosUrl, {
      params: {
        lite_positions_id: liteId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
      })
      .catch(error => {
          console.error(error);
      })
  }

  updateRowData(liteId, data) {
    axios.put(API.LitePosUrl, data, {
      params: {
        lite_positions_id: liteId
      }
    })
      .then(response => {
        this.dispatch(tableUpdate({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  handleUrenChange = (e) => {
    this.dispatch(update({ 'uren': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.liteposition.recordData.tarief }));
  }

  handleTariefChange = (e) => {
    this.dispatch(update({ 'tarief': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.liteposition.recordData.uren }));
  }

  handleBedragChange = (e) => {
    this.dispatch(update({ 'bedrag': e.target.value }));
  }

  saveClick(closeDialog) {
    const posRecord = this.props.liteposition.recordData;
    const recordData = Object.assign({}, posRecord);

    const posId = posRecord.lite_positions_id
    delete recordData.lite_positions_id
    this.updateRowData(posId, recordData)

    this.dispatch(reset());
    closeDialog();
  }

  cancelClick(closeDialog) {
    this.dispatch(reset());
    closeDialog();
  }

  renderGeneral = () => {
    return (
      <Card key={1} elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '100%'}}
      >
        <p className={Classes.HEADING}>
          Omschrijving en tarief
        </p>

        <div>
          <TextInput
            key={1}
            type='text'
            width='10em'
            value={this.props.liteposition.recordData.lite_positions_id}
            readOnly={true}
            small={true}
          />
          <TextInput
            key={2}
            type='text'
            placeholder='Omschrijving'
            width='40em'
            value={this.props.liteposition.recordData.productomschrijving}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'productomschrijving': e.target.value }))}
            small={true}
          />
          <ProductSoortSelect
            key={3}
            value={this.props.liteposition.recordData.productsoort}
            onSelect={(e) => this.dispatch(update({ 'productsoort': e.text }))}
          />

          <FormGroup key={4} helperText='Uren, tarief en bedrag' inline={true} style={{margin: '0.0em'}}/>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              key={5}
              type='number'
              icon={IconNames.TIME}
              placeholder='Uren'
              width='10em'
              value={this.props.liteposition.recordData.uren}
              readOnly={false}
              onChange={this.handleUrenChange}
              small={true}
              min={0}
            />
            <TextInput
              key={6}
              type='number'
              icon={IconNames.EURO}
              placeholder='Tarief'
              width='10em'
              value={this.props.liteposition.recordData.tarief}
              readOnly={false}
              onChange={this.handleTariefChange}
              small={true}
              min={0}
            />
            <TextInput
              key={7}
              type='number'
              icon={IconNames.EURO}
              placeholder='Bedrag'
              width='10em'
              value={this.props.liteposition.recordData.bedrag}
              readOnly={this.props.liteposition.recordData.uren > 0}
              onChange={this.handleBedragChange}
              small={true}
              min={0}
            />
          </div>

          <FormGroup key={9} helperText='BTW' inline={true} style={{margin: '0.0em'}}/>
          <BtwSelect
            key={10}
            value={this.props.liteposition.recordData.btw}
            onSelect={(e) => this.dispatch(update({ 'btw': e.key }))}
          />

        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.liteposition.recordData !== null) {
      return (
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderGeneral()}
          </div>
        </div>      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Orderpositie'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '50%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          {this.renderDialog()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  liteposition: state.liteposition,
  litepostable: state.litepostable
});

export default connect(mapStateToProps)(LitePositionDialog);
