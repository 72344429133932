import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Drawer, Toaster, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { update } from '../utils/dialogSlice';
import { update as contractUpdate } from '../utils/contractSlice';
import { update as inkorderregelUpdate } from '../utils/inkorderregelSlice';
import MszTable from './MszTable';
import * as API from '../mainapi';

class SearchDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: null,
      columnData: [],
      rowData: [],
      selectedRowIdx: null,
      selectedRowData: null,
      toasterKey: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
    this.selectClick = this.selectClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidUpdate() {
    const { table } = {...this.state};

    if (this.props.table !== table && this.props.showDialog) {
      this.setState({ table: this.props.table });
      this.loadTableData(this.props.table);
    }
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 500,
    };
  }

  loadTableData(table) {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });
    var url = null;

    // Check if url prop is passed, means the url is different from the table name
    if (this.props.url !== null && this.props.url !== undefined) {
      url = this.props.url + '/';
    } else {
      url = table + '/';
    }

    if (this.props.filter !== undefined) {
      axios.get(API.APIURL + '/main/' + url, {
        params: this.props.filter
      })
        .then(response => {
          const data = response.data;
          this.parseGrid(this.props.table, data);
        })
        .catch(error => {
            console.error(error);
        })
    } else {
      axios.get(API.APIURL + '/main/' + url)
        .then(response => {
          const data = response.data;
          this.parseGrid(this.props.table, data);
        })
        .catch(error => {
            console.error(error);
        })

    }
  }

  parseGrid(tableName, tableData) {
    this.toaster.show(this.renderProgress(75), this.state.toasterKey)
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;

        data.forEach((record) => {
          var newData = {
            field: record.fieldname,
            headerName: record.description.toString(),
            hide: (record.visible === 0)
          };
          columnData.push(newData);
        })

        this.resetSelectedRow()
        this.setState({ rowData: tableData, columnData: columnData });
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
      })
      .catch(error => {
          console.error(error);
      })
  }

  resetSelectedRow() {
    this.setState({
      selectedRowIdx: null,
      selectedRowData: null
    });
  }

  rowClicked(event) {
    this.setState({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data });
  }

  rowDoubleClicked(event, closeDialog) {
    this.setState({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data });
    this.selectClick(closeDialog);
  }

  selectClick(closeDialog) {
    const { reducer } = this.props;

    Object.keys(this.props.fields).forEach(key => {
      const value = this.state.selectedRowData[this.props.fields[key]];

      if (reducer === undefined | reducer === null) {
        this.dispatch(update({ [`${key}`]: value }));
      } else {
        switch (reducer) {
          case 'contract': this.dispatch(contractUpdate({ [`${key}`]: value }));
            break
          case 'inkorderregel': this.dispatch(inkorderregelUpdate({ [`${key}`]: value }));
            break
        }
      }
    });

    closeDialog();
  }

  cancelClick(closeDialog) {
    closeDialog();
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Drawer
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1, width: '75%'}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <MszTable
              tableColumns={this.state.columnData}
              tableRows={this.state.rowData}
              rowClicked={this.rowClicked}
              rowDoubleClicked={(e) => this.rowDoubleClicked(e, onClose)}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              <Button
                icon={IconNames.SELECT}
                intent={Intent.PRIMARY}
                onClick={() => this.selectClick(onClose)}>
                  Selecteren
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  contract: state.contract
});

export default connect(mapStateToProps)(SearchDialog);
