/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/

export const urenGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Boeking", boxWidth: '55%'},
            {boxId: 'B1', boxTitle: "Medewerker", boxWidth: '45%'},
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Projectgegevens", boxWidth: '100%'},
        ]
    }
]

export const urenUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'boeking_id',
                placeholder: 'Boeking ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: 'Label',
        label: 'Omschrijving',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'omschrijving',
                placeholder: 'Omschrijving',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A13',
        groupType: 'Label',
        label: 'Boekingsdatum',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 2,
                elementType: 'DateInput',
                field: 'datum_boeking',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A15',
        groupType: 'Label',
        label: 'Uren en bedrag',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A16',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'uren',
                placeholder: 'Uren',
                icon: 'time',
                width: '10em',
                action: 'calculate',
                operator: '*',
                calcField: 'kostprijsuur',
                target: 'bedrag',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'bedrag',
                placeholder: 'Bedrag',
                icon: 'euro',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A17',
        groupType: 'Label',
        label: 'Boeken als',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A18',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'UursoortSelect',
                field: 'uursoort',
                placeholder: 'Uursoort',
                icon: null,
                width: '10em',
                action: 'keyselect',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 0,
                elementType: 'Search',
                table: 'medewerkers',
                url: 'mw',
                action: 'search',
                fields: {
                    'fk_medewerker_id': 'medewerker_id',
                    'voornaam': 'voornaam',
                    'achternaam': 'achternaam',
                    'functie': 'functie',
                    'kostprijsuur': 'kostprijsuur',
                    'uursoort': 'uursoort'
                }
            },
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fk_medewerker_id',
                placeholder: 'Medewerker ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: 'Label',
        label: 'Voornaam',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'voornaam',
                placeholder: 'Voornaam',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B13',
        groupType: 'Label',
        label: 'Achternaam',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'achternaam',
                placeholder: 'Achternaam',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B15',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'functie',
                placeholder: 'Functie',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B16',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'kostprijsuur',
                placeholder: 'Kostprijs / uur',
                icon: 'dollar',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 0,
                elementType: 'Search',
                table: 'orderboek',
                url: 'order',
                action: 'search',
                fields: {
                    'fk_order_id': 'order_id',
                    'naam_opdrachtgever': 'naam',
                    'order_omschrijving': 'omschrijving'
                },
                filter: {'afgesloten': false}
            },
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fk_order_id',
                placeholder: 'Order ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: 'Label',
        label: 'Naam opdrachtgever',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A22',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'naam_opdrachtgever',
                placeholder: 'Naam opdrachtgever',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A23',
        groupType: 'Label',
        label: 'Omschrijving project',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A24',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'order_omschrijving',
                placeholder: 'Omschrijving project',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    }
];
