import React, {Component} from 'react'
import { InputGroup } from '@blueprintjs/core'

export class TextInput extends Component {
  render() {
    var { small, large, disabled, id, autoComplete } = this.props;

    if (small === undefined) {
      small = false;
    }
    if (large === undefined) {
      large = false;
    }
    if (disabled === undefined) {
      disabled = false;
    }
    if (id === undefined) {
      id = '';
    }
    if (autoComplete === undefined) {
      autoComplete = "on";
    }

    return (
      <InputGroup
        autoComplete={autoComplete}
        id={id}
        type={this.props.type}
        placeholder={this.props.placeholder}
        leftIcon={this.props.icon}
        value={this.props.value}
        onChange={this.props.onChange}
        rightElement={this.props.element}
        readOnly={this.props.readOnly}
        min={this.props.min}
        small={small}
        large={large}
        disabled={disabled}
        style={{marginBottom: '0.1em', width: this.props.width}}
      />
    );
  };
};

