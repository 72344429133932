import React, {Component} from 'react';
import axios from 'axios';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Toaster, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { PasswordInput } from './PasswordInput';
import * as API from '../mainapi';

class SetPasswordDialog extends Component {
  constructor(props) {
    super(props);

    this.toaster = Toaster;

    this.state = {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    };

    this.currentUserId = sessionStorage.getItem("currentUserId");
  }

  cancelClick = (closeDialog) => {
    closeDialog();
  }

  submitClick = (closeDialog) => {
    const requestBody = {
      "password": this.state.newPassword,
      "password2": this.state.newPassword2,
      "old_password": this.state.oldPassword
    };

    let fullUrl = API.ChangePasswordUrl + this.currentUserId + "/";
    console.log(fullUrl);

    axios.put(fullUrl, requestBody)
      .then(response => {
        console.log(response);

        if (response.status === 200) {
          this.toaster.show({ icon: IconNames.CONFIRM, intent: Intent.SUCCESS, message: 'Wachtwoord is gewijzigd.' });
          closeDialog();
        } else {
          this.toaster.show({ icon: IconNames.WARNING_SIGN, intent: Intent.WARNING, message: 'Niet gelukt.' });
        }
      })
      .catch(error => {
          console.error(error);
          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: 'Het oude wachtwoord klopt niet of het nieuwe wachtwoord voldoet niet aan de eisen.'
          });
      })
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <FormGroup helperText="Oude wachtwoord" style={{margin: '0.0em'}}>
            </FormGroup>
            <PasswordInput
              text={this.state.oldPassword}
              onChange={(e) => this.setState({ oldPassword: e.target.value })}
            />
            <FormGroup helperText="Nieuwe wachtwoord" style={{margin: '0.0em'}}>
            </FormGroup>
            <PasswordInput
              text={this.state.newPassword}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
            <FormGroup helperText="Controle nieuwe wachtwoord" style={{margin: '0.0em'}}>
            </FormGroup>
            <PasswordInput
              text={this.state.newPassword2}
              onChange={(e) => this.setState({ newPassword2: e.target.value })}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              <Button
                icon={IconNames.CONFIRM}
                intent={Intent.WARNING}
                disabled={this.state.newPassword !== this.state.newPassword2 || this.state.newPassword === ""}
                onClick={() => this.submitClick(onClose)}>
                  Wijzigen
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default SetPasswordDialog;
