import React, {Component} from 'react';
import axios from 'axios';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

class DeleteDialog extends Component {
  constructor(props) {
    super(props);

    this.toaster = Toaster;

    this.state = {
      selectedRowIdx: null,
      selectedRowData: null,
    };

  }

  noClick = (closeDialog) => {
    closeDialog();
  }

  yesClick = (closeDialog) => {
    axios.delete(this.props.url, {
      params: this.props.apiParams
    })
      .then(response => {
        if (response.status === 202) {
          this.props.onDelete();
          this.toaster.show({ icon: IconNames.CONFIRM, intent: Intent.SUCCESS, message: 'Verwijderd' });
        }
      })
      .catch(error => {
          console.error(error);
      })

    closeDialog();
  }

  render() {
    const { showDialog, title, onClose, message } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            {message}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.noClick(onClose)}>
                  Nee
              </Button>
              <Button
                icon={IconNames.TRASH}
                intent={Intent.DANGER}
                onClick={() => this.yesClick(onClose)}>
                  Ja
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default DeleteDialog;
