import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update, del } from "../utils/tableSlice";

export function getFactuur(id) {
  axios.get(API.FactuurUrl, {
    params: {
      factuurnr: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createFactuur() {
  axios.post(API.FactuurUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateFactuur(id, data, invoicePaid) {
  const state = store.getState();

  axios.put(API.FactuurUrl, data, {
    params: {
      factuurnr: id,
      bookPaid: invoicePaid
    }
  })
    .then(response => {
      if (invoicePaid) {
        store.dispatch(del({ rowIdx: state.maintable.selectedRowIdx }));
      } else {
        store.dispatch(update({ rowData: response.data }));
      }
    })
    .catch(error => {
        console.error(error);
    })
}
