/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/

export const crmGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Relatienr en -naam", boxWidth: '54%'},
            {boxId: 'B1', boxTitle: "Certificering", boxWidth: '28%'},
            {boxId: 'C1', boxTitle: "d.d.", boxWidth: '18%'}
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Afleveradres", boxWidth: '55%'},
            {boxId: 'B2', boxTitle: "Contactgegevens", boxWidth: '45%'}
        ]
    },
    {
        line: 3,
        boxes: [
            {boxId: 'A3', boxTitle: "Factuuradres", boxWidth: '55%'},
            {boxId: 'B3', boxTitle: "Contactgegevens administratie", boxWidth: '45%'}
        ]
    }
]

export const crmUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'contact_id',
                placeholder: 'Relatienr',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'naam',
                placeholder: 'Naam',
                icon: null,
                width: '35em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'kvk',
                placeholder: 'KvK nr',
                icon: 'office',
                width: '15em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'btwnr',
                placeholder: 'BTW nr',
                icon: null,
                width: '15em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: null,
        label: 'Soort relatie',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'RelatieSelect',
                field: 'soort_relatie',
                placeholder: 'Soort relatie',
                icon: null,
                width: null,
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Checkbox',
                field: 'inkoopvw',
                label: 'Inkoopvoorwaarden getekend',
                icon: null,
                action: 'check',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Checkbox',
                field: 'vca',
                label: 'VCA Certificaat',
                icon: null,
                action: 'check',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Checkbox',
                field: 'wka',
                label: 'WKA Certificaat',
                icon: null,
                action: 'check',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'datum_inkoopvw',
                label: 'd.d.',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'datumvca',
                label: 'd.d.',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'datumwka',
                label: 'd.d.',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: 'Inline',
        label: 'Afleveradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A22',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B21',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TelInput',
                field: 'telefoon_2',
                placeholder: 'Telefoon 2',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B22',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B23',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'website',
                placeholder: 'Website',
                icon: 'globe-network',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A3',
        groupType: 'Inline',
        label: 'Factuuradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fact_adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'fact_huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'fact_hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A31',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fact_postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'fact_plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A32',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'fact_land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B3',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'fact_aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'fact_contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B31',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'fact_telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B32',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'fact_email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B33',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'iban',
                placeholder: 'IBAN',
                icon: 'bank-account',
                width: '17em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'bic',
                placeholder: 'BIC',
                icon: null,
                width: '17em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    }
];

export const crmGridBoxesAfspraken = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Afspraken", boxWidth: '100%'},
        ]
    }
]

export const crmUiItemsAfspraken = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextEdit',
                field: 'afspraken',
                placeholder: 'Vul hier gemaakte afspraken in...',
                minLines: 22,
                maxLines: 30,
                action: 'textedit',
                restrict: null,
                readOnly: false
            }
        ]
    }
]
