import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update, del } from "../utils/tableSlice";

export function getLiteFactuur(id) {
  axios.get(API.LiteFactuurUrl, {
    params: {
      lite_id: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createLiteFactuur() {
  axios.post(API.LiteFactuurUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateLiteFactuur(id, data) {
  axios.put(API.LiteFactuurUrl, data, {
    params: {
      lite_id: id
    }
  })
    .then(response => {
      store.dispatch(update({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateInvoiced() {
  const state = store.getState();
  store.dispatch(del({ rowIdx: state.maintable.selectedRowIdx }));
}
