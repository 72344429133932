import React, {Component} from 'react';
import { Checkbox } from '@blueprintjs/core';


class CheckboxList extends Component {
  toggleItemSelection = (item) => {
    const { selectedItems, onToggleItem } = this.props;

    if (selectedItems.includes(item)) {
      onToggleItem(selectedItems.filter((i) => i !== item));
    } else {
      onToggleItem([...selectedItems, item]);
    }
  };

  render() {
    const { fixedList, selectedItems } = this.props;

    return (
      <div>
        {fixedList.map((item) => (
            <Checkbox
              key={item}
              label={item}
              checked={selectedItems.includes(item)}
              onChange={() => this.toggleItemSelection(item)}
            />
        ))}
      </div>
    );
  }
}

export default CheckboxList;
