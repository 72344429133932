import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { saveAs } from 'file-saver';
import { TextInput } from '../../components/TextInput';
import { DateEntry } from '../../components/DateEntry';
import * as API from '../../mainapi';
import moment from 'moment';

class OfferteDocDialog extends Component {
  constructor(props) {
    super(props);

    this.toaster = Toaster;

    this.state = {
      handledBy: "",
      signDate: moment(),
    };

  }

  cancelClick = (closeDialog) => {
    closeDialog();
  }

  submitClick = (closeDialog) => {
    const bodyData = {
      offerte_id: this.props.dialog.recordData.offerte_id,
      handledBy: this.state.handledBy,
      signDate: this.state.signDate
    };

    axios.post(API.OfferteDocUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const fileName = response.data;
          this.downloadFile(fileName, 'docx');

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: 'Offerte ' + fileName + ' staat klaar in Downloads.'
          });
        }
      })
      .catch(error => {
          console.error(error.response.statusText);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })

    closeDialog();
  }

  downloadFile = (fileName, fileType) => {
    // Request the download
    axios.get(API.OfferteFileUrl, {
      params: { filename: fileName, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        console.log(response);
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <div>
              <TextInput
                type='text'
                placeholder='Behandeld door'
                width='30em'
                value={this.state.handledBy}
                readOnly={false}
                onChange={(e) => this.setState({ handledBy: e.target.value })}
              />
              <DateEntry
                placeholder='dd-mm-jjjj'
                value={this.state.signDate}
                readOnly={false}
                onChange={(date) => this.setState({ signDate: moment(date).format('YYYY-MM-DD') })}
              />
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              <Button
                icon={IconNames.CONFIRM}
                intent={Intent.PRIMARY}
                onClick={() => this.submitClick(onClose)}>
                  Genereren
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog
});

export default connect(mapStateToProps)(OfferteDocDialog);
