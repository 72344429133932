/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/

export const regeltxtGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Regel ID en omschrijving", boxWidth: '100%'}
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Tekst", boxWidth: '100%'},
        ]
    }
]

export const regeltxtUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'regel_id',
                placeholder: 'Regel ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'omschrijving',
                placeholder: 'Omschrijving',
                icon: null,
                width: '35em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextEdit',
                field: 'regeltekst',
                placeholder: 'Vul hier de regeltekst in...',
                minLines: 5,
                maxLines: 20,
                action: 'textedit',
                restrict: null,
                readOnly: false
            }
        ]
    }
];
