import React from "react";
import './Logo.css';
import logo from './logo.png';

const Logo = () => (
  <img
    src={logo}
    alt=""
    className="logo"
    style={{
      width: "50%",
      height: "92%"
    }}
  />
);

const LogoImage = () => (
    <div style={{ position: 'relative' }}>
      {/* <img src={imageSrc} alt="" style={{width: '100vw', height: '100vh', objectFit: 'fill'}} /> */}
      <Logo />
    </div>
  );
  
  export default LogoImage;
  