import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Tabs, Tab, Toaster, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { liteFactuurGridBoxesMain, liteFactuurUiItemsMain } from './liteFactuurUI';
import { clear } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateLiteFactuur, updateInvoiced } from '../../services/lite.service';
import { saveAs } from 'file-saver';
import UiRenderer from '../../utils/DialogRender';
import LitePositionsTab from './LitePositionsTab';
import * as API from '../../mainapi';

class LiteFactuurDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
    this.userGroups = JSON.parse(sessionStorage.getItem("currentUserGroups"));

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog) {
    const liteRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, liteRecord);

    const liteId = liteRecord.lite_id;
    delete recordData.lite_id;
    updateLiteFactuur(liteId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar intent={amount < 100 ? Intent.WARNING : Intent.SUCCESS}
          className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 1000,
    };
  }

  downloadFile = (factuurNr, fileType) => {
    // Request the download
    axios.get(API.FactuurFileUrl, {
      params: { factuurnr: factuurNr, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerInvoice = (closeDialog) => {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });

    const bodyData = { lite_id: this.props.dialog.recordData.lite_id };

    axios.post(API.LiteInvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          this.downloadFile(data.factuurnr, 'docx');

          if (data.bestand_pdf !== null) {
            this.downloadFile(data.factuurnr, 'pdf');
          }

          if (data.bestand_ubl !== null) {
            this.downloadFile(data.factuurnr, 'ubl');
          }

          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: data.factuurnr + ' is gefactureerd. Bestanden staan klaar in Downloads.'
          });

          // Update the maintable data, record is removed
          updateInvoiced();

          // Close the dialog and clear dialog contents
          this.dispatch(clear());
          closeDialog();
          this.dispatch(expand());

        } else {
          console.log(response.data);
          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze termijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
        console.error(error);
      })
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '80%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Toaster ref={(instance) => { this.toaster = instance }} />

          <Tabs animate={true} id='TabsOrder' large={true} >
            <Tab id='main' title='Factuurgegevens'
              panel={<UiRenderer gridBoxes={liteFactuurGridBoxesMain} uiItems={liteFactuurUiItemsMain} />}
            />
            <Tab id='orderpos' title='Factuurposities'
              panel={<LitePositionsTab />}
            />
          </Tabs>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.ALIGN_LEFT}>
            <Button icon={IconNames.DOCUMENT_OPEN}
              onClick={ () => this.triggerInvoice(onClose) }
              style={{marginRight: '0.7em'}} >
                Factureren
            </Button>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={ () => this.cancelClick(onClose) }>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={ () => this.saveClick(onClose) }>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(LiteFactuurDialog);
