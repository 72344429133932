import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Card, FormGroup, Button } from '@blueprintjs/core';
import { Classes, Elevation, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { TextInput } from '../../components/TextInput';
import { CountrySelect } from '../../components/CountrySelect';
import { InkooporderStatusSelect } from '../../components/InkooporderStatusSelect';
import { DateEntry } from '../../components/DateEntry';
import MszTable from '../../components/MszTable';
import InkorderregelDialog from './InkorderregelDialog';
import { update } from '../../utils/contractSlice';
import { load, select, del } from '../../utils/inkorderregeltableSlice'
import moment from 'moment';
import DeleteDialog from '../../components/DeleteDialog';

class InkooporderTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDialog: false,
      selectedRegelId: null,
      showDialog: false,
      newRecord: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
  }

  componentDidMount() {
    if (this.props.fromMain !== undefined) {
      if (this.props.fromMain && this.props.maintable.selectedRowData !== null) {
        this.loadTableData(this.props.maintable.selectedRowData.order_contracts_id);
      } else if (!this.props.fromMain && this.props.contracttable.selectedRowData !== null) {
        this.loadTableData(this.props.contracttable.selectedRowData.order_contracts_id);
      } else {
        this.parseGrid('inkorderregel', []);
      }
    } else {
      if (this.props.contracttable.selectedRowData !== null) {
        this.loadTableData(this.props.contracttable.selectedRowData.order_contracts_id);
      } else {
        this.parseGrid('inkorderregel', []);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.contract.recordData !== this.props.contract.recordData) {
      if (this.props.contract.recordData === null) {
        this.loadTableData(nextProps.contract.recordData.order_contracts_id);
      }
      return true;
    } else if (nextProps.inkorderregeltable.columnData !== this.props.inkorderregeltable.columnData | 
               nextProps.inkorderregeltable.rowData !== this.props.inkorderregeltable.rowData) {
      return true;
    } else {
      if (nextState !== this.state) {
        return true;
      } else {
        return false;
      }
    }
  }

  loadTableData = (orderContractsId) => {
    axios.get(API.InkorderRegelUrl, {
      params: {
        order_contracts_id: orderContractsId
      }
    })
      .then(response => {
        const data = response.data;
        this.parseGrid('inkorderregel', data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  parseGrid = (tableName, tableData) => {
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;

        if (tableData.length > 0) {
          Object.keys(tableData[0]).forEach(key => {
            const fieldData = data.find((record) => {
              return record.fieldname === key
            });

            if (fieldData !== undefined && fieldData !== null) {
              var newData = {
                field: key,
                headerName: fieldData.description.toString(),
                hide: (fieldData.visible === 0)
              };
              columnData.push(newData);
            }
          })
        } else {
          data.forEach((record) => {
            var newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              hide: (record.visible === 0)
            };
            columnData.push(newData);
          })
        }

        this.resetSelectedRow()
        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ newRecord: false });
      })
      .catch(error => {
          console.error(error);
      })
  }

  rowClicked = (event) => {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
  }

  rowDoubleClicked = (event) => {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ showDialog: true, newRecord: false });
  }

  resetSelectedRow = () => {
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  closeDialog = () => {
    this.setState({ showDialog: false, newRecord: false, showDeleteDialog: false });
  }

  deleteClick = () => {
    if (this.props.inkorderregeltable.selectedRowIdx !== null) {
      this.setState({
        showDeleteDialog: true,
        selectedRegelId: this.props.inkorderregeltable.selectedRowData.inkorderregel_id,
      });
    }
  }

  getRow = (action) => {
    if (action === 'createRecord') {
      this.setState({ showDialog: true, newRecord: true });
    }

    if (this.props.inkorderregeltable.selectedRowIdx !== null) {
      switch (action) {
        case 'editRecord' : this.setState({ showDialog: true, newRecord: false });
          break
        case 'deleteRecord' : this.deleteClick();
          break
      }
    }
  }

  deleteRow = () => {
    this.dispatch(del({ rowIdx: this.props.inkorderregeltable.selectedRowIdx }));
  }

  renderStatusLevertijd = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '50%'}}
      >
        <p className={Classes.HEADING}>
          Orderstatus en levertijd
        </p>

        <div>
          <InkooporderStatusSelect
            value={this.props.contract.recordData.orderstatus}
            onSelect={(e) => this.dispatch(update({ 'orderstatus': e.key }))}
          />
          <div style={{display: 'inline-flex'}}>
            <div style={{marginRight: '1em'}}>
              <FormGroup helperText='Levertijd (dagen)' inline={false} style={{margin: '0.0em'}}/>
              <TextInput
                type='number'
                placeholder='Levertijd'
                width='5em'
                value={this.props.contract.recordData.levertijd}
                readOnly={false}
                onChange={(e) => this.dispatch(update({ 'levertijd': e.target.value }))}
                small={true}
              />
            </div>

            <div>
              <FormGroup helperText='Gewenste leverdatum' inline={false} style={{margin: '0.0em'}}/>
              <DateEntry
                placeholder='dd-mm-jjjj'
                value={this.props.contract.recordData.leverdatum}
                readOnly={false}
                onChange={(date) => this.dispatch(update({ 'leverdatum': moment(date).format('YYYY-MM-DD') }))}
              />
            </div>
          </div>
          <FormGroup helperText='Inkoper' inline={false} style={{margin: '0.0em'}}/>
          <TextInput
            type='text'
            placeholder='Inkoper'
            width='20em'
            value={this.props.contract.recordData.inkoper}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'inkoper': e.target.value }))}
            small={true}
          />
        </div>
      </Card>
    )
  }

  renderAfleverAdres = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Naam en adres
        </p>

        <div>
          <TextInput
            type='text'
            placeholder='Naam'
            width='35em'
            value={this.props.contract.recordData.aflnaam}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'aflnaam': e.target.value }))}
            small={true}
          />
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='text'
              placeholder='Adres'
              width='30em'
              value={this.props.contract.recordData.afladres}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'afladres': e.target.value }))}
              small={true}
            />
            <TextInput
              type='number'
              placeholder='Huisnr'
              width='7em'
              value={this.props.contract.recordData.aflhuisnr}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'aflhuisnr': e.target.value }))}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Toevoeging'
              width='10em'
              value={this.props.contract.recordData.aflhnrtoev}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'aflhnrtoev': e.target.value }))}
              small={true}
            />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='text'
              placeholder='Postcode'
              width='7em'
              value={this.props.contract.recordData.aflpostcode}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'aflpostcode': e.target.value }))}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Plaats'
              width='40em'
              value={this.props.contract.recordData.aflplaats}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'aflplaats': e.target.value }))}
              small={true}
            />
          </div>
          <CountrySelect
            value={this.props.contract.recordData.aflland}
            onSelect={(e) => this.dispatch(update({ 'aflland': e.text }))}
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.contract.recordData !== null) {
      return (
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderStatusLevertijd()}
            {this.renderAfleverAdres()}
          </div>
        </div>
      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  renderRegelDialog = () => {
    if (this.props.inkorderregeltable.selectedRowIdx !== null | this.state.newRecord) {
      return (
        <InkorderregelDialog
          showDialog={this.state.showDialog}
          rowData={this.props.inkorderregeltable.selectedRowData}
          newRecord={this.state.newRecord}
          orderContractsId={this.props.contract.recordData.order_contracts_id}
          onClose={this.closeDialog}
        />
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderDialog()}
        <div style={{display: 'inline-flex'}}>
          <Button icon={IconNames.ADD} text="Toevoegen" onClick={() => this.getRow('createRecord')} />
          <Button icon={IconNames.EDIT} text="Wijzigen" onClick={() => this.getRow('editRecord')} />
          <Button icon={IconNames.DELETE} text="Verwijderen" onClick={() => this.getRow('deleteRecord')} />
        </div>
        <MszTable 
          tableColumns={this.props.inkorderregeltable.columnData}
          tableRows={this.props.inkorderregeltable.rowData}
          rowClicked={this.rowClicked}
          rowDoubleClicked={this.rowDoubleClicked}
          height='20em'
        />

        {this.renderRegelDialog()}

        <DeleteDialog
          showDialog={this.state.showDeleteDialog}
          title='Inkooporder regel verwijderen'
          message={'Inkooporder regel ' + this.state.selectedRegelId + ' verwijderen?'}
          url={API.InkorderRegelUrl}
          apiParams={{'inkorderregel_id': this.state.selectedRegelId}}
          onDelete={this.deleteRow}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  inkorderregeltable: state.inkorderregeltable,
  contracttable: state.contracttable,
  maintable: state.maintable,
  contract: state.contract
});

export default connect(mapStateToProps)(InkooporderTab);
