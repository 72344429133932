import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterLabel = (query, aanhef) => {
  return aanhef.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderLabel = (aanhef, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={aanhef.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={aanhef.text}
      />
  );
};

export class ProjectlabelSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: props.prjLabels[0].text,
    }
  };

  render() {
    return (
        <Select2
          itemPredicate={filterLabel}
          itemRenderer={renderLabel}
          ItemListRenderer={renderMenu}
          items={this.props.prjLabels}
          onItemSelect={this.props.onSelect}
          style={{marginBottom: '0.4em'}}
        >
          <Button
            text={this.props.value}
            icon={IconNames.TAG}
            rightIcon='double-caret-vertical'
            small={true} 
          />
        </Select2>
    );
  };
};

