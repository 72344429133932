import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const titleList = [
  {key: 1, text: "Opdracht"},
  {key: 2, text: "Start werkzaamheden"},
  {key: 3, text: "Deelproject gereed"},
  {key: 4, text: "Project gereed"},
  {key: 5, text: "Oplevering"},
  {key: 6, text: "Meerwerk"},
  {key: 7, text: "Regie"}
];

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterProjectFase = (query, projectfase) => {
  return projectfase.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderProjectFase = (projectfase, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={projectfase.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={projectfase.text}
      />
  );
};

export class ProjectFaseSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: titleList[0].text,
    }
  };

  render() {
    return (
        <Select2
          itemPredicate={filterProjectFase}
          itemRenderer={renderProjectFase}
          ItemListRenderer={renderMenu}
          items={titleList}
          onItemSelect={this.props.onSelect}
        >
          <Button
            text={this.props.value}
            icon={IconNames.LABEL}
            rightIcon='double-caret-vertical'
            small={true}
            disabled={this.props.readOnly}
            style={{marginBottom: '0.1em', width: this.props.width}} 
          />
        </Select2>
    );
  };
};

