import './NavBar.css';
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import { Intent, Navbar, NavbarGroup } from '@blueprintjs/core';
import { Classes, Alignment, Button, Menu, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import { clear } from '../utils/tableSlice';
import SetPasswordDialog from './SetPasswordDialog';
import UserAdminDialog from './UserAdminDialog';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNavBar: true,
      logOut: false,
      showPasswordDialog: false,
      showUserAdminDialog: false,
    }
  };

  signOut = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("currentUser");
    this.dispatch(clear())
    this.setState({ logOut: true });
  }

  render() {
    const userFirstname = sessionStorage.getItem('currentUserFirstname');
    const userIsAdmin = JSON.parse(sessionStorage.getItem("currentUserIsAdmin"));

    const { navBarCustomItems } = this.props;
    const listCustomItems = navBarCustomItems.map(item => {
      return (
        <Button
          className={Classes.MINIMAL}
          key={item.key}
          icon={item.icon}
          text={item.label}
          onClick={item.action}
        />
      );
    })

    return (
      <Navbar className={Classes.DARK}
        style={{backgroundColor: '#061915', background: 'linear-gradient(to left, teal, 25%, #061915)', border: 'none'}} >
        <NavbarGroup align={Alignment.LEFT} >
          {listCustomItems}
        </NavbarGroup>

        <NavbarGroup align={Alignment.RIGHT} >
          <div className={Classes.DARK} >
            <Popover2 content={
                <Menu>
                  {userIsAdmin && <MenuItem icon={IconNames.SETTINGS} text="Gebruikersbeheer"
                    onClick={() => this.setState({ showUserAdminDialog: true })} /> }
                  <MenuItem icon={IconNames.KEY} text="Wachtwoord wijzigen" intent={Intent.WARNING}
                    onClick={() => this.setState({ showPasswordDialog: true })} />
                  <MenuItem icon={IconNames.LOG_OUT} text="Uitloggen" intent={Intent.SUCCESS}
                    onClick={() => this.setState({ logOut: true })} />
                </Menu>
              } placement='bottom-start' inheritDarkTheme={true}>
              <Button
                minimal={true}
                large={true}
                fill={true}
                intent={Intent.WARNING}
                icon={IconNames.USER}>
                  <b>{userFirstname}</b>
              </Button>
            </Popover2>
          </div>
        </NavbarGroup>

        {this.state.logOut && <Navigate to={'/'} />}

        <SetPasswordDialog
          showDialog={this.state.showPasswordDialog}
          title='Wijzig je wachtwoord'
          onClose={() => this.setState({ showPasswordDialog: false })}
        />
        {userIsAdmin && <UserAdminDialog
          showDialog={this.state.showUserAdminDialog}
          title='Gebruikersbeheer'
          onClose={() => this.setState({ showUserAdminDialog: false })}
        />}
      </Navbar>
    );
  };
};

export default NavBar
