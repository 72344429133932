import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as API from '../../mainapi';
import MszTable from '../../components/MszTable';
import { load } from '../../utils/factuurpostableSlice';

class FactuurPositionsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      newRecord: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
  }

  componentDidMount() {
    if (this.props.maintable.selectedRowData !== null) {
      this.loadTableData(this.props.maintable.selectedRowData.factuurnr);
    } else {
      this.parseGrid('factuurpos', []);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dialog.recordData !== this.props.dialog.recordData) {
      if (this.props.dialog.recordData === null) {
        this.loadTableData(nextProps.dialog.recordData.factuurnr);
      }
      return true;
    } else if (nextProps.factuurpostable.columnData !== this.props.factuurpostable.columnData | nextProps.factuurpostable.rowData !== this.props.factuurpostable.rowData) {
      return true;
    } else {
      if (nextState !== this.state) {
        return true;
      } else {
        return false;
      }
    }
  }

  loadTableData(factuurId) {
    axios.get(API.FactPosUrl, {
      params: {
        factuurnr: factuurId
      }
    })
      .then(response => {
        const data = response.data;
        this.parseGrid('factuurpos', data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  parseGrid(tableName, tableData) {
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;

        data.forEach((record) => {
          var newData = {
            field: record.fieldname,
            headerName: record.description.toString(),
            hide: (record.visible === 0)
          };
          columnData.push(newData);
        })

        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ newRecord: false });
      })
      .catch(error => {
          console.error(error);
      })
  }

  render() {
    return (
      <div>
        <MszTable 
          tableColumns={this.props.factuurpostable.columnData}
          tableRows={this.props.factuurpostable.rowData}
          height='30em'
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  factuurpostable: state.factuurpostable,
  maintable: state.maintable
});

export default connect(mapStateToProps)(FactuurPositionsTab);
