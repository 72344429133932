import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card, Switch, Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { load, update, reset } from '../../utils/paymentSlice';
import { add, update as tableUpdate } from '../../utils/paytableSlice';
import { TextInput } from '../../components/TextInput';
import { ProjectFaseSelect } from '../../components/ProjectFaseSelect';

class PaymentDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
      canCredit: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.createRowData = this.createRowData.bind(this);
    this.loadRowData = this.loadRowData.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidMount() {
    if (this.props.newRecord) {
      this.createRowData();
    } else {
      // if (this.props.postable.selectedRowData !== null) {
      const payId = this.props.paytable.selectedRowData.order_payments_id;
      this.loadRowData(payId);
      // }
    }
  }

  componentDidUpdate() {
    if (this.props.newRecord && !this.props.payment.createRecord) {
      this.createRowData();
    } else {
      if (!this.props.newRecord && this.props.payment.recordData !== null) {
        // Selected row from table prop; compare to loaded record data in store
        const selectedId = this.props.paytable.selectedRowData.order_payments_id;
        const loadedId = this.props.payment.recordData.order_payments_id;

        if (selectedId !== loadedId && this.props.showDialog) {
          this.loadRowData(selectedId);
        }
      }
    }
  }

  createRowData() {
    const bodyData = {"order_id": this.props.orderId};

    axios.post(API.OrderPayUrl, bodyData)
      .then(response => {
        const payload = { recordData: response.data, createRecord: true };
        this.dispatch(load(payload));
        this.dispatch(add({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadRowData(payId) {
    axios.get(API.OrderPayUrl, {
      params: {
        order_payments_id: payId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
      })
      .catch(error => {
          console.error(error);
      })
  }

  updateRowData(payId, data) {
    axios.put(API.OrderPayUrl, data, {
      params: {
        order_payments_id: payId
      }
    })
      .then(response => {
        this.dispatch(tableUpdate({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  sumPayments = () => {
    var sumPercentage = 0;

    if (this.props.paytable.rowData.length > 0) {
      const rowsOpdracht = this.props.paytable.rowData.filter(row => {
        return row.order_payments_id !== this.props.payment.recordData.order_payments_id && row.fk_fasecode < 6;
      });
      sumPercentage = rowsOpdracht.reduce((partialSum, row) => partialSum + row.percentage, 0);
    }

    return sumPercentage;
  }

  handlePercentageChange = (e) => {
    const sumPaymentPerc = this.sumPayments();
    const ceilPerc = 100 - sumPaymentPerc;

    if (e.target.value > ceilPerc) {
      this.dispatch(update({ 'percentage': ceilPerc }))
    } else {
      this.dispatch(update({ 'percentage': e.target.value }))
    }
  }

  handleFaseSelect = (e) => {
    const faseCode = [
      {key: 1, text: "Opdracht"},
      {key: 2, text: "Start werkzaamheden"},
      {key: 3, text: "Deelproject gereed"},
      {key: 4, text: "Project gereed"},
      {key: 5, text: "Oplevering"},
      {key: 6, text: "Meerwerk"},
      {key: 7, text: "Regie"}
    ];
    
    const selectedFase = faseCode.find(fase => {
      return e.text === fase.text
    });

    this.dispatch(update({ 'projectfase': e.text }));
    this.dispatch(update({ 'fk_fasecode': selectedFase.key }));

    if (selectedFase.key === 7) {
      this.dispatch(update({ 'percentage': 100 }))
    } else {
      this.dispatch(update({ 'percentage': 0 }))
    }
  }

  saveClick(closeDialog) {
    const payRecord = this.props.payment.recordData;
    const recordData = Object.assign({}, payRecord);

    const payId = payRecord.order_payments_id
    delete recordData.order_payments_id
    this.updateRowData(payId, recordData)

    this.dispatch(reset());
    closeDialog();
  }

  cancelClick(closeDialog) {
    this.dispatch(reset());
    closeDialog();
  }

  renderGeneral = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Gegevens
        </p>

        <div>
          <TextInput
            type='text'
            width='10em'
            value={this.props.payment.recordData.order_payments_id}
            readOnly={true}
            small={true}
          />
          <TextInput
            type='text'
            placeholder='Omschrijving'
            width='30em'
            value={this.props.payment.recordData.faseomschrijving}
            readOnly={this.props.payment.recordData.gefactureerd}
            onChange={(e) => this.dispatch(update({ 'faseomschrijving': e.target.value }))}
            small={true}
          />
          <ProjectFaseSelect
            value={this.props.payment.recordData.projectfase}
            onSelect={this.handleFaseSelect}
            readOnly={this.props.payment.recordData.gefactureerd}
            />
          <TextInput
            type='text'
            placeholder='Betalingskenmerk'
            icon={IconNames.TAG}
            width='20em'
            value={this.props.payment.recordData.kenmerk}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'kenmerk': e.target.value }))}
            small={true}
          />

          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='number'
              icon={IconNames.PERCENTAGE}
              width='10em'
              value={this.props.payment.recordData.percentage}
              readOnly={this.props.payment.recordData.gefactureerd | this.props.payment.recordData.fk_fasecode === 7}
              onChange={this.handlePercentageChange}
              small={true}
            />
            {/* <TextInput
              type='number'
              icon={IconNames.EURO}
              width='20em'
              value={this.props.dialog.recordData.aanneemsom}
              readOnly={true}
              small={true}
            /> */}
          </div>

          <Switch
            label='Creditposities opnemen'
            checked={this.props.payment.recordData.inclcredit}
            onChange={(e) => this.dispatch(update({ 'inclcredit': e.target.checked }))}
            disabled={this.props.payment.recordData.gefactureerd}
          />
        </div>
      </Card>
    )
  }

  renderFinStatus = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '40%'}}
      >
        <p className={Classes.HEADING}>
          Financiele status
        </p>

        <div>
          <Checkbox
            label='Gefactureerd'
            checked={this.props.payment.recordData.gefactureerd}
            readOnly={true}
          />
          <Checkbox
            label='Voldaan'
            checked={this.props.payment.recordData.voldaan}
            readOnly={true}
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.payment.recordData !== null) {
      return (
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderGeneral()}
            {this.renderFinStatus()}
          </div>
        </div>      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Betalingstermijn'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '50%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          {this.renderDialog()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  payment: state.payment,
  paytable: state.paytable,
  dialog: state.dialog
});

export default connect(mapStateToProps)(PaymentDialog);
