import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update } from "../utils/tableSlice";

export function getUren(id) {
  axios.get(API.UrenUrl, {
    params: {
      boeking_id: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createUren() {
  axios.post(API.UrenUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateUren(id, data) {
  axios.put(API.UrenUrl, data, {
    params: {
      boeking_id: id
    }
  })
    .then(response => {
      store.dispatch(update({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}
