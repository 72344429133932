import React, {Component} from 'react';
import axios from 'axios';
import * as API from '../../mainapi';
import { connect } from 'react-redux';
import { FormGroup, Card } from '@blueprintjs/core';
import { Elevation, Colors, Classes } from '@blueprintjs/core';
import { TextInput } from '../../components/TextInput';
import MszTable from '../../components/MszTable';
import { load, select } from '../../utils/urentableSlice';
import { update } from '../../utils/dialogSlice';

class CalculationTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      newRecord: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);
  }

  componentDidMount() {
    if (this.props.maintable.selectedRowData !== null) {
      this.loadTableData(this.props.maintable.selectedRowData.order_id);
    } else {
      this.parseGrid('uren', []);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.dialog.recordData !== this.props.dialog.recordData) {
  //     if (this.props.dialog.recordData === null | nextProps.dialog.createRecord !== this.props.dialog.createRecord) {
  //       this.loadTableData(nextProps.dialog.recordData.order_id);
  //     }
  //     return true;
  //   } else if (nextProps.urentable.columnData !== this.props.urentable.columnData | nextProps.urentable.rowData !== this.props.urentable.rowData) {
  //     return true;
  //   } else if (nextProps.contracttable.columnData !== this.props.contracttable.columnData | nextProps.contracttable.rowData !== this.props.contracttable.rowData) {
  //     return true;
  //   } else if (nextProps.metrics !== this.props.metrics) {
  //     this.updateCalcBedrag(nextProps.metrics);
  //     return true; 
  //   } else {
  //     if (nextState !== this.state) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  loadTableData(orderId) {
    axios.get(API.UrenUrl, {
      params: {
        order_id: orderId
      }
    })
      .then(response => {
        const data = response.data;
        this.parseGrid('uren', data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  parseGrid(tableName, tableData) {
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;

        data.forEach((record) => {
          var newData = {
            field: record.fieldname,
            headerName: record.description.toString(),
            hide: (record.visible === 0)
          };
          columnData.push(newData);
        })

        this.resetSelectedRow();
        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ newRecord: false });
      })
      .catch(error => {
          console.error(error);
      })
  }

  rowClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
  }

  resetSelectedRow() {
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  renderCalculations = () => {
    const directSum = this.props.urentable.rowData.reduce((accumulator, current) => {
      if (current.uursoort === 0) {return accumulator + current.uren} else {return accumulator + 0}
    }, 0);
    const indirectSum = this.props.urentable.rowData.reduce((accumulator, current) => {
      if (current.uursoort === 1) {return accumulator + current.uren} else {return accumulator + 0}
    }, 0);

    const bedragSum = this.props.urentable.rowData.reduce((accumulator, current) => accumulator + current.bedrag, 0)
    const werkDerdenSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.werkbedrag, 0)
    const materialenSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.matbedrag, 0)
    const inkoopSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.inkoopbedrag, 0)

    const restTotal = this.props.dialog.recordData.calcbedrag - bedragSum - inkoopSum;
    const restDirect = this.props.dialog.recordData.calcuren - directSum;
    const restIndirect = this.props.dialog.recordData.calcindirect - indirectSum;
    const restWerkDerden = this.props.dialog.recordData.calcinkoopwerk - werkDerdenSum;
    const restMaterialen = this.props.dialog.recordData.calcinkoopmat - materialenSum;

    const renderCalcFields = () => {
      return (
        <div style={{display: 'inline-flex'}}>
          <div>
            <FormGroup helperText='Bedragen' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.props.dialog.recordData.calcbedrag}
              readOnly={true}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Uren direct' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Uren'
              width='10em'
              value={this.props.dialog.recordData.calcuren}
              readOnly={true}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Uren indirect' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Uren'
              width='10em'
              value={this.props.dialog.recordData.calcindirect}
              readOnly={true}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Werk derden' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.props.dialog.recordData.calcinkoopwerk}
              readOnly={true}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Materialen' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.props.dialog.recordData.calcinkoopmat}
              readOnly={true}
              small={true}
            />
          </div>
        </div>
      )
    }

    const renderSumFields = () => {
      return (
        <div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={bedragSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={directSum.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={indirectSum.toFixed(0)} readOnly={true} small={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput width='10em' small={true} disabled={true} />
            </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={inkoopSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput type='number' width='10em' value={werkDerdenSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={materialenSum.toFixed(2)} readOnly={true} small={true} />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={restTotal.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restDirect.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restIndirect.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restWerkDerden.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restMaterialen.toFixed(2)} readOnly={true} small={true} />
          </div>
        </div>
      )
    }

    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '55%'}}
      >
        <p className={Classes.HEADING}>
          Calculatie
        </p>

        <div>
          {renderCalcFields()}
          {renderSumFields()}
        </div>
      </Card>
    )
  }

  updateCalcBedrag = (newMetrics) => {
    const akBedrag = newMetrics.data['orderbedrag'] * this.props.dialog.recordData.res_ak / 100;
    const wrBedrag = akBedrag * this.props.dialog.recordData.res_wr / 100;
    const carBedrag = wrBedrag * this.props.dialog.recordData.res_car / 100;
    const calcBedrag = newMetrics.data['orderbedrag'] - akBedrag - wrBedrag - carBedrag;

    this.dispatch(update({ 'calcbedrag': calcBedrag.toFixed(2) }));
  }

  getMetrics = (key) => {
    if (this.props.metrics.data !== null) {
      return this.props.metrics.data[key];
    } else {
      return 0;
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'inline-flex'}}>
          {this.renderCalculations()}
        </div>
        <MszTable 
          tableColumns={this.props.urentable.columnData}
          tableRows={this.props.urentable.rowData}
          rowClicked={this.rowClicked}
          height='20em'
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  urentable: state.urentable,
  contracttable: state.contracttable,
  maintable: state.maintable,
  metrics: state.metrics
});

export default connect(mapStateToProps)(CalculationTab);
