import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Card, RadioGroup, Radio, FormGroup, Checkbox, Button } from '@blueprintjs/core';
import { Classes, Elevation, Colors, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TextInput } from '../../components/TextInput';
import { CountrySelect } from '../../components/CountrySelect';
import { BtwSelect } from '../../components/BtwSelect';
import { AanhefSelect } from '../../components/AanhefSelect';
import { update, load } from '../../utils/contractSlice';
import SearchDialog from '../../components/SearchDialog';
import * as API from '../../mainapi';

class ContractorTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchDialog: false,
      tableName: null,
      updateFields: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
  }

  handlePercBedragChange = (e) => {
    this.dispatch(update({ 'termijnisperc': e.target.value === '0' }));
    this.dispatch(update({ 'termijnisbedrag': e.target.value === '1' }));
  }

  parsePercBedrag = () => {
    var typeValue = '';

    if (this.props.contract.recordData.termijnisperc) {
      typeValue = '0';
    } else if (this.props.contract.recordData.termijnisbedrag) {
      typeValue = '1';
    }

    return typeValue;
  }

  sumOrderRegels = () => {
    var sumBedrag = 0;

    if (this.props.inkorderregeltable.rowData.length > 0) {
      sumBedrag = this.props.inkorderregeltable.rowData.reduce((partialSum, row) => partialSum + row.bedragexcl, 0);
    }

    return sumBedrag.toString();
  }

  sumPercentages = () => {
    const percIds = ['perc1', 'perc2', 'perc3', 'perc4', 'perc5'];
    var sumPerc = 0;

    if (this.props.contract.recordData.termijnisperc) {
      percIds.forEach((id) => {
        var percId = 'perc' + id.charAt(id.length - 1);
        sumPerc += this.props.contract.recordData[percId];
      })
    }
    console.log('Sum perc :' + sumPerc);
    return sumPerc;
  }

  sumBedragen = () => {
    const bedragIds = ['bedrag1', 'bedrag2', 'bedrag3', 'bedrag4', 'bedrag5']
    var sumBedrag = 0;

    if (this.props.contract.recordData.termijnisperc) {
      bedragIds.forEach((id) => {
        var bedragId = 'bedrag' + id.charAt(id.length - 1);
        sumBedrag += this.props.contract.recordData[bedragId];
      })
    }

    return sumBedrag;
  }

  renderInkooporderGegevens = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '50%'}}
      >
        <p className={Classes.HEADING}>
          Inkoopordernr en bedrag
        </p>

        <div>
          <TextInput
            type='text'
            width='10em'
            value={this.props.contract.recordData.order_contracts_id}
            readOnly={true}
            small={true}
          />
          <div style={{display: 'inline-flex'}}>
            <div style={{marginRight: '1em'}}>
              <FormGroup helperText='Inkoopbedrag excl. BTW' inline={false} style={{margin: '0.0em'}}/>
              <TextInput
                type='number'
                width='15em'
                value={this.props.contract.recordData.inkoopbedrag}
                readOnly={false}
                onChange={this.handleInkoopBedragChange}
                small={true}
              />
            </div>
            <div style={{marginRight: '1em'}}>
              <FormGroup helperText='Som van orderregels' inline={false} style={{margin: '0.0em'}}/>
              <TextInput
                type='number'
                icon={this.props.contract.recordData.inkoopbedrag.toString() !== this.sumOrderRegels() ? 
                  IconNames.WARNING_SIGN : IconNames.THUMBS_UP}
                width='15em'
                value={this.sumOrderRegels()}
                readOnly={true}
                small={true}
              />
            </div>
          </div>
          <div style={{display: 'inline-flex'}}>
            <div style={{marginRight: '1em'}}>
              <FormGroup helperText='Bedrag Werk derden' inline={false} style={{margin: '0.0em'}}/>
              <TextInput
                type='number'
                width='15em'
                value={this.props.contract.recordData.werkbedrag}
                readOnly={false}
                onChange={this.handleWerkDerdenChange}
                small={true}
              />
            </div>
            <div style={{marginRight: '1em'}}>
              <FormGroup helperText='Bedrag Materialen' inline={false} style={{margin: '0.0em'}}/>
              <TextInput
                type='number'
                width='15em'
                value={this.props.contract.recordData.matbedrag}
                readOnly={false}
                onChange={this.handleMaterialenChange}
                small={true}
              />
            </div>
          </div>

          <FormGroup helperText='BTW' inline={false} style={{margin: '0.0em'}}/>
          <BtwSelect
            value={this.props.contract.recordData.btw}
            onSelect={(e) => this.dispatch(update({ 'btw': e.key }))}
          />
          <TextInput
            type='text'
            placeholder='kenmerk'
            width='20em'
            value={this.props.contract.recordData.kenmerk}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'kenmerk': e.target.value }))}
            small={true}
          />
        </div>
      </Card>
    )
  }

  handleInkoopBedragChange = (e) => {
    const bedragIds = ['bedrag1', 'bedrag2', 'bedrag3', 'bedrag4', 'bedrag5']

    this.dispatch(update({ 'inkoopbedrag': e.target.value }))

    if (this.props.contract.recordData.termijnisperc) {
      bedragIds.forEach((id) => {
        var percId = 'perc' + id.charAt(id.length - 1);
        this.dispatch(update({ [id]: this.props.contract.recordData[percId] * e.target.value / 100 }));
      })
    }
  }

  handleWerkDerdenChange = (e) => {
    const inkoopBedrag = this.props.contract.recordData.inkoopbedrag;
    const matBedrag = this.props.contract.recordData.matbedrag;
    const ceilBedrag = inkoopBedrag - matBedrag;

    if (e.target.value > ceilBedrag) {
      this.dispatch(update({ 'werkbedrag': ceilBedrag }))
    } else {
      this.dispatch(update({ 'werkbedrag': e.target.value }))
    }
  }

  handleMaterialenChange = (e) => {
    const inkoopBedrag = this.props.contract.recordData.inkoopbedrag;
    const werkBedrag = this.props.contract.recordData.werkbedrag;
    const ceilBedrag = inkoopBedrag - werkBedrag;

    if (e.target.value > ceilBedrag) {
      this.dispatch(update({ 'matbedrag': ceilBedrag }))
    } else {
      this.dispatch(update({ 'matbedrag': e.target.value }))
    }
  }

  handlePercChange = (e) => {
    const idNr = e.target.id.charAt(e.target.id.length - 1)
    const percIds = ['perc1', 'perc2', 'perc3', 'perc4', 'perc5'];
    var bedragId = 'bedrag' + idNr
    var sumPerc = 0;

    // Sum the other percentages and calculate rest value for this input
    percIds.splice(idNr - 1, 1);
    percIds.forEach((id) => {
      sumPerc += parseInt(this.props.contract.recordData[id]);
    })

    const restValue = 100 - sumPerc;

    if (e.target.value > restValue) {
      this.dispatch(update({ [e.target.id]: restValue }));
      this.dispatch(update({ [bedragId]: restValue * this.props.contract.recordData.inkoopbedrag / 100 }));
    } else {
      this.dispatch(update({ [e.target.id]: e.target.value }));
      this.dispatch(update({ [bedragId]: e.target.value * this.props.contract.recordData.inkoopbedrag / 100 }));
    }
  }

  handleBedragChange = (e) => {
    const idNr = e.target.id.charAt(e.target.id.length - 1)
    const bedragIds = ['bedrag1', 'bedrag2', 'bedrag3', 'bedrag4', 'bedrag5']
    var sumBedrag = 0;

    // Sum the other percentages and calculate rest value for this input
    bedragIds.splice(idNr - 1, 1);
    bedragIds.forEach((id) => {
      sumBedrag += parseInt(this.props.contract.recordData[id]);
    })

    const restValue = this.props.contract.recordData.inkoopbedrag - sumBedrag;

    if (e.target.value > restValue) {
      this.dispatch(update({ [e.target.id]: restValue }));
    } else {
      this.dispatch(update({ [e.target.id]: e.target.value }));
    }
  }

  bookTermijnPaid = (termijnId) => {
    const bodyData = {
      order_contracts_id: this.props.contract.recordData.order_contracts_id,
      termijn: termijnId.toString()
    };

    axios.post(API.InkooporderTermijnPaidUrl, bodyData)
      .then(response => {
        console.log(response);
        this.dispatch(load({ recordData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  renderTermijnen = () => {
    const termijnIds = [
      {key: 1, termijnField: 'termijn1', percField: 'perc1', bedragField: 'bedrag1', voldaanField: 'voldaan1'},
      {key: 2, termijnField: 'termijn2', percField: 'perc2', bedragField: 'bedrag2', voldaanField: 'voldaan2'},
      {key: 3, termijnField: 'termijn3', percField: 'perc3', bedragField: 'bedrag3', voldaanField: 'voldaan3'},
      {key: 4, termijnField: 'termijn4', percField: 'perc4', bedragField: 'bedrag4', voldaanField: 'voldaan4'},
      {key: 5, termijnField: 'termijn5', percField: 'perc5', bedragField: 'bedrag5', voldaanField: 'voldaan5'}
    ]

    const mappedTermijnen = termijnIds.map(id => {
      return (
        <div key={id.key} style={{display: 'inline-flex'}}>
          <div style={{marginRight: '1em'}}>
            {id.key === 1 && <FormGroup helperText='Aan/uit' inline={false} style={{margin: '0.0em'}}/>}
            <Checkbox
              label={id.termijnField.charAt(id.termijnField.length - 1)}
              checked={this.props.contract.recordData[id.termijnField]}
              onChange={(e) => this.dispatch(update({ [id.termijnField]: e.target.checked }))}
              disabled={id.key === 1}
              style={{marginRight: '3em'}}
            />
          </div>
          <div style={{marginRight: '1em'}}>
            {id.key === 1 && <FormGroup helperText='Percentage' inline={false} style={{margin: '0.0em'}}/>}
            <TextInput
              id={id.percField}
              type='number'
              width='10em'
              value={this.props.contract.recordData[id.percField]}
              readOnly={this.props.contract.recordData.termijnisbedrag}
              onChange={this.handlePercChange}
              min={0}
              small={true}
            />
          </div>
          <div style={{marginRight: '1em'}}>
            {id.key === 1 && <FormGroup helperText='Bedrag' inline={false} style={{margin: '0.0em'}}/>}
            <TextInput
              id={id.bedragField}
              type='number'
              width='10em'
              value={this.props.contract.recordData[id.bedragField]}
              readOnly={this.props.contract.recordData.termijnisperc}
              onChange={this.handleBedragChange}
              min={0}
              small={true}
            />
          </div>
          <div>
            {id.key === 1 && <FormGroup helperText='Boeken' inline={false} style={{margin: '0.0em'}}/>}
            <Button
              id={'Book' + id.key}
              icon={IconNames.Dollar}
              intent={this.props.contract.recordData[id.voldaanField] ? Intent.SUCCESS : Intent.WARNING}
              onClick={() => this.bookTermijnPaid(id.key)}
              disabled={!this.props.contract.recordData[id.termijnField] | this.props.contract.recordData[id.voldaanField]}
              small={true}
            >
              {this.props.contract.recordData[id.voldaanField] ? 'Geboekt' : 'Boeken'}
            </Button>
          </div>
        </div>
      )
    })

    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '50%'}}
      >
        <p className={Classes.HEADING}>
          Termijnen
        </p>

        <div>
          <RadioGroup inline={true} onChange={this.handlePercBedragChange} selectedValue={this.parsePercBedrag()}>
            <Radio label='Percentages' value='0' />
            <Radio label='Bedragen' value='1' />
          </RadioGroup>
          {mappedTermijnen}
        </div>
      </Card>
    )
  }

  searchClick = () => {
    const fields = {
      'contract_id': 'contact_id',
      'soort_relatie': 'soort_relatie',
      'naam': 'naam',
      'adres': 'adres',
      'huisnr': 'huisnr',
      'hnrtoev': 'hnrtoev',
      'postcode': 'postcode',
      'plaats': 'plaats',
      'land': 'land',
      'contactp': 'contactp',
      'aanhef': 'aanhef',
      'telefoon': 'telefoon',
      'telefoon_2': 'telefoon_2',
      'email': 'email'
    }
    this.setState({ showSearchDialog: true, tableName: 'crm', updateFields: fields })
  }

  renderContractor = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Naam en adres
        </p>

        <div>
          <div style={{display: 'inline-flex'}}>
            <Button
              icon={IconNames.SEARCH}
              intent={Intent.PRIMARY}
              onClick={() => this.searchClick()}
              small={true}
            />
            <TextInput
              type='text'
              width='10em'
              value={this.props.contract.recordData.contract_id}
              readOnly={true}
              small={true}
            />
            <TextInput
              type='text'
              width='15em'
              value={this.props.contract.recordData.soort_relatie}
              readOnly={true}
              small={true}
            />
          </div>
          <TextInput
            type='text'
            placeholder='Naam'
            width='35em'
            value={this.props.contract.recordData.naam}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'naam': e.target.value }))}
            small={true}
          />
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='text'
              placeholder='Adres'
              width='30em'
              value={this.props.contract.recordData.adres}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'adres': e.target.value }))}
              small={true}
            />
            <TextInput
              type='number'
              placeholder='Huisnr'
              width='7em'
              value={this.props.contract.recordData.huisnr}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'huisnr': e.target.value }))}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Toevoeging'
              width='10em'
              value={this.props.contract.recordData.hnrtoev}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'hnrtoev': e.target.value }))}
              small={true}
            />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='text'
              placeholder='Postcode'
              width='7em'
              value={this.props.contract.recordData.postcode}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'postcode': e.target.value }))}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Plaats'
              width='40em'
              value={this.props.contract.recordData.plaats}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'plaats': e.target.value }))}
              small={true}
            />
          </div>
          <CountrySelect
            value={this.props.contract.recordData.land}
            onSelect={(e) => this.dispatch(update({ 'land': e.text }))}
          />
        </div>
      </Card>
    )
  }

  renderContact = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '40%'}}
      >
        <p className={Classes.HEADING}>
          Contactgegevens
        </p>

        <div>
          <div style={{display: 'inline-flex'}}>
            <AanhefSelect
              value={this.props.contract.recordData.aanhef}
              onSelect={(e) => this.dispatch(update({ 'aanhef': e.text }))}
            />
            <TextInput
              type='text'
              placeholder='Contactpersoon'
              width='20em'
              value={this.props.contract.recordData.contactp}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'contactp': e.target.value }))}
              small={true}
            />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='tel'
              icon={IconNames.PHONE}
              placeholder='Telefoon'
              width='10em'
              value={this.props.contract.recordData.telefoon}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'telefoon': e.target.value }))}
              small={true}
            />
            <TextInput
              type='tel'
              icon={IconNames.PHONE}
              placeholder='Telefoon 2'
              width='10em'
              value={this.props.contract.recordData.telefoon_2}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'telefoon_2': e.target.value }))}
              small={true}
            />
          </div>
          <TextInput
            type='email'
            icon={IconNames.ENVELOPE}
            placeholder='Email'
            width='30em'
            value={this.props.contract.recordData.email}
            readOnly={false}
            onChange={(e) => this.dispatch(update({ 'email': e.target.value }))}
            small={true}
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.contract.recordData !== null) {
      return (
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderInkooporderGegevens()}
            {this.renderTermijnen()}
          </div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderContractor()}
            {this.renderContact()}
          </div>
          <SearchDialog
            showDialog={this.state.showSearchDialog}
            title='Zoeken'
            table={this.state.tableName}
            fields={this.state.updateFields}
            reducer={'contract'}
            onClose={() => this.setState({ showSearchDialog: false })}
          />
        </div>
      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderDialog()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contracttable: state.contracttable,
  maintable: state.maintable,
  contract: state.contract,
  inkorderregeltable: state.inkorderregeltable
});

export default connect(mapStateToProps)(ContractorTab);
