import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Tabs, Tab, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { offerteGridBoxesMain, offerteUiItemsMain } from './offerteUI';
import { offerteGridBoxesAantekeningen, offerteUiItemsAantekeningen } from './offerteUI';
import * as API from '../../mainapi';
import { clear } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateOfferte, promoteOfferte } from '../../services/offertes.service';
import UiRenderer from '../../utils/DialogRender';
import OfferteDocDialog from './OfferteDocDialog';

class OfferteDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
      showOfferteDocDialog: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
    this.userGroups = JSON.parse(sessionStorage.getItem("currentUserGroups"));

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog) {
    const orderRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, orderRecord);

    const offerteId = orderRecord.offerte_id
    delete recordData.offerte_id
    updateOfferte(offerteId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  triggerOfferteToOrder = (closeDialog) => {
    const bodyData = { offerte_id: this.props.dialog.recordData.offerte_id };

    axios.post(API.OfferteToOrderUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          promoteOfferte();

          this.dispatch(clear());
          closeDialog();
          this.dispatch(expand());
        }
      })
      .catch(error => {
          console.error(error.response.statusText);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '75%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Toaster ref={(instance) => { this.toaster = instance }} />

          <Tabs animate={true} id='TabsOrder' large={true} >
            <Tab id='main' title='Offertegegevens'
              panel={<UiRenderer gridBoxes={offerteGridBoxesMain} uiItems={offerteUiItemsMain} />}
            />
            <Tab id='aantekeningen' title='Aantekeningen'
              panel={<UiRenderer gridBoxes={offerteGridBoxesAantekeningen} uiItems={offerteUiItemsAantekeningen} />}
            />
          </Tabs>
          <OfferteDocDialog showDialog={this.state.showOfferteDocDialog}
            title='Offerte'
            onClose={() => this.setState({ showOfferteDocDialog: false })} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.ALIGN_LEFT}>
            <Button icon={IconNames.DOCUMENT_OPEN}
              onClick={() => this.setState({ showOfferteDocDialog: true })}
              style={{marginRight: '0.7em'}} >
                Offerte document
            </Button>
            <Button icon={IconNames.DOCUMENT_SHARE} onClick={() => this.triggerOfferteToOrder(onClose)}>
                Promoveren naar order
            </Button>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(OfferteDialog);
