import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import MszTable from '../../components/MszTable';
import PositionDialog from './PositionDialog';
import DeleteDialog from '../../components/DeleteDialog';
import { load, select, del } from '../../utils/postableSlice';

class PositionsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDialog: false,
      selectedPosId: null,
      showDialog: false,
      newRecord: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.loadTableData = this.loadTableData.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.getRow = this.getRow.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);
    this.renderDialog = this.renderDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.maintable.selectedRowData !== null) {
      this.loadTableData(this.props.maintable.selectedRowData.order_id);
    } else {
      this.parseGrid('orderpos', []);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dialog.recordData !== this.props.dialog.recordData) {
      if (this.props.dialog.recordData === null | nextProps.dialog.createRecord !== this.props.dialog.createRecord) {
        this.loadTableData(nextProps.dialog.recordData.order_id);
      }
      return true;
    } else if (nextProps.postable.columnData !== this.props.postable.columnData | nextProps.postable.rowData !== this.props.postable.rowData) {
      return true;
    } else {
      if (nextState !== this.state) {
        return true;
      } else {
        return false;
      }
    }
  }

  loadTableData(orderId) {
    axios.get(API.OrderPosUrl, {
      params: {
        order_id: orderId
      }
    })
      .then(response => {
        const data = response.data;
        this.parseGrid('orderpos', data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  booleanRenderer = (params) => {
    if (params.value) {
      return (
        <div>
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        </div>
      )
    } 
  }

  parseGrid(tableName, tableData) {
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;
        var newData = {};

        data.forEach((record) => {
          if (record.yesno) {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              cellRenderer: this.booleanRenderer,
              hide: (record.visible === 0)
            };
          } else {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              hide: (record.visible === 0)
            };
          }
          columnData.push(newData);
        })

        this.resetSelectedRow();
        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ newRecord: false });
      })
      .catch(error => {
          console.error(error);
      })
  }

  rowClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
  }

  rowDoubleClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ showDialog: true, newRecord: false });
  }

  resetSelectedRow() {
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  closeDialog = () => {
    this.setState({ showDialog: false, newRecord: false, showDeleteDialog: false });
  }

  deleteClick = () => {
    if (this.props.postable.selectedRowIdx !== null) {
      this.setState({
        showDeleteDialog: true,
        selectedPosId: this.props.postable.selectedRowData.order_positions_id,
      });
    }
  }

  getRow(action) {
    if (action === 'createRecord') {
      this.setState({ showDialog: true, newRecord: true });
    }

    if (this.props.postable.selectedRowIdx !== null) {
      switch (action) {
        case 'editRecord' : this.setState({ showDialog: true, newRecord: false });
          break
        case 'deleteRecord' : this.deleteClick();
          break
      }
    }
  }

  deleteRow = () => {
    this.dispatch(del({ rowIdx: this.props.postable.selectedRowIdx }));
  }

  renderDialog() {
    if (this.props.postable.selectedRowIdx !== null | this.state.newRecord) {
      return (
        <PositionDialog
          showDialog={this.state.showDialog}
          rowData={this.props.postable.selectedRowData}
          newRecord={this.state.newRecord}
          orderId={this.props.dialog.recordData.order_id}
          onClose={this.closeDialog}
        />
      )
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'inline-flex'}}>
          <Button icon={IconNames.ADD} text="Toevoegen" onClick={() => this.getRow('createRecord')} />
          <Button icon={IconNames.EDIT} text="Wijzigen" onClick={() => this.getRow('editRecord')} />
          <Button icon={IconNames.DELETE} text="Verwijderen" onClick={() => this.getRow('deleteRecord')} />
        </div>
        <MszTable 
          tableColumns={this.props.postable.columnData}
          tableRows={this.props.postable.rowData}
          rowClicked={this.rowClicked}
          rowDoubleClicked={this.rowDoubleClicked}
          height='30em'
        />

        {this.renderDialog()}

        <DeleteDialog
          showDialog={this.state.showDeleteDialog}
          title='Orderpositie verwijderen'
          message={'Orderpositie ' + this.state.selectedPosId + ' verwijderen?'}
          url={API.OrderPosUrl}
          apiParams={{'order_positions_id': this.state.selectedPosId}}
          onDelete={this.deleteRow}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  postable: state.postable,
  maintable: state.maintable
});

export default connect(mapStateToProps)(PositionsTab);
