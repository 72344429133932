import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const titleList = [
  {key: 1, text: "Werkuren"},
  {key: 2, text: "Diensten"},
  {key: 3, text: "Materialen"},
];

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterProductSoort = (query, productsoort) => {
  return productsoort.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderProductSoort = (productsoort, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={productsoort.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={productsoort.text}
      />
  );
};

export class ProductSoortSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: titleList[0].text,
    }
  };

  render() {
    return (
        <Select2
          itemPredicate={filterProductSoort}
          itemRenderer={renderProductSoort}
          ItemListRenderer={renderMenu}
          items={titleList}
          onItemSelect={this.props.onSelect}
        >
          <Button
            text={this.props.value}
            icon={IconNames.LABEL}
            rightIcon='double-caret-vertical'
            small={true}
            style={{marginBottom: '0.1em', width: this.props.width}} 
          />
        </Select2>
    );
  };
};

