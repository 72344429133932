import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Tabs, Tab } from '@blueprintjs/core';
import { Button, Dialog } from '@blueprintjs/core';
import * as API from '../../mainapi';
import { load, update, reset } from '../../utils/contractSlice';
import { add, update as tableUpdate } from '../../utils/contracttableSlice';
import ContractorTab from './ContractorTab';
import InkooporderTab from './InkooporderTab';

class ContractDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.createRowData = this.createRowData.bind(this);
    this.loadRowData = this.loadRowData.bind(this);
    this.updateRowData = this.updateRowData.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  componentDidMount() {
    if (this.props.newRecord) {
      this.createRowData();
    } else {
      // if (this.props.postable.selectedRowData !== null) {
      const contractId = this.props.contracttable.selectedRowData.order_contracts_id;
      this.loadRowData(contractId);
      // }
    }
  }

  componentDidUpdate() {
    if (this.props.newRecord && !this.props.contract.createRecord) {
      this.createRowData();
    } else {
      if (!this.props.newRecord && this.props.contract.recordData !== null) {
        // Selected row from table prop; compare to loaded record data in store
        const selectedId = this.props.contracttable.selectedRowData.order_contracts_id;
        const loadedId = this.props.contract.recordData.order_contracts_id;

        if (selectedId !== loadedId && this.props.showDialog) {
          this.loadRowData(selectedId);
        }
      }
    }
  }

  createRowData() {
    const bodyData = {"order_id": this.props.orderId};

    axios.post(API.OrderContractUrl, bodyData)
      .then(response => {
        const payload = { recordData: response.data, createRecord: true };
        this.dispatch(load(payload));
        this.dispatch(add({ rowData: response.data }))

        // Fill afleveradres
        const orderData = this.props.dialog.recordData;
        this.dispatch(update({ 'aflnaam': orderData.naam }));
        this.dispatch(update({ 'afladres': orderData.adres }));
        this.dispatch(update({ 'aflhuisnr': orderData.huisnr }));
        this.dispatch(update({ 'aflhnrtoev': orderData.hnrtoev }));
        this.dispatch(update({ 'aflpostcode': orderData.postcode }));
        this.dispatch(update({ 'aflplaats': orderData.plaats }));
        this.dispatch(update({ 'aflland': orderData.land }));
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadRowData(contractId) {
    axios.get(API.OrderContractUrl, {
      params: {
        order_contracts_id: contractId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
      })
      .catch(error => {
          console.error(error);
      })
  }

  updateRowData(contractId, data) {
    axios.put(API.OrderContractUrl, data, {
      params: {
        order_contracts_id: contractId
      }
    })
      .then(response => {
        this.dispatch(tableUpdate({ rowData: response.data }))
      })
      .catch(error => {
          console.error(error);
      })
  }

  handleUrenChange = (e) => {
    if (e.target.value > 0) {
    }
    this.dispatch(update({ 'uren': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.position.recordData.tarief }));
  }

  saveClick(closeDialog) {
    const contractRecord = this.props.contract.recordData;
    const recordData = Object.assign({}, contractRecord);

    const contractId = contractRecord.order_contracts_id
    delete recordData.order_contracts_id
    this.updateRowData(contractId, recordData)

    this.dispatch(reset());
    closeDialog();
  }

  cancelClick(closeDialog) {
    this.dispatch(reset());
    closeDialog();
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Inkoop'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '70%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
        <Tabs animate={true} id='TabsOrder' large={true} >
          <Tab id='contractor' title='Contractgegevens'
            panel={<ContractorTab />}
          />
          <Tab id='inkooporder' title='Inkooporder'
            panel={<InkooporderTab />}
          />
        </Tabs>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  contract: state.contract,
  contracttable: state.contracttable,
  dialog: state.dialog
});

export default connect(mapStateToProps)(ContractDialog);
