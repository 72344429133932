import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        collapsed: false,
    },
    reducers: {
        toggle: {
            reducer(state) {
                state.collapsed = !state.collapsed
            },
        },
        collapse: {
            reducer(state) {
                state.collapsed = true
            },
        },
        expand: {
            reducer(state) {
                state.collapsed = false
            },
        }
    }
})

const { actions, reducer } = sidebarSlice;
export const { toggle, collapse, expand } = actions;

export default reducer
