import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import tableReducer from './utils/tableSlice';
import postableReducer from './utils/postableSlice';
import contracttableReducer from './utils/contracttableSlice';
import inkorderregeltableReducer from './utils/inkorderregeltableSlice';
import paytableReducer from './utils/paytableSlice';
import urentableReducer from './utils/urentableSlice';
import sidebarReducer from './utils/sidebarSlice';
import dialogReducer from './utils/dialogSlice';
import positionReducer from './utils/positionSlice';
import contractReducer from './utils/contractSlice';
import inkorderregelReducer from './utils/inkorderregelSlice';
import paymentReducer from './utils/paymentSlice';
import factuurpostableReducer from './utils/factuurpostableSlice';
import metricsReducer from './utils/metricsSlice';
import totalizerReducer from './utils/totalizerSlice';
import litepostableReducer from './utils/litepostableSlice';
import litepositionReducer from './utils/litepositionSlice';
import usertableReducer from './utils/usertableSlice';

const store = configureStore({
    reducer: {
        maintable: tableReducer,
        postable: postableReducer,
        contracttable: contracttableReducer,
        inkorderregeltable: inkorderregeltableReducer,
        paytable: paytableReducer,
        urentable: urentableReducer,
        sidebar: sidebarReducer,
        dialog: dialogReducer,
        position: positionReducer,
        contract: contractReducer,
        inkorderregel: inkorderregelReducer,
        payment: paymentReducer,
        factuurpostable: factuurpostableReducer,
        metrics: metricsReducer,
        totalizer: totalizerReducer,
        litepostable: litepostableReducer,
        liteposition: litepositionReducer,
        usertable: usertableReducer
    },
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: false
        })
    ]
})

export default store
