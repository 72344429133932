// import React from "react";
// import './Logo.css';

// const BackgroundImage = ({ imageSrc }) => (
//   <div style={{ position: "relative" }}>
//     <img src={imageSrc} alt="" style={{width: '100vw', height: '100vh', objectFit: 'fill'}} />
//   </div>
// );

// export default BackgroundImage;

import React, { Component } from 'react';

class BackgroundImage extends Component {
  state = {
    highResLoaded: false,
    currentSrc: null,
  };

  componentDidMount() {
    // Load low-resolution image
    const lowRes = new Image();
    lowRes.src = this.props.lowResSrc;
    lowRes.onload = () => {
      this.setState({ currentSrc: lowRes.src });

      // Load high-resolution image in background
      const highRes = new Image();
      highRes.src = this.props.highResSrc;
      highRes.onload = () => {
        this.setState({ highResLoaded: true, currentSrc: highRes.src });
      };
    };
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <img
          src={this.state.currentSrc || this.props.lowResSrc}
          alt={this.props.alt}
          className={this.state.highResLoaded ? 'high-res' : 'low-res'}
          style={{width: '100vw', height: '100vh', objectFit: 'fill'}} />
      </div>
    )
  }
}

export default BackgroundImage;
