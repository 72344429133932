import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card, Icon, Toaster, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { expand } from '../../utils/sidebarSlice';
import * as API from '../../mainapi';

class ReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spinFacturenReport: false,
      spinOfferteReport: false,
      spinOrderReport: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
  }

  downloadFile = (fileName) => {
    // Request the download
    axios.get(API.ReportFileUrl, {
      params: { filename: fileName },
      responseType: 'blob'
    })
      .then(response => {
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
        this.setState({
          spinFacturenReport: false,
          spinOfferteReport: false,
          spinOrderReport: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerReport = (urlTrigger, reportName) => {
    if (reportName === 'facturen') {
      this.setState({ spinFacturenReport: true });
    } else if(reportName === 'offertes') {
      this.setState({ spinOfferteReport: true });
    } else if(reportName === 'orders') {
      this.setState({ spinOrderReport: true });
    }

    axios.post(urlTrigger)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          this.downloadFile(data);

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: data + ' staat klaar in Downloads.'
          });
        } else {
          console.log(response.data);
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Geen rapport beschikbaar.'
          });
        }
      })
      .catch(error => {
          console.error(error);
      })
  }

  cancelClick = (closeDialog) => {
    closeDialog();
    this.dispatch(expand());
  }

  cardFacturen = () => {
    return (
      <Card elevation={Elevation.THREE} style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '1em', width: '20em'}}>
        <div style={{display: 'inline-flex'}}>
          <Icon icon={IconNames.DOCUMENT_SHARE} size={20} style={{marginRight: '1em'}} />
          <p className={Classes.HEADING}>
            Overzicht facturen
          </p>
        </div>
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            <Button icon={IconNames.DOWNLOAD} small={true} fill={true} intent={Intent.NONE} style={{marginRight: '1em'}}
              onClick={() => this.triggerReport(API.ReportFacturenUrl, 'facturen')}>
              Downloaden
            </Button>
            {this.state.spinFacturenReport && <Spinner className={Classes.SPINNER} size={25} />}
          </div>
        </div>
      </Card>
    )
  }

  cardOffertes = () => {
    return (
      <Card elevation={Elevation.THREE} style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '1em', width: '20em'}}>
        <div style={{display: 'inline-flex'}}>
          <Icon icon={IconNames.DOCUMENT_SHARE} size={20} style={{marginRight: '1em'}} />
          <p className={Classes.HEADING}>
            Overzicht offerts
          </p>
        </div>
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            <Button icon={IconNames.DOWNLOAD} small={true} fill={true} intent={Intent.NONE} style={{marginRight: '1em'}}
              onClick={() => this.triggerReport(API.ReportOffertesUrl, 'offertes')}>
              Downloaden
            </Button>
            {this.state.spinOfferteReport && <Spinner className={Classes.SPINNER} size={25} />}
          </div>
        </div>
      </Card>
    )
  }

  cardOrderActies = () => {
    return (
      <Card elevation={Elevation.THREE} style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '1em', width: '20em'}}>
        <div style={{display: 'inline-flex'}}>
          <Icon icon={IconNames.DOCUMENT_SHARE} size={20} style={{marginRight: '1em'}} />
          <p className={Classes.HEADING}>
            Overzicht orders
          </p>
        </div>
        <div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            <Button icon={IconNames.DOWNLOAD} small={true} fill={true} intent={Intent.NONE} style={{marginRight: '1em'}}
              onClick={() => this.triggerReport(API.ReportOrdersUrl, 'orders')}>
              Downloaden
            </Button>
            {this.state.spinOrderReport && <Spinner className={Classes.SPINNER} size={25} />}
          </div>
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    return (
      <div style={{display: 'inline-flex', width: '100%'}}>
        {this.cardFacturen()}
        {this.cardOffertes()}
        {this.cardOrderActies()}
      </div>
    )
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1, width: '50%'}}
        >
          <div className={(Classes.DIALOG_BODY)}>
            {this.renderDialog()}
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
});

export default connect(mapStateToProps)(ReportDialog);
