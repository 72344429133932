import React, {Component} from 'react';
import { Colors, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Card, Button, ButtonGroup } from '@blueprintjs/core';
import { UserInput } from '../../components/UserInput';
import { PasswordInput } from '../../components/PasswordInput';
import { TextInput } from '../../components/TextInput';
import * as API from '../../mainapi';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      tenantName: '',
      tenantList: [],
      tenantIsKnown: false,
    };

    this.saveTenant = this.saveTenant.bind(this);
    this.selectTenant = this.selectTenant.bind(this);
    this.clearTenantList = this.clearTenantList.bind(this);
    this.changeTenant = this.changeTenant.bind(this);
  }

  componentDidMount() {
    var tenant = sessionStorage.getItem("tenantName");

    if (tenant === undefined | tenant === null) {
      tenant = localStorage.getItem("tenantName");
      sessionStorage.setItem("tenantName", tenant);
    }

    const tenantList = JSON.parse(localStorage.getItem("tenantList"));

    if (tenant !== undefined && tenant !== null) {
      if (tenantList !== undefined && tenantList !== null) {
        this.setState({ tenantName: tenant, tenantList: tenantList, tenantIsKnown: true });
      } else {
        this.setState({ tenantName: tenant, tenantIsKnown: true });
      }
    } else {
      if (!API.IS_MULTITENANT) {
        this.setState({ tenantIsKnown: true });
      }
    }
  }

  saveTenant() {
    const prevState = {...this.state};
    var tenantList = prevState.tenantList;
    tenantList.push(this.state.tenantName);

    localStorage.setItem("tenantName", this.state.tenantName);
    sessionStorage.setItem("tenantName", this.state.tenantName);
    localStorage.setItem("tenantList", JSON.stringify(tenantList));
    this.setState({ tenantIsKnown: true, tenantList: tenantList });

    window.location.reload(false);
  }

  selectTenant(e) {
    sessionStorage.setItem("tenantName", e.target.innerText);
    this.setState({ tenantIsKnown: true, tenantName: e.target.innerText });
  }

  clearTenantList() {
    localStorage.setItem("tenantName", this.state.tenantName);
    sessionStorage.setItem("tenantName", this.state.tenantName);
    localStorage.setItem("tenantList", JSON.stringify([]));
    this.setState({ tenantIsKnown: true, tenantList: [] });

    window.location.reload(false);
  }

  changeTenant(e) {
    this.setState({ tenantName: e.target.value, tenantIsKnown: false });
  }

  renderBedrijfsId = () => {
    return (
      <div style={{marginLeft: '5em', marginRight: '5em', marginTop: '5em'}}>
        <h3 align='left' style={{color: '#daa51b'}}>
          Bedrijfs-ID
        </h3>
        <div align='left' style={{marginBottom: '0.2em'}}>
          <ButtonGroup>
            {this.state.tenantList.map((tenant, index) => {
              return (
                <Button
                  key={index}
                  icon={IconNames.OFFICE}
                  small={true}
                  minimal={true}
                  intent={Intent.WARNING}
                  onClick={this.selectTenant}>
                    {tenant}
                </Button>
              )
            })}
          </ButtonGroup>
        </div>
        <TextInput
          value={this.state.tenantName}
          onChange={this.changeTenant}
        />
        <div align='right'>
          <Button
            style={{marginTop: '0em', marginRight: '0.2em'}}
            icon={IconNames.CONFIRM}
            minimal={true}
            small={true}
            intent={Intent.SUCCESS}
            onClick={this.saveTenant}>
              <b>ONTHOUDEN</b>
          </Button>
          <Button
            style={{marginTop: '0em'}}
            icon={IconNames.DELETE}
            minimal={true}
            small={true}
            intent={Intent.DANGER}
            onClick={this.clearTenantList}>
              <b>LIJST WISSEN</b>
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const IS_MULTITENANT = (process.env.REACT_APP_INSTANCE_TYPE === 'multitenant');

    return (
      <Card style={{backgroundColor: Colors.DARK_GRAY1, height: '100%'}}>

        {IS_MULTITENANT && this.renderBedrijfsId()}

        <div style={{marginLeft: '5em', marginRight: '5em', marginTop: '5em'}}>
          <h3 align='left' style={{color: '#daa51b'}}>
            Je gebruikersnaam en wachtwoord
          </h3>
          <UserInput
            text={this.props.user}
            onChange={this.props.onChangeUser}
            disabled={!this.state.tenantIsKnown}
          />
          <PasswordInput
            text={this.props.passWord}
            onChange={this.props.onChangePassword}
            disabled={!this.state.tenantIsKnown}
          />
          <div align='right'>
            <Button
              style={{marginTop: '1em'}}
              icon={IconNames.LOG_IN}
              intent={Intent.SUCCESS}
              minimal={true}
              large={true}
              onClick={this.props.onSignIn}
              disabled={!this.state.tenantIsKnown}>
                <b>INLOGGEN</b>
            </Button>
          </div>
        </div>
      </Card>
    )
  }
}

export default Login
