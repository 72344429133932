import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Elevation, Colors, Classes, Intent } from '@blueprintjs/core';
import { FormGroup, Card } from '@blueprintjs/core';
import { Checkbox, Switch, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TextInput } from '../components/TextInput';
import { NumInput } from '../components/NumInput';
import { CountrySelect } from '../components/CountrySelect';
import { AanhefSelect } from '../components/AanhefSelect';
import { RelatieSelect } from '../components/RelatieSelect';
import { OrderStatusSelect } from '../components/OrderStatusSelect';
import { UursoortSelect } from '../components/UursoortSelect';
import { ProjectlabelSelect } from '../components/ProjectlabelSelect';
import { TextEdit } from '../components/TextEdit';
import { DateEntry } from '../components/DateEntry';
import { update } from './dialogSlice';
import moment from 'moment';
import SearchDialog from '../components/SearchDialog';

class UiRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchDialog: false,
      tableName: null,
      url: null,
      updateFields: null,
      filter: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.prjLabels = JSON.parse(sessionStorage.getItem("prjLabels"));

    this.searchClick = this.searchClick.bind(this);
    this.calcField = this.calcField.bind(this);
    this.mapDialog = this.mapDialog.bind(this);
  }

  searchClick(table, url, fields, filter) {
    this.setState({
      showSearchDialog: true,
      tableName: table,
      url: url,
      updateFields: fields,
      filter: filter
    });
  }

  calcField(field, value, operator, calcField, target) {
    var resultValue = 0;

    switch (operator) {
      case '*': resultValue = value * this.props.dialog.recordData[calcField]; break
      case '-x': resultValue = value - this.props.dialog.recordData[calcField]; break
      case 'x-': resultValue = this.props.dialog.recordData[calcField] - value; break

      default: resultValue = 0; break
    }

    this.dispatch(update({ [`${field}`]: value }));
    this.dispatch(update({ [`${target}`]: resultValue }));
  }

  readCondition = (sub) => {
    if (sub.disabledCondition !== undefined && sub.disabledCondition !== null) {
      return sub.readOnly | this.props.dialog.recordData[sub.disabledCondition[0]] === sub.disabledCondition[1];
    } else {
      return sub.readOnly;
    }
  }

  /*
  Dialog mapper method uses uiItems and maps them to the appropriate components.
  */
  mapDialog(boxData) {
    var actionHook = null;

    const { uiItems } = this.props;
    const uiBoxItems = uiItems.filter(item => item.boxId === boxData.boxId);

    const mappedComponents = uiBoxItems.map(item => {
      const mappedElements = item.elements.map(sub => {
        switch (sub.action) {
          case 'default':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.target.value }))
            break
          case 'numeric':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.value }))
            break
          case 'select':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.text }))
            break
          case 'keyselect':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.key }))
            break
          case 'check':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.target.checked }))
            break
          case 'switch':
            actionHook = (e) => this.dispatch(update({ [`${sub.field}`]: e.target.checked }))
            break
          case 'textedit':
            actionHook = (text) => this.dispatch(update({ [`${sub.field}`]: text }))
            break
          case 'dateinput':
            actionHook = (date) => this.dispatch(update({ [`${sub.field}`]: moment(date).format('YYYY-MM-DD') }))
            break
          case 'search':
            actionHook = () => this.searchClick(sub.table, sub.url, sub.fields, sub.filter)
            break
          case 'calculate':
            actionHook = (e) => this.calcField(sub.field, e.target.value, sub.operator, sub.calcField, sub.target)
            break
          } 

        switch (sub.elementType) {
          case 'TextInput':
            return (
              <TextInput
                key={sub.key}
                type='text'
                icon={sub.icon}
                placeholder={sub.placeholder}
                width={sub.width}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
                small={true}
              />
            );
          case 'NumInput':
            return (
              <TextInput
                key={sub.key}
                type='number'
                icon={sub.icon}
                placeholder={sub.placeholder}
                width={sub.width}
                value={this.props.dialog.recordData[sub.field]}
                min={sub.min}
                readOnly={sub.readOnly}
                onChange={actionHook}
                small={true}
              />
            );
          case 'TelInput':
            return (
              <TextInput
                key={sub.key}
                type='tel'
                icon={sub.icon}
                placeholder={sub.placeholder}
                width={sub.width}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
                small={true}
              />
            );
          case 'EmailInput':
            return (
              <TextInput
                key={sub.key}
                type='email'
                icon={sub.icon}
                placeholder={sub.placeholder}
                width={sub.width}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
                small={true}
              />
            );
          case 'NumControlInput':
            return (
              <NumInput
                key={sub.key}
                icon={sub.icon}
                placeholder={sub.placeholder}
                width={sub.width}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
              />
            );
          case 'CountrySelect':
            return (
              <CountrySelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                onSelect={actionHook}
              />
            );
          case 'AanhefSelect':
            return (
              <AanhefSelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                onSelect={actionHook}
              />
            );
          case 'RelatieSelect':
            return (
              <RelatieSelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                onSelect={actionHook}
                width={sub.width}
              />
            );
          case 'OrderStatusSelect':
            return (
              <OrderStatusSelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                onSelect={actionHook}
                width={sub.width}
              />
            );
          case 'UursoortSelect':
            return (
              <UursoortSelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                onSelect={actionHook}
                width={sub.width}
              />
            );
          case 'ProjectlabelSelect':
            return (
              <ProjectlabelSelect
                key={sub.key}
                value={this.props.dialog.recordData[sub.field]}
                prjLabels={this.prjLabels}
                onSelect={actionHook}
              />
            );
          case 'Checkbox':
            return (
              <Checkbox
                key={sub.key}
                label={sub.label}
                checked={this.props.dialog.recordData[sub.field]}
                onChange={actionHook}
                style={{marginLeft: sub.spacing}}
                disabled={sub.readOnly}
              />
            );
          case 'Switch':
            return (
              <Switch
                key={sub.key}
                label={sub.label}
                checked={this.props.dialog.recordData[sub.field]}
                onChange={actionHook}
                disabled={this.readCondition(sub)}
              />
            );
          case 'TextEdit':
            return (
              <TextEdit
                key={sub.key}
                placeholder={sub.placeholder}
                minLines={sub.minLines}
                maxLines={sub.maxLines}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
              />
            );
          case 'DateInput':
            return (
              <DateEntry
                key={sub.key}
                icon={sub.icon}
                placeholder={sub.placeholder}
                value={this.props.dialog.recordData[sub.field]}
                readOnly={sub.readOnly}
                onChange={actionHook}
              />
            );
          case 'Search':
            return (
              <Button
                key={sub.key}
                icon={IconNames.SEARCH}
                intent={Intent.PRIMARY}
                onClick={actionHook}
                small={true}
              />
            );
        }
      });

      if (item.groupType === null && !item.inline) {
        return (
          <div key={item.key} >
            {mappedElements}
          </div>
        )
      } else {
        switch (item.groupType) {
          case 'Label':
            return (
              <FormGroup key={item.key} helperText={item.label} inline={item.inline}
                style={{margin: '0.0em'}}>
                {mappedElements}
              </FormGroup>
            )
          case 'Inline':
            return (
              <div key={item.key} >
                <div style={{display: 'inline-flex'}} >
                  {mappedElements}
                </div>
              </div>
            )
        }
      }
    })

    const mappedCard = () => {
      return (
        <Card
          key={boxData.boxId}
          elevation={Elevation.ONE}
          style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: boxData.boxWidth}}
        >
          <p className={Classes.HEADING}>
            {boxData.boxTitle}
          </p>
          {mappedComponents}
        </Card>
      )
    }

    return mappedCard();
  }

  render() {
    const renderLoading = () => {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
    var dialogItems = []

    if (this.props.dialog.recordData !== null) {
      const { gridBoxes } = this.props;
      dialogItems = gridBoxes.map(row => {
        var boxItems = [];

        row.boxes.forEach(box => {
          boxItems.push(this.mapDialog(box));
        });
        return (
          <div key={row.line}>
            <div style={{display: 'inline-flex', width: '100%'}}>
              {boxItems}
            </div>
          </div>
        )
      });
    } else {
      dialogItems = renderLoading()
    }

    return (
      <div>
        {dialogItems}
        <SearchDialog
          showDialog={this.state.showSearchDialog}
          title='Zoeken'
          table={this.state.tableName}
          url={this.state.url}
          fields={this.state.updateFields}
          filter={this.state.filter}
          onClose={() => this.setState({ showSearchDialog: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog
});

export default connect(mapStateToProps)(UiRenderer);
