import React, {Component} from 'react'
import { NumericInput, Intent } from '@blueprintjs/core'

export class NumInput extends Component {
  render() {
    const options = {
      allowNumericCharactersOnly: true,
      buttonPosition: 'right',
      disabled: false,
      fill: false,
      intent: Intent.NONE,
      large: false,
      majorStepSize: 10,
      max: this.props.max,
      min: this.props.min,
      minorStepSize: 0.1,
      selectAllOnFocus: false,
      selectAllOnIncrement: false,
      stepSize: 1
    }

    return (
      <NumericInput {...options}
        placeholder={this.props.placeholder}
        leftIcon={this.props.icon}
        value={this.props.value}
        onValueChange={this.props.onChange}
        readOnly={this.props.readOnly}
        style={{maxWidth: this.props.width}}
      />
    );
  };
};

