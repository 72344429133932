import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import App from "./App"
import MainWindow from "./views/Main/MainPage"
import * as API from "./mainapi";
import store from "./store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

function getLocalAccessToken() {
    const accessToken = sessionStorage.getItem("accessToken");
    return accessToken;
}

function getLocalRefreshToken() {
    const refreshToken = sessionStorage.getItem("refreshToken");
    return refreshToken;
}

function getLocalTenant() {
    const tenantName = sessionStorage.getItem("tenantName");
    return tenantName;
}

axios.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }

        if (API.IS_MULTITENANT) {
            const tenant = getLocalTenant();
            if (tenant) {
                config.headers["X-Tenant"] = tenant;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => response,
    async (error) => {
        const originalConfig = error.config;

        if (error.response) {
            if (error.response.status === 403 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    console.log("Token must be refreshed");
                    const res = await refreshToken();
                    const { access } = res.data;
                    sessionStorage.setItem("accessToken", access);
                    return axios(error.response.config);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            } else {
                if (error.response.status === 403 && originalConfig._retry)
                    console.log("Second 403 status; go back to login page");
                    // history.push("/");
                    // history.go(0);
                    return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

function refreshToken() {
    return axios.post(API.TokenRefreshUrl, {
      refresh: getLocalRefreshToken(),
    });
}

const root = createRoot(
    document.getElementById('root')
);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="mainwindow" element={<MainWindow />} />
            </Routes>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
