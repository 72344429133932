/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/

export const mwGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Medewerkernr en naam", boxWidth: '50%'},
            {boxId: 'A2', boxTitle: "Contract", boxWidth: '50%'},
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'B1', boxTitle: "Adres en contactgegevens", boxWidth: '60%'},
            {boxId: 'B2', boxTitle: "Certificeringen en diploma's", boxWidth: '40%'}
        ]
    }
]

export const mwUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'medewerker_id',
                placeholder: 'Medewerker ID',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: 'Label',
        label: 'Aanhef, voornaam en intialen',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'voornaam',
                placeholder: 'Voornaam',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'initialen',
                placeholder: 'Initialen',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A13',
        groupType: 'Label',
        label: 'Achternaam',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 2,
                elementType: 'TextInput',
                field: 'achternaam',
                placeholder: 'Achternaam',
                icon: null,
                width: '37em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B1',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '37em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B13',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'TelInput',
                field: 'telefoon_2',
                placeholder: 'Telefoon 2',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B14',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: 'Label',
        label: 'Datum in dienst en einddatum contract',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'datumindienst',
                placeholder: 'In dienst',
                icon: null,
                width: '10em',
                action: 'dateinput',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'DateInput',
                field: 'eindecontract',
                placeholder: 'Einddatum',
                icon: null,
                width: '10em',
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A22',
        groupType: 'Label',
        label: 'Functie',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A23',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'functie',
                placeholder: 'Functie',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'UursoortSelect',
                field: 'uursoort',
                placeholder: 'uursoort',
                icon: null,
                width: '10em',
                action: 'keyselect',
                restrict: null,
                readOnly: false
            },
        ]
    },
    {
        boxId: 'A2',
        key: 'A24',
        groupType: 'Label',
        label: 'BSN',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A25',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'bsn',
                placeholder: 'BSN',
                icon: 'bank-account',
                width: '17em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A26',
        groupType: 'Label',
        label: 'IBAN',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A27',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'iban',
                placeholder: 'IBAN',
                icon: 'bank-account',
                width: '17em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A28',
        groupType: 'Label',
        label: 'Kostprijs / uur',
        inline: false,
        elements: []
    },
    {
        boxId: 'A2',
        key: 'A29',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'kostprijsuur',
                placeholder: 'Kostprijs',
                icon: 'dollar',
                width: '17em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B2',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Checkbox',
                field: 'vca',
                label: 'VCA Certificaat',
                icon: null,
                action: 'check',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B21',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'datum_vca',
                label: 'd.d.',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
];

export const mwGridBoxesAantekeningen = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Aantekeningen", boxWidth: '100%'},
        ]
    }
]

export const mwUiItemsAantekeningen = [
    {
        boxId: 'A1',
        key: 'A1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextEdit',
                field: 'aantekeningen',
                placeholder: 'Vul hier eventuele aantekeningen in...',
                minLines: 22,
                maxLines: 30,
                action: 'textedit',
                restrict: null,
                readOnly: false
            }
        ]
    }
]
