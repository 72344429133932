import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Toaster, FormGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DateEntry } from '../../components/DateEntry';
import { update } from '../../utils/paytableSlice';
import * as API from '../../mainapi';
import moment from 'moment';
import { dateFormats } from '../../utils/formats';

class PlanInvoiceDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceDate: moment().format(dateFormats.STORAGEFORMAT)
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
  }

  cancelClick = (closeDialog) => {
    closeDialog();
  }

  planClick = (closeDialog) => {
    const bodyData = {
      payment_id: this.props.paytable.selectedRowData.order_payments_id,
      datum_uit: this.state.invoiceDate
    };

    axios.post(API.PlanInvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          this.dispatch(update({ rowData: response.data }));

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: 'Factuur is gepland.'
          });
        } else if (response.status === 208) {
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze betalingstermijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
          console.error(error.response);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })

    closeDialog();
  }

  planApproveClick = (closeDialog) => {
    const bodyData = {
      payment_id: this.props.paytable.selectedRowData.order_payments_id,
      datum_uit: this.state.invoiceDate,
      akkoord_uit: true
    };

    axios.post(API.PlanInvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          this.dispatch(update({ rowData: response.data }));

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.SUCCESS,
            message: 'Factuur is aangeboden ter facturatie.'
          });
        } else if (response.status === 208) {
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze betalingstermijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
          console.error(error.response);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })

    closeDialog();
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1}}
        >
          <div className={(Classes.DIALOG_BODY)} >
            <div>
              <FormGroup helperText='Datum' inline={false} style={{margin: '0.0em'}}/>
              <DateEntry
                placeholder='dd-mm-jjjj'
                value={this.state.invoiceDate}
                readOnly={false}
                onChange={(date) => this.setState({ invoiceDate: moment(date).format('YYYY-MM-DD') })}
              />
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              { this.props.paytable.selectedRowData !== null &&
                <Button
                  icon={IconNames.CALENDAR}
                  intent={Intent.PRIMARY}
                  disabled={this.props.paytable.selectedRowData.gefactureerd}
                  onClick={() => this.planClick(onClose)}>
                    Plannen
                </Button>
              }
              { this.props.paytable.selectedRowData !== null &&
                <Button
                  icon={IconNames.CONFIRM}
                  intent={Intent.SUCCESS}
                  disabled={this.props.paytable.selectedRowData.gefactureerd}
                  onClick={() => this.planApproveClick(onClose)}>
                    Aanbieden ter facturatie
                </Button>
              }
              </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  paytable: state.paytable,
  maintable: state.maintable
});

export default connect(mapStateToProps)(PlanInvoiceDialog);
