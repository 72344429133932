import { getOrder, createOrder } from '../../services/orderboek.service';
import { getCrm, createCrm } from '../../services/crm.service';
import { getMw, createMw } from '../../services/mw.service';
import { getContract, createContract } from '../../services/contract.service';
import { getUren, createUren } from '../../services/uren.service';
import { getOrderRegelTxt, createOrderRegelTxt } from '../../services/orderregeltxt.service';
import { getFactuur, createFactuur } from '../../services/facturen.service';
import { getOfferte, createOfferte } from '../../services/offertes.service';
import { getLiteFactuur, createLiteFactuur } from '../../services/lite.service';
import { getUser, createUser } from '../../services/user.service';
import store from '../../store';

export function Dispatcher(tableName, action) {
    const state = store.getState();

    if (tableName === 'Orderboek') {
        switch (action) {
            case 'createRecord' : { createOrder(); break }
            case 'editRecord'   : { getOrder(state.maintable.selectedRowData.order_id); break }
        }
    }

    if (tableName === 'Crm') {
        switch (action) {
            case 'createRecord' : { createCrm(); break }
            case 'editRecord'   : { getCrm(state.maintable.selectedRowData.contact_id); break }
        }
    }

    if (tableName === 'Medewerkers') {
        switch (action) {
            case 'createRecord' : { createMw(); break }
            case 'editRecord'   : { getMw(state.maintable.selectedRowData.medewerker_id); break }
        }
    }

    if (tableName === 'OrderContracts') {
        switch (action) {
            case 'createRecord' : { createContract(); break }
            case 'editRecord'   : { getContract(state.maintable.selectedRowData.order_contracts_id); break }
        }
    }

    if (tableName === 'Uren') {
        switch (action) {
            case 'createRecord' : { createUren(); break }
            case 'editRecord'   : { getUren(state.maintable.selectedRowData.boeking_id); break }
        }
    }

    if (tableName === 'Orderregeltxt') {
        switch (action) {
            case 'createRecord' : { createOrderRegelTxt(); break }
            case 'editRecord'   : { getOrderRegelTxt(state.maintable.selectedRowData.regel_id); break }
        }
    }

    if (tableName === 'Facturen') {
        switch (action) {
            case 'createRecord' : { createFactuur(); break }
            case 'editRecord'   : { getFactuur(state.maintable.selectedRowData.factuurnr); break }
        }
    }

    if (tableName === 'Offertes') {
        switch (action) {
            case 'createRecord' : { createOfferte(); break }
            case 'editRecord'   : { getOfferte(state.maintable.selectedRowData.offerte_id); break }
        }
    }

    if (tableName === 'Litefactuur') {
        switch (action) {
            case 'createRecord' : { createLiteFactuur(); break }
            case 'editRecord'   : { getLiteFactuur(state.maintable.selectedRowData.lite_id); break }
        }
    }

    if (tableName === 'User') {
        switch (action) {
            case 'createRecord' : { createUser(); break }
            case 'editRecord'   : { getUser(state.usertable.selectedRowData.id); break }
        }
    }
}
