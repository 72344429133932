import { createSlice } from '@reduxjs/toolkit';

const totalizerSlice = createSlice({
    name: 'totalizer',
    initialState: {
        value: 0
    },
    reducers: {
        set: {
            reducer(state, { payload }) {
                const { value } = payload
                state.value = value
            },
        },
        zero: {
            reducer(state) {
                state.value = 0
            },
        }
    }
})

const { actions, reducer } = totalizerSlice;
export const { set } = actions;

export default reducer
