import './App.css';
import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, Intent } from '@blueprintjs/core';
import Login from './views/Login/LoginPage';
import * as API from './mainapi';
import multiTenantImageLowres from './image-mt-low.jpg'
import multiTenantImageHighres from './image-mt-high.jpg'
import singleTenantImage from './imagest.jpg'
import BackgroundImage from './Background'
import LogoImage from './Logo'

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      passWord: "",
      navMain: false,
    };

    this.toaster = Toaster;
    this.msgUnauthorized = this.msgUnauthorized.bind(this);
    this.requestLogin = this.requestLogin.bind(this);
    this.renderMultiTenant = this.renderMultiTenant.bind(this);
    this.renderSingleTenant = this.renderSingleTenant.bind(this);
  }

  msgUnauthorized() {
    this.toaster.show({
      icon: 'warning-sign',
      intent: Intent.WARNING,
      message: "Ongeldige gebruikersnaam en/of wachtwoord"
    });
  }

  requestLogin(e) {
    console.log("Send login request...");

    const requestBody = {
      "username": this.state.user,
      "password": this.state.passWord
    }

    axios.post(API.TokenUrl, requestBody)
      .then(response => {
        const token = response.data;
        // localStorage.setItem("accessToken", token.access);
        // localStorage.setItem("refreshToken", token.refresh);
        // localStorage.setItem("currentUser", token.username);
        // localStorage.setItem("currentUserFirstname", token.first_name);
        // localStorage.setItem("currentUserLastname", token.last_name);
        // localStorage.setItem("currentUserGroups", JSON.stringify(token.groups));

        sessionStorage.setItem("accessToken", token.access);
        sessionStorage.setItem("refreshToken", token.refresh);
        sessionStorage.setItem("currentUserId", token.user_id);
        sessionStorage.setItem("currentUser", token.username);
        sessionStorage.setItem("currentUserFirstname", token.first_name);
        sessionStorage.setItem("currentUserLastname", token.last_name);
        sessionStorage.setItem("currentUserGroups", JSON.stringify(token.groups));
        sessionStorage.setItem("currentUserIsAdmin", token.is_staff);

        this.setState({ navMain: true });
      })
      .catch(error => {
          console.log(error)
          this.msgUnauthorized()
      })
  }

  renderMultiTenant() {
    return (
      <div style={{width: '100%', height: '100%'}}>
        <BackgroundImage lowResSrc={multiTenantImageLowres} highResSrc={multiTenantImageHighres} alt={'MiSenzImage'} />
      </div>
    )
  }

  renderSingleTenant() {
    return (
      <div style={{width: '57%', height: '100%'}}>
        <img src={singleTenantImage} width='100%' />
      </div>
    )
  }

  render() {
    const isMultiTenant = (process.env.REACT_APP_INSTANCE_TYPE === 'multitenant');
    var renderImages = null;

    if (isMultiTenant) {
      renderImages = this.renderMultiTenant();
    } else {
      renderImages = this.renderSingleTenant();
    }

    return (
      <div className="App">
        <Toaster
          ref={(instance) => {
            this.toaster = instance;
          }}
        />
        <div className={ `${isMultiTenant ? "App-flex-mt" : "App-flex-st"}` } style={{height: '100%', overflow: 'hidden'}} >
          { renderImages }
          <div className={ `${isMultiTenant ? "App-signin-mt" : "App-signin-st"}` }>
            <div className="App-heading">
              <div style={{display: 'inline-flex', alignItems: 'stretch', marginLeft: '16%'}}>
                <h2><span className="App-misenz-a" >MiSenz [</span></h2>
                <LogoImage />
                <h2><span className="App-misenz-b" >] Online</span></h2>
              </div>
            </div>
            <Login
              onSignIn={this.requestLogin}
              onChangeUser={(e) => this.setState({user: e.target.value })}
              onChangePassword={(e) => this.setState({passWord: e.target.value })}
            />
            {/* <div className="App-flex App-footer" style={{height: '5%'}}>
              <h2>Copyright MiSenz 2022</h2>
            </div> */}
          </div>
        </div>

        {this.state.navMain && <Navigate to={'/mainwindow'} />}
      </div>
    )
  }

}

export default App;
