import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { add, update } from "../utils/tableSlice";

export function getCrm(id) {
  axios.get(API.CrmUrl, {
    params: {
      contact_id: id
    }
  })
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createCrm() {
  axios.post(API.CrmUrl)
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
      store.dispatch(add({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateCrm(id, data) {
  axios.put(API.CrmUrl, data, {
    params: {
      contact_id: id
    }
  })
    .then(response => {
      store.dispatch(update({ rowData: response.data }));
    })
    .catch(error => {
        console.error(error);
    })
}
