import React, {Component} from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux/es/exports';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import { Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import 'react-pro-sidebar/dist/css/styles.css';
import { toggle } from '../utils/sidebarSlice';
import { clear } from '../utils/tableSlice';
import logo from '../logo.png';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: true,
      logOut: false,
    }

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.userGroups = JSON.parse(sessionStorage.getItem("currentUserGroups"));
  };

  signOut = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("currentUser");
    this.dispatch(clear())
    this.setState({ logOut: true });
  }

  renderReports = () => {
    const { clickReports } = this.props;
    return (
      <Menu iconShape='circle'>
        <MenuItem icon={<Icon icon={IconNames.HELPER_MANAGEMENT} />} onClick={clickReports}>
          Rapporten
        </MenuItem>
      </Menu>
    )
  }

  render() {
    const { menuItems } = this.props;
    const { dispatch } = this.props;
    const showReports = this.userGroups.find(obj => {return obj === "Directie"});

    const listItems = menuItems.map(item => {
      if (item.action === null | item.action === undefined) {
        const filtered = menuItems.filter(obj => {
          return obj.parent === item.key;
        });
        const childItems = filtered.map(child => {
          return (
            <MenuItem key={child.key} icon={<Icon icon={child.icon}/>} onClick={child.action}>
              {child.label}
            </MenuItem>
          );
        });

        return (
          <SubMenu key={item.key} title={item.label} icon={<Icon icon={item.icon} />}>
            {childItems}
          </SubMenu>
        );
      } else {
        if (item.parent === null) {
          return (
            <MenuItem key={item.key} icon={<Icon icon={item.icon}/>} onClick={item.action}>
              {item.label}
            </MenuItem>
          );
        }
      }
    })

    return (
      <ProSidebar width={'18em'} collapsed={this.props.sidebar.collapsed}>
        <SidebarHeader
          style={{backgroundColor: '#061915', background: 'linear-gradient(to right, teal, 25%, #061915)', fontWeight: 'bold', fontSize: 16,
                  paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em', border: 'none', position: 'sticky', top: 0, zIndex: 1}} >
          <div style={{width: '15%', display: 'inline-flex', justifyContent: 'left'}}>
            <img src={logo} width='100%' style={{flexShrink: '0'}}/>
            <span style={{marginLeft: '1em', marginTop: '0.3em', color: Colors.GOLD4, fontSize: 'larger', fontVariantCaps: 'small-caps'}}>MiSenz</span>
          </div>
        </SidebarHeader>

        <SidebarContent style={{backgroundColor: '#061915'}}>
          <Menu>
            <MenuItem
              icon={<Icon icon={this.props.sidebar.collapsed? IconNames.DoubleChevronRight:''} />}
              suffix={<Icon icon={this.props.sidebar.collapsed? '':IconNames.DoubleChevronLeft} />}
              onClick={() => dispatch(toggle())}
            />
          </Menu>
          <Menu iconShape='circle'>
            {listItems}
          </Menu>

          {showReports && this.renderReports()}

          <Menu iconShape='circle'>
            <MenuItem icon={<Icon icon={IconNames.COG} />}>
              Instellingen
            </MenuItem>
            <MenuItem icon={<Icon icon={IconNames.LOG_OUT}/>} onClick={this.signOut}>
              Uitloggen
            </MenuItem>
          </Menu>
        </SidebarContent>
        {this.state.logOut && <Navigate to={'/'} />}
      </ProSidebar>
    );
  };
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  maintable: state.maintable
});

export default connect(mapStateToProps)(SideBar)
