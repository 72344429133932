import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Classes, Icon, Intent, Toaster, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import MszTable from '../../components/MszTable';
import CrmDialog from '../Crm/CrmDialog';
import LiteFactuurDialog from '../Lite/LiteFactuurDialog';
import OrderDialog from '../Orderboek/OrderDialog';
import MedewerkerDialog from '../Mw/MwDialog';
import InkooporderDialog from '../Inkooporders/InkooporderDialog';
import UrenDialog from '../Uren/UrenDialog';
import RegeltxtDialog from '../OrderregelTxt/RegeltxtDialog';
import FacturenDialog from '../Facturen/FacturenDialog';
import DeleteDialog from '../../components/DeleteDialog';
import OfferteDialog from '../Offertes/OfferteDialog';
import ApproveInvoiceDialog from '../Facturen/ApproveInvoiceDialog';
import ReportDialog from '../Reports/ReportDialog';
import * as API from "../../mainapi";
import { MetricCard } from '../../components/MetricCard';
import { collapse } from '../../utils/sidebarSlice';
import { load, select, del } from '../../utils/tableSlice';
import { clear } from '../../utils/dialogSlice';
import { Dispatcher } from '../Main/ServiceDispatch'

class MainWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: true,
      menuItems: [],
      navBarItems: [],
      showDialog: false,
      activeData: null,
      newRecord: false,
      showDeleteDialog: false,
      delIdField: null,
      delIdValue: null,
      delIdUrl: null,
      cashBedrag: 0,
      totalColumn: null,
      toasterKey: null,
      showReportDialog: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;

    this.loadMenuData = this.loadMenuData.bind(this);
    this.getData = this.getData.bind(this);
    this.openReports = this.openReports.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
    this.resetSelectedRow = this.resetSelectedRow.bind(this);
    this.getRow = this.getRow.bind(this);
    this.parseMenu = this.parseMenu.bind(this);
    this.parseGrid = this.parseGrid.bind(this);
    this.parseNavbar = this.parseNavbar.bind(this);
    this.renderDialog = this.renderDialog.bind(this);
  };

  componentDidMount() {
    this.loadMenuData("SIDE_MAIN");
    this.loadModules();
    this.loadSettings();
    this.loadProjectlabels();
    this.loadUserGroups();
    this.loadMetrics();
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 1000,
    };
  }

  loadModules = () => {
    axios.get(API.ModulesUrl)
      .then(response => {
        const data = response.data;
        sessionStorage.setItem("appModules", JSON.stringify(data));
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadSettings = () => {
    axios.get(API.SettingsUrl)
      .then(response => {
        const data = response.data;
        sessionStorage.setItem("appSettings", JSON.stringify(data));
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadProjectlabels = () => {
    axios.get(API.PrjLabelUrl)
      .then(response => {
        var data = response.data;
        data = JSON.parse(JSON.stringify(data).split('"labelcode":').join('"key":'));
        data = JSON.parse(JSON.stringify(data).split('"labeltekst":').join('"text":'));
        sessionStorage.setItem("prjLabels", JSON.stringify(data));
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadUserGroups = () => {
    const userIsAdmin = JSON.parse(sessionStorage.getItem("currentUserIsAdmin"));

    if (userIsAdmin) {
      axios.get(API.UserGroupUrl)
        .then(response => {
          var data = response.data;
          data = JSON.parse(JSON.stringify(data).split('"id":').join('"key":'));
          data = JSON.parse(JSON.stringify(data).split('"name":').join('"text":'));
          sessionStorage.setItem("userGroup", JSON.stringify(data));
        })
        .catch(error => {
            console.error(error);
        })
    }
  }

  loadMenuData(menuName) {
    axios.get(API.MenuUrl)
      .then(response => {
        const data = response.data;
        this.parseMenu(data);
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadMetrics(menuName) {
    axios.get(API.MetricsCashUrl)
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          this.setState({ cashBedrag: data.bedrag.toFixed(2) });
        }
      })
      .catch(error => {
        console.error(error);
        this.toaster.show({
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
          message: 'Metrics kunnen miet worden opgehaald.'
        });
      })
  }

  parseMenu(uiData) {
    var parsedData = [];

    uiData.forEach(item => {
      var newData = null;

      if (Object.keys(item.actionprops).length > 0) {
        newData = {
          key: item.id,
          label: item.menuitem,
          icon: item.menuicon,
          level: item.level,
          parent: item.parentid,
          action: () => this.getData(item.actionprops, item.navbarprops)
        };
      } else {
        newData = {
          key: item.id,
          label: item.menuitem,
          icon: item.menuicon,
          level: item.level,
          parent: item.parentid
        };
      } 
      parsedData.push(newData);
    });

    this.setState({ menuItems: parsedData });
  }

  getData(actionProps, navbarProps) {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });

    if (actionProps.params !== undefined) {
      axios.get(API.APIURL + actionProps.url, {
        params: actionProps.params
      })
        .then(response => {
          const data = response.data;
          this.parseGrid(actionProps.tableName, data);
          this.parseNavbar(actionProps.tableName, navbarProps);
        })
        .catch(error => {
            console.error(error);
        })
    } else {
      axios.get(API.APIURL + actionProps.url)
        .then(response => {
          const data = response.data;
          this.parseGrid(actionProps.tableName, data);
          this.parseNavbar(actionProps.tableName, navbarProps);
        })
        .catch(error => {
            console.error(error);
        })
    }
  }

  openReports() {
    this.setState({ showReportDialog: true });
    this.dispatch(collapse());
  }

  rowClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ newRecord: false });
  }

  rowDoubleClicked(event) {
    this.dispatch(select({ selectedRowIdx: event.node.rowIndex, selectedRowData: event.data }));
    this.setState({ showDialog: true, newRecord: false });
    this.dispatch(collapse());
    Dispatcher(this.state.activeData, 'editRecord');
  }

  resetSelectedRow() {
    // Clear maintable selected row
    this.dispatch(select({ selectedRowIdx: null, selectedRowData: null }));
  }

  closeDialog = () => {
    this.setState({ showDialog: false, newRecord: false });
  }

  deleteClick = () => {
    if (this.props.maintable.selectedRowIdx !== null) {
      switch (this.state.activeData) {
        case 'Uren' : {
          this.setState({
            showDeleteDialog: true,
            delIdField: 'boeking_id',
            delIdValue: this.props.maintable.selectedRowData.boeking_id,
            delIdUrl: API.UrenUrl
          });
          break
        }
        default: console.log("Delete action not available.");
          break
      }
    }
  }

  getRow(action, tableName) {
    if (action === 'createRecord') {
      this.setState({ showDialog: true, newRecord: true });
      this.dispatch(collapse());
      Dispatcher(tableName, action);
    }

    if (this.props.maintable.selectedRowIdx !== null) {
      switch (action) {
        case 'editRecord' : {
          this.setState({ showDialog: true, newRecord: false });
          this.dispatch(collapse());
          Dispatcher(tableName, action);
          break
        }
        case 'deleteRecord' : this.deleteClick();
          break
      }
    }
  }

  deleteRow = () => {
    this.dispatch(del({ rowIdx: this.props.maintable.selectedRowIdx }));
  }

  booleanRenderer = (params) => {
    if (params.value) {
      return (
        <div>
          <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
        </div>
      )
    } 
  }

  statusRenderer = (params, renderer) => {
    const orderStatus = [
      {key: 0, text: "Niet gestart"},
      {key: 1, text: "Lopend"},
      {key: 2, text: "Gereed"},
      {key: 3, text: "Afgesloten"},
    ];

    const inkOrderStatus = [
      {key: 0, text: "Nog niet verstuurd"},
      {key: 1, text: "Opdracht verstuurd"},
      {key: 2, text: "Materialen geleverd"},
      {key: 3, text: "Wachten / op hold"},
    ];

    switch (renderer) {
      case 'orderstatus' : return orderStatus[params.value].text
      case 'inkorderstatus' : return inkOrderStatus[params.value].text
    }
  }

  parseGrid(tableName, tableData) {
    this.toaster.show(this.renderProgress(75), this.state.toasterKey)
    var columnData = [];

    axios.get(API.TableviewUrl, {
        params: {
          table: tableName
        }
      })
      .then(response => {
        const data = response.data;

        data.forEach((record) => {
          var newData = null;

          if (record.yesno) {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              cellRenderer: this.booleanRenderer,
              hide: (record.visible === 0)
            };
          } else if (record.renderer !== null && record.renderer !== '') {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              cellRenderer: (params) => this.statusRenderer(params, record.renderer),
              hide: (record.visible === 0)
            };
          } else {
            newData = {
              field: record.fieldname,
              headerName: record.description.toString(),
              hide: (record.visible === 0)
            };
          }
          columnData.push(newData);
        })

        var columnValue = null;
        if (tableName === 'OrderPay') {
          columnValue = 'totaal_excl';
        }
        this.resetSelectedRow()
        this.dispatch(load({ rowData: tableData, columnData: columnData }));
        this.setState({ activeData: tableName, newRecord: false, totalColumn: columnValue });
        this.dispatch(clear());
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
      })
      .catch(error => {
          console.error(error);
      })
  }

  parseNavbar(tableName, navbarProps) {
    var parsedData = [];

    if (navbarProps !== undefined && navbarProps !== null) {
      navbarProps.forEach((item, index) => {
        var newData = {
          key: index,
          label: item.label,
          icon: item.icon,
          action: () => this.getRow(item.action, tableName)
        };
        parsedData.push(newData);
      })

      this.setState({ navBarItems: parsedData });
    } else {
      this.setState({ navBarItems: [] });
    }
  }

  renderDialog() {
    if (this.props.dialog.recordData !== null) {
      switch (this.state.activeData) {
        case 'Orderboek' : return (
          <OrderDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Crm' : return (
          <CrmDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Medewerkers' : return (
          <MedewerkerDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Uren' : return (
          <UrenDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Orderregeltxt' : return (
          <RegeltxtDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Facturen' : return (
          <FacturenDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Offertes' : return (
          <OfferteDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
        case 'Litefactuur' : return (
          <LiteFactuurDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
      }
    }

    if (this.props.contract.recordData !== null) {
      switch (this.state.activeData) {
        case 'OrderContracts' : return (
          <InkooporderDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        )
      }
    }

    if (this.props.maintable.selectedRowIdx !== null | this.state.newRecord) {
      switch (this.state.activeData) {
        case 'OrderPay' : return (
          <ApproveInvoiceDialog
            showDialog={this.state.showDialog}
            title={this.state.activeData}
            onClose={this.closeDialog}
          />
        );
      }
    }
  }

  render() {
    return (
      <div className='App'>
        <Toaster ref={(instance) => { this.toaster = instance }} />

        <div style={{display: 'inline-flex', flex: 4, height: '10%'}}>
          <SideBar menuItems={this.state.menuItems} clickReports={this.openReports} />

          <div className='App-flex-left' style={{flexDirection: 'column', height: '100%'}}>
            <NavBar navBarCustomItems={this.state.navBarItems} />

            <div style={{display: 'inline-flex', width: '100%'}}>
              <MetricCard label='Cashflow' icon={IconNames.EURO} value={this.state.cashBedrag} />
              { 
                this.state.activeData === 'OrderPay' &&
                <MetricCard label='Totaal bedrag' icon={IconNames.EURO} value={this.props.totalizer.value} />
              }
            </div>

            <MszTable
              tableColumns={this.props.maintable.columnData}
              tableRows={this.props.maintable.rowData}
              rowClicked={this.rowClicked}
              rowDoubleClicked={this.rowDoubleClicked}
              totalColumn={this.state.totalColumn}
              height='73%'
            />
          </div>
        </div>

        {this.renderDialog()}

        <DeleteDialog
          showDialog={this.state.showDeleteDialog}
          title='Orderpositie verwijderen'
          message={this.state.delIdValue + ' verwijderen?'}
          url={this.state.delIdUrl}
          apiParams={{[this.state.delIdField]: this.state.delIdValue}}
          onDelete={this.deleteRow}
          onClose={() => this.setState({ showDeleteDialog: false })}
        />
        <ReportDialog
          showDialog={this.state.showReportDialog}
          title='Rapporten'
          onClose={() => this.setState({ showReportDialog: false })}
        />
      </div>
    );
  };
};

const mapStateToProps = state => ({
  sidebar: state.sidebar,
  maintable: state.maintable,
  dialog: state.dialog,
  contract: state.contract,
  totalizer: state.totalizer
});

export default connect(mapStateToProps)(MainWindow)
