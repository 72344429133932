import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Button, Dialog, Tabs, Tab, Toaster, ProgressBar } from '@blueprintjs/core';
import { factuurGridBoxesMain, factuurUiItemsMain } from './facturenUI';
import { clear } from '../../utils/dialogSlice';
import * as API from '../../mainapi';
import { expand } from '../../utils/sidebarSlice';
import { updateFactuur } from '../../services/facturen.service';
import { saveAs } from 'file-saver';
import UiRenderer from '../../utils/DialogRender';
import FactuurPositionsTab from './FactuurPosTab';

class FacturenDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
      isVoldaan: false,
      toasterKey: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog, invoicePaid) {
    const factuurRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, factuurRecord);

    const factuurId = factuurRecord.factuurnr
    delete recordData.factuurnr
    updateFactuur(factuurId, recordData, invoicePaid);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar intent={amount < 100 ? Intent.WARNING : Intent.SUCCESS}
          className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 1000,
    };
  }

  downloadFile = (fileName, fileType) => {
    // Request the download
    axios.get(API.InvoiceCopyFileUrl, {
      params: { filename: fileName, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        console.log(response);
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerInvoiceCopy = () => {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });

    const bodyData = { factuurnr: this.props.dialog.recordData.factuurnr };

    axios.post(API.InvoiceCopyUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          console.log(data);
          this.downloadFile(data.bestand_docx, 'docx');

          if (data.bestand_pdf !== null) {
            this.downloadFile(data.bestand_pdf, 'pdf');
          }

          if (data.bestand_ubl !== null) {
            this.downloadFile(data.bestand_ubl, 'ubl');
          }

          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: "Kopiefactuur " + this.props.dialog.recordData.factuurnr + ' staat klaar in Downloads.'
          });
        }
      })
      .catch(error => {
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
        console.error(error);
      })
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '80%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Toaster ref={(instance) => { this.toaster = instance }} />

          <Tabs animate={true} id='TabsFactuur' large={true} >
            <Tab id='main' title='Gegevens'
              panel={<UiRenderer gridBoxes={factuurGridBoxesMain} uiItems={factuurUiItemsMain} />}
            />
            <Tab id='factuurpos' title='Posities'
              panel={<FactuurPositionsTab />}
            />
          </Tabs>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.ALIGN_LEFT}>
            <Button
              icon={IconNames.Dollar}
              intent={Intent.WARNING}
              onClick={() => this.saveClick(onClose, true)}
              disabled={this.props.dialog.recordData.voldaan}
              style={{marginRight: '0.7em'}} >
                Betaling Boeken en Opslaan
            </Button>
            <Button
              icon={IconNames.DUPLICATE}
              onClick={this.triggerInvoiceCopy} >
                Kopie factuur
            </Button>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose, false)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable,
  sidebar: state.sidebar
});

export default connect(mapStateToProps)(FacturenDialog);
