import React, {Component} from 'react'
import { EditableText } from '@blueprintjs/core'

export class TextEdit extends Component {
  render() {
    return (
      <EditableText
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        rightElement={this.props.element}
        readOnly={this.props.readOnly}
        style={{marginBottom: '0.4em'}}
        multiline={true}
        minLines={this.props.minLines}
        maxLines={this.props.maxLines}
      />
    );
  };
};

