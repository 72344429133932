/* 
    Structure for the Dialog Mapper:
    {
        key: Unique Id
        groupType: Input group type; Label, Inline or null if not applicable
        label: Label for the input group
        inline: Inputs shown inline or not
        elements: List of input items [
            {
                key: Unique Id
                elementType: type of input; TextInput, Checkbox, ...
                field: Name of the field in the table
                placeholder: Placeholder for the input
                icon: Icon
                width: Width of the input
                action: onChange action; default, ... (to be defined)
                restrict: Roles for which this input is allowed to be shown
                readOnly: Readonly, only display the contents
            }
        ]
    },
*/
import { IconNames } from "@blueprintjs/icons";

export const factuurGridBoxesMain = [
    {
        line: 1,
        boxes: [
            {boxId: 'A1', boxTitle: "Bedragen", boxWidth: '35%'},
            {boxId: 'B1', boxTitle: "Data en overige ordergegevens", boxWidth: '45%'},
            {boxId: 'C1', boxTitle: "G-Rekening", boxWidth: '20%'},
        ]
    },
    {
        line: 2,
        boxes: [
            {boxId: 'A2', boxTitle: "Acties", boxWidth: '60%'},
            {boxId: 'B2', boxTitle: "Betalingen", boxWidth: '40%'},
        ]
    },
    {
        line: 3,
        boxes: [
            {boxId: 'A3', boxTitle: "Opdrachtgever", boxWidth: '55%'},
            {boxId: 'B3', boxTitle: "Contactgegevens", boxWidth: '45%'},
        ]
    }
]

export const factuurUiItemsMain = [
    {
        boxId: 'A1',
        key: 'A0',
        groupType: 'Label',
        label: 'Factuurnr',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A1',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'factuurnr',
                placeholder: 'Factuurnr',
                icon: null,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A11',
        groupType: 'Label',
        label: 'Factuurbedrag excl. BTW',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A12',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'totaal_excl',
                placeholder: 'Bedrag',
                icon: IconNames.EURO,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A13',
        groupType: 'Label',
        label: 'BTW bedragen laag - hoog',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A14',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'btwl',
                placeholder: 'Bedrag',
                icon: IconNames.ArrowDown,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'btwh',
                placeholder: 'Bedrag',
                icon: IconNames.ArrowUp,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A1',
        key: 'A15',
        groupType: 'Label',
        label: 'Factuurbedrag incl. BTW',
        inline: false,
        elements: []
    },
    {
        boxId: 'A1',
        key: 'A16',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'totaal_incl',
                placeholder: 'Bedrag',
                icon: IconNames.EURO,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B1',
        groupType: 'Label',
        label: 'Factuurdatum en vervaldatum',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'DateInput',
                field: 'factuurdatum',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'DateInput',
                field: 'vervaldatum',
                icon: null,
                action: 'dateinput',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B14',
        groupType: 'Label',
        label: 'Referentie opdrachtgever',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B15',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'uwref',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B1',
        key: 'B16',
        groupType: 'Label',
        label: 'Order Id en omschrijving project',
        inline: false,
        elements: []
    },
    {
        boxId: 'B1',
        key: 'B17',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'fk_order_id',
                icon: null,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'omschrijving',
                icon: IconNames.DOCUMENT,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'C1',
        key: 'C1',
        groupType: 'Label',
        label: 'Bedrag G-Rekening',
        inline: false,
        elements: []
    },
    {
        boxId: 'C1',
        key: 'C11',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'NumInput',
                field: 'totaal_grekening',
                placeholder: 'Bedrag',
                icon: IconNames.EURO,
                width: '12em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A2',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'actie',
                placeholder: 'Actie',
                icon: 'build',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: false
            },
            {
                key: 2,
                elementType: 'DateInput',
                field: 'actiedatum',
                placeholder: 'Actiedatum',
                icon: null,
                width: null,
                action: 'dateinput',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'A2',
        key: 'A21',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'actiehouder',
                placeholder: 'Actiehouder',
                icon: 'follower',
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: false
            }
        ]
    },
    {
        boxId: 'B2',
        key: 'B2',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'Checkbox',
                field: 'voldaan',
                label: 'Voldaan',
                icon: null,
                width: null,
                action: 'check',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A3',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'client_id',
                placeholder: 'Relatienr',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'naam',
                placeholder: 'Naam',
                icon: null,
                width: '35em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A31',
        groupType: null,
        label: 'Soort relatie',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'RelatieSelect',
                field: 'soort_relatie',
                placeholder: 'Soort relatie',
                icon: null,
                width: null,
                action: 'select',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A32',
        groupType: 'Inline',
        label: 'Afleveradres',
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'adres',
                placeholder: 'Adres',
                icon: null,
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'NumInput',
                field: 'huisnr',
                placeholder: 'Huisnr',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 3,
                elementType: 'TextInput',
                field: 'hnrtoev',
                placeholder: 'toevoeging',
                icon: null,
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A33',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TextInput',
                field: 'postcode',
                placeholder: 'Postcode',
                icon: null,
                width: '7em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'plaats',
                placeholder: 'Plaats',
                icon: null,
                width: '40em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'A3',
        key: 'A34',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'CountrySelect',
                field: 'land',
                placeholder: 'Land',
                icon: null,
                width: '20em',
                action: 'select',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B3',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'AanhefSelect',
                field: 'aanhef',
                placeholder: 'Aanhef',
                icon: null,
                width: '10em',
                action: 'select',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TextInput',
                field: 'contactp',
                placeholder: 'Contactpersoon',
                icon: null,
                width: '20em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B31',
        groupType: 'Inline',
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'TelInput',
                field: 'telefoon',
                placeholder: 'Telefoon',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            },
            {
                key: 2,
                elementType: 'TelInput',
                field: 'telefoon_2',
                placeholder: 'Telefoon 2',
                icon: 'phone',
                width: '10em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    },
    {
        boxId: 'B3',
        key: 'B32',
        groupType: null,
        label: null,
        inline: false,
        elements: [
            {
                key: 1,
                elementType: 'EmailInput',
                field: 'email',
                placeholder: 'Email',
                icon: 'envelope',
                width: '30em',
                action: 'default',
                restrict: null,
                readOnly: true
            }
        ]
    }
];
