import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { parseSettings } from '../utils/parseSettings';

const appSettings = JSON.parse(sessionStorage.getItem("appSettings"))
// const btwH = parseSettings(appSettings, 'BTWHTARIEF')
// const btwL = parseSettings(appSettings, 'BTWLTARIEF')
const btwH = '21.0';
const btwL = '9.0';

const titleList = [
  {key: 0, text: "BTW verlegd"},
  {key: 1, text: btwL},
  {key: 2, text: btwH},
];

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterBtw = (query, btw) => {
  return btw.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderBtw = (btw, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={btw.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={btw.text}
      />
  );
};

export class BtwSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: titleList[0].text,
    }
  };

  render() {
    return (
        <Select2
          itemPredicate={filterBtw}
          itemRenderer={renderBtw}
          ItemListRenderer={renderMenu}
          items={titleList}
          onItemSelect={this.props.onSelect}
        >
          <Button
            text={titleList[this.props.value].text}
            icon={IconNames.PERCENTAGE}
            rightIcon='double-caret-vertical'
            small={true}
            style={{marginBottom: '0.1em', width: this.props.width}} 
          />
        </Select2>
    );
  };
};

