import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog, Tabs, Tab, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { orderGridBoxesMain, orderUiItemsMain } from './orderUI';
import { orderGridBoxesAfspraken, orderUiItemsAfspraken } from './orderUI';
import { clear } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateOrder } from '../../services/orderboek.service';
import UiRenderer from '../../utils/DialogRender';
import PositionsTab from './PositionsTab';
import FinanceTab from './FinanceTab';
import InkoopTab from './InkoopTab';
import CalculationTab from './CalculationTab';
import SteeringTab from './SteeringTab';
import OpleverDialog from './OpleverDialog';

class OrderDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
      showOpleverDialog: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
    this.userGroups = JSON.parse(sessionStorage.getItem("currentUserGroups"));

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog) {
    const orderRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, orderRecord);

    const orderId = orderRecord.order_id;
    delete recordData.order_id;
    updateOrder(orderId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  render() {
    const { showDialog, title, onClose } = this.props;
    const showCalculationTab = this.userGroups.find(obj => {return obj === "Projectmanagement"});
    const showSteeringTab = this.userGroups.find(obj => {return obj === "Directie"});

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '80%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Toaster ref={(instance) => { this.toaster = instance }} />

          <Tabs animate={true} id='TabsOrder' large={true} >
            <Tab id='main' title='Gegevens'
              panel={<UiRenderer gridBoxes={orderGridBoxesMain} uiItems={orderUiItemsMain} />}
            />
            <Tab id='orderpos' title='Posities'
              panel={<PositionsTab />}
            />
            <Tab id='contract' title='Inkoop'
              panel={<InkoopTab />}
            />
            <Tab id='finance' title='Financieel'
              panel={<FinanceTab />}
            />
            <Tab id='afspraken' title='Afspraken'
              panel={<UiRenderer gridBoxes={orderGridBoxesAfspraken} uiItems={orderUiItemsAfspraken} />}
            />
            {
              showCalculationTab && this.props.dialog.recordData !== null &&
              <Tab id='calculatie' title='Calculatie' panel={<CalculationTab />} />
            }
            {
              showSteeringTab && this.props.dialog.recordData !== null &&
              <Tab id='sturing' title='Sturing' panel={<SteeringTab />} />
            }
          </Tabs>

          <OpleverDialog showDialog={this.state.showOpleverDialog}
            title='Oplevering'
            onClose={() => this.setState({ showOpleverDialog: false })}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.ALIGN_LEFT}>
            <Button icon={IconNames.DOCUMENT_OPEN}
              onClick={() => this.setState({ showOpleverDialog: true })}
              style={{marginRight: '0.7em'}} >
                Opleverdocument
            </Button>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(OrderDialog);
