import { createSlice } from '@reduxjs/toolkit';

const contractSlice = createSlice({
    name: 'contract',
    initialState: {
        recordData: null,
        createRecord: false
    },
    reducers: {
        load: {
            reducer(state, { payload }) {
                const { recordData, createRecord } = payload
                state.recordData = recordData
                state.createRecord = createRecord
            },
        },
        update: {
            reducer(state, { payload }) {
                const key = Object.keys(payload)[0]
                const value = Object.values(payload)[0]
                state.recordData[key] = value
            },
        },
        reset: {
            reducer(state) {
                state.createRecord = false
            }
        },
        clear: {
            reducer(state) {
                state.createRecord = false
                state.recordData = null
            },
        }
    }
})

const { actions, reducer } = contractSlice;
export const { load, update, reset, clear } = actions;

export default reducer
