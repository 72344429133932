import React, {Component} from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Tabs, Tab, Toaster } from '@blueprintjs/core';
import { Button, Dialog } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as API from '../../mainapi';
import { update, clear } from '../../utils/contractSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateContract } from '../../services/contract.service';
import ContractorTab from '../Orderboek/ContractorTab';
import InkooporderTab from '../Orderboek/InkooporderTab';

class InkooporderDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  handleUrenChange = (e) => {
    if (e.target.value > 0) {
    }
    this.dispatch(update({ 'uren': e.target.value }));
    this.dispatch(update({ 'bedrag': e.target.value * this.props.position.recordData.tarief }));
  }

  saveClick(closeDialog) {
    const contractRecord = this.props.contract.recordData;
    const recordData = Object.assign({}, contractRecord);

    const contractId = contractRecord.order_contracts_id
    delete recordData.order_contracts_id
    updateContract(contractId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  downloadFile = (inkooporderId, fileType) => {
    // Request the download
    axios.get(API.InkooporderFileUrl, {
      params: { order_contracts_id: inkooporderId, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        console.log(response);
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerInkooporder = () => {
    const bodyData = { order_contracts_id: this.props.contract.recordData.order_contracts_id };

    axios.post(API.InkooporderUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          this.downloadFile(data.order_contracts_id, 'docx');

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: data.order_contracts_id + ' is gegenereerd. Bestand staat klaar in Downloads.'
          });
        }
      })
      .catch(error => {
          console.error(error.response.statusText);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })
  }

  render() {
    const { showDialog, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title='Inkooporder'
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '70%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          <Toaster ref={(instance) => { this.toaster = instance }} />

          <Tabs animate={true} id='TabsOrder' large={true} >
            <Tab id='contractor' title='Contractgegevens'
              panel={<ContractorTab fromMain={true} />}
            />
            <Tab id='inkooporder' title='Inkooporder'
              panel={<InkooporderTab fromMain={true} />}
            />
          </Tabs>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.ALIGN_LEFT}>
            <Button icon={IconNames.DOCUMENT_OPEN} onClick={this.triggerInkooporder}>
                Inkooporder genereren
            </Button>
          </div>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  contract: state.contract,
  maintable: state.maintable
});

export default connect(mapStateToProps)(InkooporderDialog);
