import React, {Component} from 'react';
import { Select2 } from '@blueprintjs/select';
import { Menu, MenuItem, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const titleList = [
  {key: 0, text: "Direct"},
  {key: 1, text: "Indirect"}
];

const renderMenu = ({ items, itemsParentRef, query, renderItem, menuProps }) => {
  const renderedItems = items.map(renderItem).filter(item => item != null);
  return (
      <Menu role="listbox" ulRef={itemsParentRef} {...menuProps}>
          <MenuItem
              disabled={true}
              text={`Found ${renderedItems.length} items matching "${query}"`}
          />
          {renderedItems}
      </Menu>
  );
};

const filterUursoort = (query, status) => {
  return status.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const renderUursoort = (uursoort, { handleClick, handleFocus, modifiers }) => {
  return (
      <MenuItem
          selected={modifiers.active}
          key={uursoort.key}
          onClick={handleClick}
          onFocus={handleFocus}
          text={uursoort.text}
      />
  );
};

export class UursoortSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: titleList[0].text,
    }
  };

  renderButton = () => {
    try {
      return (
        <Button
          text={titleList[this.props.value].text}
          icon={IconNames.LABEL}
          rightIcon='double-caret-vertical'
          small={true}
          style={{marginBottom: '0.1em', width: this.props.width}} 
        />
      );
    } catch (err) {
      return (
        <Button
          text={titleList[0].text}
          icon={IconNames.LABEL}
          rightIcon='double-caret-vertical'
          small={true}
          style={{marginBottom: '0.1em', width: this.props.width}} 
        />
      )
    }
  }

  render() {
    return (
        <Select2
          itemPredicate={filterUursoort}
          itemRenderer={renderUursoort}
          ItemListRenderer={renderMenu}
          items={titleList}
          onItemSelect={this.props.onSelect}
        >
          {this.renderButton()}
        </Select2>
    );
  };
};

