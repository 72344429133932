import React, {Component} from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { Colors, Intent, Classes, Elevation } from '@blueprintjs/core';
import { Button, Dialog, Card, Switch, Checkbox, Toaster, FormGroup, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { del } from '../../utils/tableSlice';
import { load } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import * as API from '../../mainapi';
import { TextInput } from '../../components/TextInput';
import { ProjectFaseSelect } from '../../components/ProjectFaseSelect';

class ApproveInvoiceDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderData: null,
      toasterKey: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
    this.toaster = Toaster;
  }

  componentDidMount() {
    const payId = this.props.maintable.selectedRowData.order_payments_id;
    this.loadRowData(payId);
  }

  componentDidUpdate() {
    if (this.props.dialog.recordData !== null) {
      // Selected row from table prop; compare to loaded record data in store
      const selectedId = this.props.maintable.selectedRowData.order_payments_id;
      const loadedId = this.props.dialog.recordData.order_payments_id;

      if (selectedId !== loadedId && this.props.showDialog) {
        this.loadRowData(selectedId);
      }
    }
  }

  loadRowData = (payId) => {
    axios.get(API.OrderPayUrl, {
      params: {
        order_payments_id: payId
      }
    })
      .then(response => {
        const payload = { recordData: response.data, createRecord: false };
        this.dispatch(load(payload));
        this.loadOrderData(response.data.fk_order_id);
      })
      .catch(error => {
          console.error(error);
      })
  }

  loadOrderData = (orderId) => {
    axios.get(API.OrderUrl, {
      params: {
        order_id: orderId
      }
    })
      .then(response => {
        this.setState({ orderData: response.data })
      })
      .catch(error => {
          console.error(error);
      })
  }

  cancelClick = (closeDialog) => {
    closeDialog();
    this.dispatch(expand());
  }

  approveClick = (closeDialog) => {
    const bodyData = {
      payment_id: this.props.maintable.selectedRowData.order_payments_id,
      datum_uit: this.props.dialog.recordData.datum_uit,
      akkoord_uit: true
    };

    axios.post(API.PlanInvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          this.dispatch(del({ rowIdx: this.props.maintable.selectedRowIdx }));

          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.SUCCESS,
            message: 'Factuur is aangeboden ter facturatie.'
          });
        } else if (response.status === 208) {
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze betalingstermijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
          console.error(error.response);

          var msg = "Er is iets fout gegaan.";
          if (error.response.statusText !== undefined) {
            msg = error.response.statusText;
          }

          this.toaster.show({
            icon: IconNames.ERROR,
            intent: Intent.DANGER,
            message: msg
          });
      })

    closeDialog();
    this.dispatch(expand());
  }

  renderProgress = (amount) => {
    return {
      icon: IconNames.CLOUD_DOWNLOAD,
      message: (
        <ProgressBar intent={amount < 100 ? Intent.WARNING : Intent.SUCCESS}
          className={amount < 100 ? Classes.PROGRESS_BAR : Classes.PROGRESS_NO_STRIPES}
          value={amount / 100}
        />
      ),
      timeout: amount < 100 ? 0 : 1000,
    };
  }

  downloadFile = (factuurNr, fileType) => {
    // Request the download
    axios.get(API.FactuurFileUrl, {
      params: { factuurnr: factuurNr, filetype: fileType },
      responseType: 'blob'
    })
      .then(response => {
        let fileName = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename="'))
          .replace('filename="', '')
          .replace('"', '')
          .trim()

        saveAs(response.data, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }

  triggerInvoice = (closeDialog) => {
    const key = this.toaster.show(this.renderProgress(50));
    this.setState({ toasterKey: key });

    const bodyData = { payment_ids: [this.props.dialog.recordData.order_payments_id] };

    axios.post(API.InvoiceUrl, bodyData)
      .then(response => {
        if (response.status === 201) {
          const data = response.data;
          this.downloadFile(data.factuurnr, 'docx');

          if (data.bestand_pdf !== null) {
            this.downloadFile(data.factuurnr, 'pdf');
          }

          if (data.bestand_ubl !== null) {
            this.downloadFile(data.factuurnr, 'ubl');
          }

          // Reload ayments table as the gefactureerd flag is updated
          this.dispatch(del({ rowIdx: this.props.maintable.selectedRowIdx }));

          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.CONFIRM,
            intent: Intent.PRIMARY,
            message: data.factuurnr + ' is gefactureerd. Bestanden staan klaar in Downloads.'
          });
        } else {
          console.log(response.data);
          this.toaster.show(this.renderProgress(100), this.state.toasterKey)
          this.toaster.show({
            icon: IconNames.WARNING_SIGN,
            intent: Intent.WARNING,
            message: 'Deze termijn is al gefactureerd.'
          });
        }
      })
      .catch(error => {
        this.toaster.show(this.renderProgress(100), this.state.toasterKey)
        console.error(error);
      })

    closeDialog();
    this.dispatch(expand());
  }

  renderProject = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '99%'}}
      >
        <p className={Classes.HEADING}>
          Opdrachtgever en projectomschrijving
        </p>

        <div>
          <FormGroup helperText='Opdrachtgever' inline={true} style={{margin: '0.0em'}}/>
          <TextInput
            type='text'
            placeholder='Naam'
            width='35em'
            value={this.state.orderData.naam}
            readOnly={true}
            small={true}
          />
          <FormGroup helperText='Omschrijving project' inline={true} style={{margin: '0.0em'}}/>
          <TextInput
            type='text'
            placeholder='Omschrijving'
            width='45em'
            value={this.state.orderData.omschrijving}
            readOnly={true}
            small={true}
          />
          <FormGroup helperText='Contactpersoon' inline={true} style={{margin: '0.0em'}}/>
          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='text'
              placeholder='Aanhef'
              width='5em'
              value={this.state.orderData.fact_aanhef}
              readOnly={true}
              small={true}
            />
            <TextInput
              type='text'
              placeholder='Naam'
              width='30em'
              value={this.state.orderData.fact_contactp}
              readOnly={true}
              small={true}
            />
          </div>
          <TextInput
            type='text'
            placeholder='Email'
            width='35em'
            value={this.state.orderData.fact_email}
            readOnly={true}
            small={true}
          />
        </div>
      </Card>
    )
  }

  renderGeneral = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Gegevens
        </p>

        <div>
          <TextInput
            type='text'
            width='10em'
            value={this.props.dialog.recordData.order_payments_id}
            readOnly={true}
            small={true}
          />
          <TextInput
            type='text'
            placeholder='Omschrijving'
            width='30em'
            value={this.props.dialog.recordData.faseomschrijving}
            readOnly={true}
            small={true}
          />
          <ProjectFaseSelect
            value={this.props.dialog.recordData.projectfase}
            readOnly={true}
            />
          <TextInput
            type='text'
            placeholder='Betalingskenmerk'
            icon={IconNames.TAG}
            width='20em'
            value={this.props.dialog.recordData.kenmerk}
            readOnly={true}
            small={true}
          />

          <div style={{display: 'inline-flex'}}>
            <TextInput
              type='number'
              icon={IconNames.PERCENTAGE}
              width='10em'
              value={this.props.dialog.recordData.percentage}
              readOnly={true}
              small={true}
            />
          </div>

          <Switch
            label='Creditposities opnemen'
            checked={this.props.dialog.recordData.inclcredit}
            disabled={true}
          />
        </div>
      </Card>
    )
  }

  renderFinStatus = () => {
    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '40%'}}
      >
        <p className={Classes.HEADING}>
          Financiele status
        </p>

        <div>
          <Checkbox
            label='Gefactureerd'
            checked={this.props.dialog.recordData.gefactureerd}
            readOnly={true}
          />
          <Checkbox
            label='Voldaan'
            checked={this.props.dialog.recordData.voldaan}
            readOnly={true}
          />
        </div>
      </Card>
    )
  }

  renderDialog = () => {
    if (this.props.dialog.recordData !== null) {
      return (
        <div>
          <div>
            {this.state.orderData !== null && this.renderProject()}
          </div>
          <div style={{display: 'inline-flex', width: '100%'}}>
            {this.renderGeneral()}
            {this.renderFinStatus()}
          </div>
        </div>      )
    } else {
      return (
        <div style={{display: 'inline-flex', width: '100%'}}>
          Loading
        </div>
      )
    }
  }

  render() {
    const { showDialog, title, onClose } = this.props;
    const toApprove = this.props.maintable.selectedRowData.gepland_uit && !this.props.maintable.selectedRowData.akkoord_uit;
    const toInvoice = this.props.maintable.selectedRowData.akkoord_uit;

    return (
      <div>
        <Toaster ref={(instance) => { this.toaster = instance }} />
        <Dialog
          backdropProps={true}
          isOpen={showDialog}
          title={title}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          onClose={onClose}
          style={{backgroundColor: Colors.LIGHT_GRAY1, width: '50%'}}
        >
          <div className={(Classes.DIALOG_BODY)}>
            {this.renderDialog()}
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                icon={IconNames.CROSS}
                intent={Intent.NONE}
                onClick={() => this.cancelClick(onClose)}>
                  Annuleren
              </Button>
              { toApprove && <Button icon={IconNames.CONFIRM} intent={Intent.SUCCESS} onClick={() => this.approveClick(onClose)}>
                  Aanbieden ter facturatie
              </Button> }
              { toInvoice && <Button icon={IconNames.EURO} intent={Intent.PRIMARY} onClick={() => this.triggerInvoice(onClose)}>
                  Factureren
              </Button> }
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(ApproveInvoiceDialog);
