import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Colors, Intent, Classes } from '@blueprintjs/core';
import { Button, Dialog } from '@blueprintjs/core';
import { urenGridBoxesMain, urenUiItemsMain } from './urenUI';
import { clear } from '../../utils/dialogSlice';
import { expand } from '../../utils/sidebarSlice';
import { updateUren } from '../../services/uren.service';
import UiRenderer from '../../utils/DialogRender';

class UrenDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: false,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;

    this.saveClick = this.saveClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
  }

  saveClick(closeDialog) {
    const urenRecord = this.props.dialog.recordData;
    const recordData = Object.assign({}, urenRecord);

    const ubkId = urenRecord.boeking_id
    delete recordData.boeking_id
    updateUren(ubkId, recordData);

    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  cancelClick(closeDialog) {
    this.dispatch(clear());
    closeDialog();
    this.dispatch(expand());
  }

  render() {
    const { showDialog, title, onClose } = this.props;

    return (
      <Dialog
        backdropProps={true}
        isOpen={showDialog}
        title={title}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        onClose={onClose}
        style={{backgroundColor: Colors.LIGHT_GRAY1, width: '50%'}}
      >
        <div className={(Classes.DIALOG_BODY)} style={{width: '97%'}} >
          {<UiRenderer gridBoxes={urenGridBoxesMain} uiItems={urenUiItemsMain} />}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              icon='cross'
              onClick={() => this.cancelClick(onClose)}>
                Annuleren
            </Button>
            <Button
              icon='floppy disk'
              intent={Intent.PRIMARY}
              onClick={() => this.saveClick(onClose)}>
                Opslaan
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  maintable: state.maintable
});

export default connect(mapStateToProps)(UrenDialog);
