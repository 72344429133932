import React, {Component} from 'react';
import axios from 'axios';
import * as API from '../../mainapi';
import { connect } from 'react-redux';
import { FormGroup, Card } from '@blueprintjs/core';
import { Elevation, Colors, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TextInput } from '../../components/TextInput';
import { update } from '../../utils/dialogSlice';
import { load as metricsLoad } from '../../utils/metricsSlice';

class SteeringTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      newRecord: false,
      metrics: null,
    };

    const { dispatch } = this.props;
    this.dispatch = dispatch;
  }

  componentDidMount() {
    if (this.props.maintable.selectedRowData !== null) {
      this.loadMetrics(this.props.maintable.selectedRowData.order_id);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.dialog.recordData !== this.props.dialog.recordData) {
  //     if (this.props.dialog.recordData === null | nextProps.dialog.createRecord !== this.props.dialog.createRecord) {
  //       this.loadMetrics(this.props.maintable.selectedRowData.order_id);
  //     }
  //     return true;
  //   } else {
  //     if (nextState !== this.state) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // }

  renderCalculations = () => {
    const renderCalcFields = () => {
      return (
        <div style={{display: 'inline-flex'}}>
          <div>
            <FormGroup helperText='Bedragen' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.getMetrics('orderbedrag').toFixed(2)}
              readOnly={true}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Uren direct' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Uren'
              width='10em'
              value={this.props.dialog.recordData.calcuren}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'calcuren': e.target.value }))}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Uren indirect' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Uren'
              width='10em'
              value={this.props.dialog.recordData.calcindirect}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'calcindirect': e.target.value }))}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Werk derden' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.props.dialog.recordData.calcinkoopwerk}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'calcinkoopwerk': e.target.value }))}
              small={true}
            />
          </div>
          <div>
            <FormGroup helperText='Materialen' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              type='number'
              placeholder='Bedrag'
              width='10em'
              value={this.props.dialog.recordData.calcinkoopmat}
              readOnly={false}
              onChange={(e) => this.dispatch(update({ 'calcinkoopmat': e.target.value }))}
              small={true}
            />
          </div>
        </div>
      )
    }

    const renderSumFields = () => {
      const directSum = this.props.urentable.rowData.reduce((accumulator, current) => {
        if (current.uursoort === 0) {return accumulator + current.uren} else {return accumulator + 0}
      }, 0);
      const indirectSum = this.props.urentable.rowData.reduce((accumulator, current) => {
        if (current.uursoort === 1) {return accumulator + current.uren} else {return accumulator + 0}
      }, 0);
  
      const orderBedrag = this.getMetrics('orderbedrag');
      const bedragSum = this.props.urentable.rowData.reduce((accumulator, current) => accumulator + current.bedrag, 0)
      const werkDerdenSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.werkbedrag, 0)
      const materialenSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.matbedrag, 0)
      const inkoopSum = this.props.contracttable.rowData.reduce((accumulator, current) => accumulator + current.inkoopbedrag, 0)
  
      const restTotal = orderBedrag - bedragSum - inkoopSum;
      const restDirect = this.props.dialog.recordData.calcuren - directSum;
      const restIndirect = this.props.dialog.recordData.calcindirect - indirectSum;
      const restWerkDerden = this.props.dialog.recordData.calcinkoopwerk - werkDerdenSum;
      const restMaterialen = this.props.dialog.recordData.calcinkoopmat - materialenSum;
  
      return (
        <div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={bedragSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={directSum.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={indirectSum.toFixed(0)} readOnly={true} small={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput width='10em' small={true} disabled={true} />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={inkoopSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput width='10em' small={true} disabled={true} />
            <TextInput type='number' width='10em' value={werkDerdenSum.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={materialenSum.toFixed(2)} readOnly={true} small={true} />
          </div>
          <div style={{display: 'inline-flex'}}>
            <TextInput type='number' width='10em' value={restTotal.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restDirect.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restIndirect.toFixed(0)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restWerkDerden.toFixed(2)} readOnly={true} small={true} />
            <TextInput type='number' width='10em' value={restMaterialen.toFixed(2)} readOnly={true} small={true} />
          </div>
        </div>
      )
    }

    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '60%'}}
      >
        <p className={Classes.HEADING}>
          Calculatie
        </p>

        <div>
          {renderCalcFields()}
          {renderSumFields()}
        </div>
      </Card>
    )
  }

  updateAK = (e) => {
    const calcBedrag = (
      (
        (this.getMetrics('orderbedrag') / (100 + parseInt(e.target.value)) * 100) /
        (100 + parseInt(this.props.dialog.recordData.res_wr)) * 100
      ) /
      (100 + parseInt(this.props.dialog.recordData.res_car)) * 100
    )

    this.dispatch(update({ 'res_ak': e.target.value }));
    this.dispatch(update({ 'calcbedrag': calcBedrag.toFixed(2) }));
  }

  updateWR = (e) => {
    const calcBedrag = (
      (
        (this.getMetrics('orderbedrag') / (100 + parseInt(this.props.dialog.recordData.res_ak)) * 100) /
        (100 + parseInt(e.target.value)) * 100
      ) /
      (100 + parseInt(this.props.dialog.recordData.res_car)) * 100
    )

    this.dispatch(update({ 'res_wr': e.target.value }));
    this.dispatch(update({ 'calcbedrag': calcBedrag.toFixed(2) }));
  }

  updateCAR = (e) => {
    const calcBedrag = (
      (
        (this.getMetrics('orderbedrag') / (100 + parseInt(this.props.dialog.recordData.res_ak)) * 100) /
        (100 + parseInt(this.props.dialog.recordData.res_wr)) * 100
      ) /
      (100 + parseInt(e.target.value)) * 100
    )

    this.dispatch(update({ 'res_car': e.target.value }));
    this.dispatch(update({ 'calcbedrag': calcBedrag.toFixed(2) }));
  }

  renderResFields = () => {
    return (
      <Card elevation={Elevation.ONE} style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '50%'}}>
        <p className={Classes.HEADING}>
          Reserveringen
        </p>

        <div style={{display: 'inline-flex'}}>
          <div style={{marginRight: '3em'}}>
            <FormGroup helperText='Algemene kosten (AK)' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              icon={IconNames.PERCENTAGE}
              width='10em'
              value={this.props.dialog.recordData.res_ak}
              readOnly={false}
              onChange={this.updateAK}
              small={true} />
          </div>
          <div style={{marginRight: '3em'}}>
            <FormGroup helperText='Winst en Risico (W&R)' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              icon={IconNames.PERCENTAGE}
              width='10em'
              value={this.props.dialog.recordData.res_wr}
              readOnly={false}
              onChange={this.updateWR}
              small={true} />
          </div>
          <div style={{marginRight: '3em'}}>
            <FormGroup helperText='Construction All Risk (CAR)' inline={false} style={{margin: '0.0em'}}/>
            <TextInput
              icon={IconNames.PERCENTAGE}
              width='10em'
              value={this.props.dialog.recordData.res_car}
              readOnly={false}
              onChange={this.updateCAR}
              small={true} />
          </div>
        </div>
      </Card>
    )
  }

  loadMetrics = (orderId) => {
    axios.get(API.OrderMetricsUrl, {
      params: {
        order_id: orderId
      }
    })
      .then(response => {
        this.dispatch(metricsLoad({ data: response.data }));
      })
      .catch(error => {
          console.error(error);
      })
  }

  getMetrics = (key) => {
    if (this.props.metrics.data !== null) {
      return this.props.metrics.data[key];
    } else {
      return 0;
    }
  }

  renderMetrics = () => {
    const renderSumFields = () => {
      return (
        <div>
          <div style={{display: 'inline-flex'}}>
            <div>
              <FormGroup helperText='Aanneemsom' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('aanneemsom').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Gefactureerd' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('invoiced').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Betaald' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('paid').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Marge' inline={false} style={{margin: '0.0em'}}/>
              <TextInput icon={IconNames.PERCENTAGE} width='10em' value={this.getMetrics('margePerc').toFixed(2)} readOnly={true} small={true} />
            </div>
          </div>
          <div style={{display: 'inline-flex'}}>
            <div>
              <FormGroup helperText='Totaal orderbedrag' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('orderbedrag').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Inkoopbedrag' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('inkoopbedrag').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Uren' inline={false} style={{margin: '0.0em'}}/>
              <TextInput width='10em' value={this.getMetrics('urenbedrag').toFixed(2)} readOnly={true} small={true} />
            </div>
            <div>
              <FormGroup helperText='Marge' inline={false} style={{margin: '0.0em'}}/>
              <TextInput icon={IconNames.EURO} width='10em' value={this.getMetrics('margeBedrag').toFixed(2)} readOnly={true} small={true} />
            </div>
          </div>
        </div>
      )
    }

    return (
      <Card elevation={Elevation.ONE}
        style={{backgroundColor: Colors.LIGHT_GRAY4, margin: '0.2em', width: '45%'}}
      >
        <p className={Classes.HEADING}>
          Metrics
        </p>

        <div>
          {renderSumFields()}
        </div>
      </Card>
    )
  }

  render() {
    return (
      <div>
        <div>
          {this.renderResFields()}
        </div>
        <div style={{display: 'inline-flex'}}>
          {this.renderCalculations()}
          {this.renderMetrics()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.dialog,
  urentable: state.urentable,
  contracttable: state.contracttable,
  maintable: state.maintable,
  metrics: state.metrics
});

export default connect(mapStateToProps)(SteeringTab);
