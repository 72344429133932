import { createSlice } from '@reduxjs/toolkit';

const metricsSlice = createSlice({
    name: 'metrics',
    initialState: {
        data: null
    },
    reducers: {
        load: {
            reducer(state, { payload }) {
                const { data } = payload
                state.data = data
            },
        }
    }
})

const { actions, reducer } = metricsSlice;
export const { load } = actions;

export default reducer
