import axios from "axios";
import * as API from "../mainapi";
import store from '../store';
import { load } from '../utils/dialogSlice';
import { update } from "../utils/usertableSlice";

export function getUser(id) {
  axios.get(API.LoadUserUrl + id + "/")
    .then(response => {
      const payload = { recordData: response.data };
      store.dispatch(load(payload));
    })
    .catch(error => {
        console.error(error);
      })
}

export function createUser(data) {
  axios.post(API.RegisterUrl, data)
    .then(response => {
      if (response.status === 201) {
        console.log(response.data);
      }
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateUser(id, data) {
  axios.put(API.UpdateProfileUrl + id + "/", data)
    .then(response => {
      if (response.status === 200) {
        const newData = data;
        data.id = id;
        store.dispatch(update({ rowData: newData }));
      }
    })
    .catch(error => {
        console.error(error);
    })
}

export function updateGroups(id, groups) {
  axios.put(API.UpdateGroupUrl + id + "/", groups)
    .then(response => {
    })
    .catch(error => {
        console.error(error);
    })
}
